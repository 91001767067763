import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
const WebFont = require('webfontloader');

WebFont.load({
    google: {
        families: ['Lora', 'Lato']
    }
});

export const styles = {
    bannerSection: {
},
    bannerContainer: {
        position: "relative",
        minHeight: "500px",
        height:"40vh",
        width: "100vw",
        backgroundPosition: "left center" /* Center the image */,
        backgroundRepeat: "no-repeat" /* Do not repeat the image */,
        backgroundSize: "cover" /* Resize the background image to cover the entire container */,
        boxSizing:"border-box",
    },
    logo: {
        position: "absolute",
        width:"150px",
        height:"150px",
        marginTop:"40px",
        left: "6%",
        backgroundSize: "cover"
    },
    titleSection: {
        position: "absolute",
        width: "40%",
        bottom: "10%",
        left: "6%",
    },
    title: {
        fontFamily: "Lora",
        textShadow: "1px 2px 3px rgba(0,0,0, 0.5)",
        letterSpacing: "5px",
        fontSize: "4vw",
        color: "white",
    },
    subTitle: {
        fontFamily: "Lato",
        textShadow: "1px 2px 3px rgba(0,0,0, 0.5)",
        fontSize: "2vw",
        color: "white",
    },
    horizontalLine: {
        color: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        height: .5,
        borderColor : '#FFFFFF',
        margin: 5,
        marginLeft: 0,

    }
};

class HomeTitle extends Component {
    render() {
        const { classes, url, logo, title, subTitle, backgroundPosition } = this.props;
        return (
            <div className={classes.bannerSection}>
                <div
                    className={classes.bannerContainer}
                    style={{
                        backgroundImage: `url(${url})`,
                        backgroundPosition: `${backgroundPosition}`,
                    }}
                >
                    <img src={logo} className={classes.logo}/>
                    {/*<SvgIcon className={classes.logo}>*/}
                    {/*    <Logo />*/}
                    {/*</SvgIcon>*/}

                    <div className={classes.titleSection}>
                        <div className={classes.title}>{title}</div>
                        <hr className={classes.horizontalLine} />
                        <div className={classes.subTitle}>{subTitle}</div>
                    </div>
                </div>
            </div>
        );
    }
}

HomeTitle.defaultProps = {
    url: "images/faq-banner-img.png",
    title: "Page title",
    subTitle: "Subtitle",
    backgroundPosition: "left center",
};

HomeTitle.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    backgroundPosition: PropTypes.string,
};

/*withStyles(styles, [options]) => higher-order component
-----------------------------------------------------------------------------
Link a style sheet with a component using the higher-order component pattern.
It does not modify the component passed to it; instead, it returns a new
component with a classes property. This classes object contains the name of the
class names injected in the DOM.*/
export default withStyles(styles)(HomeTitle);
