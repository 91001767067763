import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Link from "react-router-dom/Link";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import theme from "../util/theme";

import Post from "../components/Post";
import Profile from "../components/Profile.js";
import axios from "axios";
import { getUserData } from "../redux/actions/userActions";
import { getPosts } from "../redux/actions/dataActions";

//marginBottom: theme.spacing(3),
const styles = (theme) => ({
  postsWraper: {
    padding: theme.spacing(3),
    //backgroundColor: "#fafafa",
    borderRadius: "10px",
    //maxHeight: "31vh",
    minHeight: "7vh",
    display: "flex",
    flexDirection: "column",
  },

  postsContainer: {
    display: "flex",
    flexDirection: "column",
    //flexWrap: "wrap",
    //justifyContent: "center",
    overflow: "auto",
    maxHeight: "100%",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      //boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      //webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      backgroundColor: "#fafafa",
      //width: "0.1em",
      //backgroundClip: "content-box",
      borderRadius: "1em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "1em",
      //height: "1em",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
  },
});

class ProfileContentWrapper extends React.Component {
  render() {
    const { classes, title, content, action, height, background } = this.props;

    return (
      <div
        className={classes.postsWraper}
        style={{ backgroundColor: background }}
      >
        <div
          style={{
            overflow: "hidden",
          }}
        >
          <div className={classes.title}>
            <Typography variant="h5">{title}</Typography>
          </div>
          <div
            //style={{ maxHeight: `${height}` }}
            className={classes.postsContainer}
          >
            {content != null && content}
          </div>
        </div>
      </div>
    );
  }
}

/*
<IconButton
  variant="outlined"
  color="secondary"
  fontSize="large"
  className={classes.navButton}
  onClick={action}
>
  <AccountCircleIcon />
</IconButton>
*/

export default withStyles(styles)(ProfileContentWrapper);
