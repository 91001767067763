import React, {Component, Fragment} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {handleDate} from "./functions/Date";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {deletePost} from "../redux/actions/dataActions";

import Comments from "./Comments";
import InputComment from "./InputComment";
import MooiButton from "./MooiButton";
import HerkenbaarButton from "./HerkenbaarButton";
import DitHelpButton from "./DitHelpButton";
import SavePostButton from "./SavePostButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as DeleteIcon} from "../assets/icons/delete-24px.svg";
import {ReactComponent as ReportIcon} from "../assets/icons/report_black_24dp.svg";
import ReportPopup from "./ReportPopup";

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import {NavLink} from "react-router-dom";

const redTheme = createMuiTheme({palette: {primary: red}});

const styles = (theme) => ({
    card: {
        height: "100%",
        position: "relative",
        overflow: "auto",
    },

    cardHeader: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
    gridFather: {
        position: "relative",
        height: "100%",
        width: "100%",
        backgroundColor: "white",
    },
    leftColumn: {
        minHeight: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    postHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2vh",
        maxHeight: "20%",
    },
    bodyWrapper: {
        height: "35%",
        overflow: "hidden",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        //padding: "60px",
        paddingTop: 50,
        paddingRight: "15px",
    },
    rightColumn: {
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
    },

    imageWrapper: {
        height: "60%",
        width: "90%",
        position: "relative",
        //margin: "20px 20px 20px 20px;",
        margin: "auto",
    },
    comments: {
        height: "30%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px 15px 1vh 20px",
    },

    cardP2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "2vh",
    },
    baseActionsLike: {
        borderStyle: "solid",
        borderWidth: "1px 0px 0px 0px",
        borderColor: theme.palette.secondary.main,
        //position: "absolute",
        //bottom: 0,
        //left: 0,
        height: "80px",
        //width: "90%",
        padding: "0px 60px 0px 40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        //marginBottom: "2vh",
    },
    baseActionsComment: {
        borderStyle: "solid",
        borderWidth: "1px 0px 0px 0px",
        borderColor: theme.palette.secondary.main,
        height: "80px",
        padding: "0px 15px 0px 30px;",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    likesStack: {
        display: "flex",
        justifyContent: "flex-start",
    },

    inputField: {
        marginTop: "1vh",
    },
    handle: {
        padding: "1vh",
        float: "right",
    },
    navButton: {
        backgroundColor: "#fff",
        margin: "1vh",
    },

    media: {
        position: "relative",
        height: "100%",
        width: "100%",
        borderRadius: "15px 15px 15px 15px",
    },

    bodyScroll: {
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "0.3em",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#fafafa",
            borderRadius: "1em",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "1em",
            //height: "1em",
        },
    }
});

class OpenPostModal extends Component {
    state = {
        anchorEl: null,
        //expandComments: false,
        openOptions: false,
        deleteOpen: false,
    };

    handleClick = (event) => {
        this.setState({openOptions: true, anchorEl: event.currentTarget});
    };

    handleRequestClose = () => {
        this.setState({openOptions: false});
        this.setState({reportOpen: false});
    };

    handleOpenOptions = () => {
        this.setState({deleteOpen: true});
    };
    handleCloseOptions = () => {
        this.setState({deleteOpen: false});
        this.setState({openOptions: false});
    };

    handleOpenReporting = () => {
        this.setState({reportOpen: true});
    };

    handleCloseOptions = () => {
        this.setState({reportOpen: false});
        this.setState({deleteOpen: false});
        this.setState({openOptions: false});
    };

    handleExpandComments = () => {
        this.props.openModal(this.props.post);
        //this.setState({ expandComments: true });
    };

    /*
    handleCompressComments = () => {
      this.props.openModal(null);
      //this.setState({ expandComments: false });
    };
    */

    render() {
        //dayjs.extend(relativeTime);
        const {
            classes,
            post: {
                body,
                title,
                createdAt,
                userAvatar,
                userHandle,
                likeCount,
                interestingCount,
                iAgreeCount,
                bookmarkCount,
                imageURL,
                id,
                comments, //each comment from post.comments should have the property commentId
            },
            user: {
                authenticated,
                credentials: {handle, avatar, role},
            },
            open,
        } = this.props;

        const {anchorEl, openOptions /*, expandComments */} = this.state;

        const deletePost = () => {
            this.props.deletePost(id);
            this.setState({deleteOpen: false});
            this.setState({openOptions: false});
        };

        return (
            <Card className={classes.card}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <div>
                            {userHandle === handle ? (
                                <Avatar
                                    className={classes.avatar}
                                    alt="Remy Sharp"
                                    src={
                                        userAvatar
                                            ? require("../assets/svg/" + userAvatar + ".svg")
                                            : null
                                    }
                                    component={NavLink}
                                    to={`/myprofile`}
                                />
                            ) : (<Avatar
                                    className={classes.avatar}
                                    alt="Remy Sharp"
                                    src={
                                        userAvatar
                                            ? require("../assets/svg/" + userAvatar + ".svg")
                                            : null
                                    }
                                    component={NavLink}
                                    to={`/profile/${userHandle}`}
                                />
                            )}
                        </div>
                    }
                    action={
                        <IconButton
                            variant="outlined"
                            fontSize="large"
                            className={classes.navButton}
                            aria-label="More"
                            aria-owns={this.state.openOptions ? "more-options" : null}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    }
                    title={
                        <div>
                            {userHandle === handle ? (
                                <NavLink
                                    to={`/myprofile`}
                                    style={{color: "inherit", textDecoration: "none"}}
                                >
                                    {userHandle}
                                </NavLink>
                            ) : (
                                <NavLink
                                    to={`/profile/${userHandle}`}
                                    style={{color: "inherit", textDecoration: "none"}}
                                >
                                    {userHandle}
                                </NavLink>
                            )}
                        </div>
                    }
                    subheader={handleDate(this.props.post)}
                />
                <Menu
                    id="more-options"
                    anchorEl={this.state.anchorEl}
                    open={this.state.openOptions}
                    onClose={this.handleRequestClose}
                >
                    {authenticated && userHandle === handle || role === 'admin' ? (
                        <MenuItem>
                            <Button
                                onClick={this.handleOpenOptions}
                                className={classes.deleteButton}
                            >
                                <SvgIcon component={DeleteIcon} color="secondary"/>
                            </Button>
                            <Button
                                onClick={this.handleRequestClose}
                                className={classes.deleteButton}
                            >
                                <ArrowBackIcon color="secondary"/>
                            </Button>
                            <Dialog
                                open={this.state.deleteOpen}
                                onClose={this.handleCloseOptions}
                                fullWidth
                                maxWidth="sm"
                            >
                                <DialogTitle>
                                    Are you sure you want to delete this post?
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={this.handleCloseOptions} color="secondary">
                                        Cancel
                                    </Button>
                                    <Button onClick={deletePost} color="secondary">
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </MenuItem>
                    ) : (
                        <MenuItem>
                            {this.state.reportOpen ? null : <MuiThemeProvider theme={redTheme}><Button
                                onClick={this.handleOpenReporting}
                                className={classes.deleteButton}
                            >
                                <SvgIcon component={ReportIcon} color="primary"/>
                            </Button></MuiThemeProvider>}
                            <Button
                                onClick={this.handleRequestClose}
                                className={classes.deleteButton}
                            >
                                <ArrowBackIcon color="secondary"/>
                            </Button>
                            {this.state.reportOpen ?
                                <ReportPopup postId={id} handleCloseOptions={this.handleCloseOptions}/> : null}
                        </MenuItem>
                    )}
                </Menu>
                <Grid container justify="flex-start" className={classes.gridFather}>
                    <Grid item xs={12} md={7} className={classes.leftColumn}>
                        <div className={classes.imageWrapper}>
                            <CardMedia
                                className={classes.media}
                                image={
                                    imageURL
                                        ? imageURL
                                        : "https://images.unsplash.com/photo-1519810755548-39cd217da494?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
                                }
                            />
                        </div>
                        {/*<div className={classes.bodyWrapper}>*/}
                        {/*    <Typography variant="h3">{title}</Typography>*/}
                        {/*    <Typography variant="body2">{body}</Typography>*/}
                        {/*</div>*/}
                        <div className={classes.baseActionsLike}>
                            <SavePostButton postId={id}/>
                            <div className={classes.likesStack}>
                                <MooiButton postId={id} mooiCount={likeCount}/>
                                <DitHelpButton postId={id} dithelpCount={iAgreeCount}/>
                                <HerkenbaarButton
                                    postId={id}
                                    herkenbaarCount={interestingCount}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.rightColumn}>
                        <div className={classes.bodyWrapper}>
                            <div className={classes.bodyScroll}>
                                <Typography variant="h4">{title}</Typography>
                                <Typography variant="body2">{body}</Typography>
                            </div>
                        </div>
                        {/*<div className={classes.imageWrapper}>*/}
                        {/*    <CardMedia*/}
                        {/*        className={classes.media}*/}
                        {/*        image={*/}
                        {/*            imageURL*/}
                        {/*                ? imageURL*/}
                        {/*                : "https://images.unsplash.com/photo-1519810755548-39cd217da494?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <CardContent className={classes.comments}>
                            {comments ? (
                                <Comments
                                    comments={comments} //commentId should be a property of each component of the array comments
                                    open={true}
                                    authenticated={authenticated}
                                    handle={handle}
                                    postId={id}
                                />
                            ) : null}
                        </CardContent>
                        <div className={classes.baseActionsComment}>
                            <InputComment postId={id} className={classes.inputField}/>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        );
    }
}

/*
<Typography variant="caption">
  {handleDate(this.props.post)}
</Typography>
*/

const mapStateToProps = (state) => ({
    user: state.user,
    //I need to put data here so that mapStateToProps rerenders when comments get into post.comments from redux state
    data: state.data,
});

const mapActionsToProps = {
    deletePost,
};

//it will stop mapStateToProps from triggering if there are no changes in the condition (if it returns true)
//const areStatesEqual = (next, prev) => false; //next.user === prev.user;

OpenPostModal.propTypes = {
    user: PropTypes.object.isRequired,
    deletePost: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(OpenPostModal));