import React, { Component, Fragment } from "react";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { pushNewComment } from "../redux/actions/dataActions";
import SendIcon from "@material-ui/icons/Send";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  form: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    //marginTop: 20,
  },
  cardP1: {
    backgroundColor: "green",
    display: "flex",
    alignItems: "center",
    padding: "2vh",
  },
  cardP2: {
    height: 240,
    backgroundColor: "red",
  },
  handle: {
    padding: "1vh",
    float: "right",
  },
  postCommentButton: {
    //backgroundColor: "#fff",
    margin: "2vh",
  },
  sendButton: {
    margin: "0vh",
  },
  media: {
    height: 240,
    backgroundColor: "#999999",
  },
};

class InputComment extends Component {
  constructor() {
    super();
    this.state = {
      body: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors && nextProps.UI.errors.postIdError === this.props.postId) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ 
        body: "",
        errors: "", 
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: ""
    });
  };

  //SENDING COMMENT +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.props);
    this.props.pushNewComment(this.props.postId, { body: this.state.body });
  };

  render() {
    const { classes } = this.props;
    const errors = this.state.errors;
    return (
      <form
        noValidate
        key="form"
        autoComplete="off"
        onSubmit={this.handleSubmit}
        className={classes.form}
      >
        <TextField
          id="body"
          type="body"
          name="body"
          value={this.state.body}
          multiline
          rows={Math.ceil(((this.state.body.length + 1) * 1) / 45)}
          placeholder={"Add a comment"}
          helperText={errors.comment}
          error={errors.comment ? true : false}
          onChange={this.handleChange}
          fullWidth
          className={classes.textForm}
          color="secondary"
        />
        <IconButton
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          className={classes.sendButton}
        >
          <SendIcon />
        </IconButton>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  pushNewComment,
};

InputComment.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(InputComment));
