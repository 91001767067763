import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "react-router-dom/Link";

const styles = {
  homeBottomSection: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor:"blue"
  },

  leftBox: {
    justifyContent: "center",
    width: "400px",
    height:"250px",
    paddingBottom:"50px",
    paddingTop:"50px",
    color:"black" ,
    margin:"50px 40px 0 40px",
    boxSizing:"border-box"
  },

  rightBox: {
    // backgroundColor: "lightgray",
    width: "400px",
    height:"250px",
    fontSize:"30vw",
    color:"blue",
    marginTop:"50px",
    boxSizing:"border-box"
  },

  imageWrapper: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  title: {
    marginBottom:"10px"
  },

  summary: {
    marginBottom:"20px"
  }
};

class HomeBottomSection extends Component {
  render() {
    const { classes, title, text, buttonText,buttonLink,ButtonColor,direction,imageUrl } = this.props;
    return (
      <div dir={direction} className={classes.homeBottomSection}>
        <div dir="ltr" className={classes.leftBox}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.summary}>
            <i>{text}</i>
          </Typography>
        </div>
        <div className={classes.rightBox} >
          <div className={classes.imageWrapper}>
            {/*<img  src={imageUrl} className={classes.imageWrapper} alt="Logo" />*/}
          </div>
        </div>
      </div>
    );
  }
}

HomeBottomSection.defaultProps = {
  direction: "ltr",
  title:"This is title",
  text:"description of the section",
  imageUrl:"/images/aboutHero.jpg"
};


/*withStyles(styles, [options]) => higher-order component
-----------------------------------------------------------------------------
Link a style sheet with a component using the higher-order component pattern.
It does not modify the component passed to it; instead, it returns a new
component with a classes property. This classes object contains the name of the
class names injected in the DOM.*/
export default withStyles(styles)(HomeBottomSection);
