import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "react-router-dom/Link";

const styles = {
  comingSoon: {
    //display: "flex",
    //justifyContent: "center",
    //position: "relative",
    minHeight: "500px",
        width: "100vw",
        backgroundPosition: "left center" /* Center the image */,
        backgroundRepeat: "no-repeat" /* Do not repeat the image */,
        backgroundSize: "cover" /* Resize the background image to cover the entire container */,
        boxSizing:"border-box",
  }

}

class HomeComingSoon extends Component{
    render() {
        const { classes, url, logo, title, subTitle, backgroundPosition } = this.props;
        return (
            <div className={classes.comingSoon}>
                <img src="../images/splash_1.png" width="100%" />
            </div>
        );
    }
}

export default withStyles(styles)(HomeComingSoon);