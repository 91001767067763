import React, {Component} from "react";
import {motion} from "framer-motion";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {Container} from "@material-ui/core";

import FilterButton from "./FilterButton.js";

//Icons
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as Helpful} from "../../assets/icons/trending_up_black.svg";
import {ReactComponent as CongratulationsOn} from "../../assets/icons/DitHelptMij.svg";
import {ReactComponent as CongratulationsOff} from "../../assets/icons/DitHelptMij_inactive.svg";
import {ReactComponent as inspiring} from "../../assets/icons/lightbulb_black_24dp.svg";
import {ReactComponent as Experience} from "../../assets/icons/article.svg";
// import { ReactComponent as ExperienceOn } from "../../assets/icons/story-24px.svg";
// import { ReactComponent as ExperienceOff } from "../../assets/icons/story-24px_Inactive.svg";
import {ReactComponent as QuestionOn} from "../../assets/icons/FAQ-24px.svg";
import {ReactComponent as QuestionOff} from "../../assets/icons/FAQ-24px_Inactive.svg";

import theme from "../../util/theme.js";

export const styles = (theme) => ({
    filterButton: {
        backgroundColor: "white",
    },
    bigWrapper: {
        position: "fixed",
        marginTop: "2vh",
        right: 0,
        height: "9vh",
        width: "90%",
        zIndex: 1,
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
        },
    },
    container: {
        position: "absolute",
        top: "0",
        height: "100%",
        width: "80%",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        [theme.breakpoints.down("lg")]: {
            justifyContent: "flex-start",
            marginTop: 0,
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
            paddingLeft: 0,
            height: "15vh",
            width: "65%",
            marginLeft: 100,
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 40,
            marginLeft: 70,
        },
    },
    background: {
        height: "100%",
        width: "100%",
        left: "55%",
        paddingLeft: 20,
        zIndex: 1,
        backgroundColor: theme.palette.primary.light,
        boxShadow: "3px 3px 5px 0px #0000001c",
        borderBottomLeftRadius: 15,
        borderTopLeftRadius: 15,
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 20,
        [theme.breakpoints.down("xl")]: {
            paddingRight: 80,
        },
        [theme.breakpoints.down("lg")]: {
            paddingRight: 20,
        },
        [theme.breakpoints.down("md")]: {
            paddingRight: 5,
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
            width: "100vw",
            left: "50%",
            height: "15vh",
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "space-between",
            height: "20vh",
            paddingLeft: 5,
        },

        //marginRight: 50,
    },
    filterText: {
        [theme.breakpoints.down("xs")]: {
            transform: "rotate(-90deg)",
            fontSize: 18,
            marginLeft: -20,
        },
    },
    filterButtons: {
        [theme.breakpoints.down("xs")]: {
            height: "8vh",
        },
    },
    secondTabs: {
        //margin: theme.spacing(3),
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            margin: 0,
        },
    },
    firstIcon: {
        margin: 6,
        height: 20,
    },
    inspiringOn: {
        margin: 6,
        height: 20,
        color: "orange",
    },
    helpfulOn: {
        margin: 6,
        height: 20,
        color: "#16DFC3",
    },
    experienceOn: {
        margin: 6,
        height: 20,
        color: "#E4C515",
    },
    iconsOff: {
        margin: 6,
        height: 20,
        color: "#B1B1B1",
    },


    arrowWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
});

const StyledTab = withStyles({
    root: {minWidth: 130},
    wrapper: {
        flexDirection: "row",
        marginBottom: 2,
    },
})(Tab);

//set here the ratio of user likes for a post to show up in filtering (write 20% as 0.2)
const ratioUserLikes = 0;

class FilterFeedMultiSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storiesFilter: 0,
            likeCount: false,
            iAgreeCount: false,
            interestingCount: false,
            open: false,
        };
    }

    defineLikesFilterLimit() {
        let likesLimit = Math.floor(this.props.usersCount * ratioUserLikes);
        likesLimit === 0 ? (likesLimit = 1) : (likesLimit = likesLimit);
        console.log("likesLimit = " + likesLimit);
        return likesLimit;
    }

    handleLikesFilter() {
        const likesLimit = this.defineLikesFilterLimit();

        let newPostSelection = [];

        console.log(
            "Congratulations: " +
            this.state.likeCount +
            " Helpful: " +
            this.state.iAgreeCount +
            " Inspiring: " +
            this.state.interestingCount
        );

        if (this.state.likeCount) {
            if (this.state.iAgreeCount) {
                if (this.state.interestingCount) {
                    //true true true
                    newPostSelection = this.props.posts;
                } else {
                    //true true false
                    newPostSelection = this.props.posts.filter(
                        (item) =>
                            item.likeCount >= likesLimit || item.iAgreeCount >= likesLimit
                    );
                }
            } else {
                if (this.state.interestingCount) {
                    //true false true
                    newPostSelection = this.props.posts.filter(
                        (item) =>
                            item.likeCount >= likesLimit || item.interestingCount >= likesLimit
                    );
                } else {
                    //true false false
                    newPostSelection = this.props.posts.filter(
                        (item) => item.likeCount >= likesLimit
                    );
                }
            }
        } else {
            if (this.state.iAgreeCount) {
                if (this.state.interestingCount) {
                    //false true true
                    newPostSelection = this.props.posts.filter(
                        (item) =>
                            item.iAgreeCount >= likesLimit ||
                            item.interestingCount >= likesLimit
                    );
                } else {
                    //false true false
                    newPostSelection = this.props.posts.filter(
                        (item) => item.iAgreeCount >= likesLimit
                    );
                }
            } else {
                if (this.state.interestingCount) {
                    //false false true
                    newPostSelection = this.props.posts.filter(
                        (item) => item.interestingCount >= likesLimit
                    );
                } else {
                    //false false false
                    newPostSelection = this.props.posts;
                }
            }
        }
        return newPostSelection;
    }

    handleChange_StoryFilter = (storyType) => {
        let filteredLikes_Selection = [];
        let newPostSelection = [];
        let newValue = [];

        //postSelection_likes needs to update HERE
        filteredLikes_Selection = this.handleLikesFilter();
        console.log("filteredLikes_Selection");
        console.log(filteredLikes_Selection);

        switch (storyType) {
            case "story":
                if (this.state.storiesFilter === 1) {
                    console.log("remove filters");
                    this.setState({storiesFilter: 0});
                    newPostSelection = filteredLikes_Selection;
                    newValue = [0, 0];
                } else {
                    console.log("filter stories");
                    this.setState({storiesFilter: 1});
                    newPostSelection = filteredLikes_Selection.filter(
                        //false
                        (post) => post.isQuestion === "0"
                    );
                    newValue = [1, 0];
                }
                break;
            case "question":
                if (this.state.storiesFilter === 2) {
                    console.log("remove filters");
                    this.setState({storiesFilter: 0});
                    newPostSelection = filteredLikes_Selection;
                    newValue = [0, 0];
                } else {
                    console.log("filter questions");
                    this.setState({storiesFilter: 2});
                    newPostSelection = filteredLikes_Selection.filter(
                        //true
                        (post) => post.isQuestion === "1"
                    );
                    newValue = [0, 1];
                }
                break;
            default:
                console.log("remove filters");
                this.setState({storiesFilter: 0});
                newPostSelection = filteredLikes_Selection;
                newValue = [0, 0];
                break;
        }

        this.props.filterPosts(newPostSelection, newValue);
    };

    changeLikeFilters() {
        let newPostSelection = [];

        switch (this.state.storiesFilter) {
            case 1:
                newPostSelection = this.props.posts.filter((post) => post.isQuestion === "0");
                break;
            case 2:
                newPostSelection = this.props.posts.filter((post) => post.isQuestion === "1");
                break;
            default:
                newPostSelection = this.props.posts;
                break;
        }

        return newPostSelection;
    }

    handleChange_LikeFilter = (likeType) => {
        //BTM old version: const likesArray = ["mooiCount", "ditHelpCount", "herkenbaarCount"];
        //Hallway DB/FrontEnd equivalent: like == congratulation; iAgree == helpful; interesting == inspiring

        const likesArray = ["likeCount", "iAgreeCount", "interestingCount"];
        const likesLimit = this.defineLikesFilterLimit();

        const notSelectedLikes = likesArray.filter((like) => like !== likeType);

        console.log(notSelectedLikes);

        //Diselects all if clicking turning on the third filter
        if (
            this.state[likeType] === false &&
            this.state[notSelectedLikes[0]] === true &&
            this.state[notSelectedLikes[1]] === true
        ) {
            this.setState({
                likeCount: false,
                iAgreeCount: false,
                interestingCount: false,
            });
        } else {
            this.setState({[likeType]: !this.state[likeType]});
        }

        let newPostSelection = [];
        let newValue = [0, 0, 0];

        //console.log("postSelection_stories");
        //console.log(this.state.postSelection_stories);

        if (this.state[likeType]) {
            if (this.state[notSelectedLikes[0]]) {
                if (this.state[notSelectedLikes[1]]) {
                    //false true true
                    newValue = [0, 1, 1];
                    newPostSelection = this.changeLikeFilters().filter(
                        (item) =>
                            item[notSelectedLikes[0]] >= likesLimit ||
                            item[notSelectedLikes[1]] >= likesLimit
                    );
                    console.log(
                        `${likeType} FALSE, ${notSelectedLikes[0]} TRUE, ${notSelectedLikes[1]} TRUE`
                    );
                } else {
                    //false true false
                    newValue = [0, 1, 0];
                    newPostSelection = this.changeLikeFilters().filter(
                        (item) => item[notSelectedLikes[0]] >= likesLimit
                    );
                    console.log(
                        `${likeType} FALSE, ${notSelectedLikes[0]} TRUE, ${notSelectedLikes[1]} FALSE`
                    );
                }
            } else {
                if (this.state[notSelectedLikes[1]]) {
                    //false false true
                    newValue = [0, 0, 1];
                    newPostSelection = this.changeLikeFilters().filter(
                        (item) => item[notSelectedLikes[1]] >= likesLimit
                    );
                    console.log(
                        `${likeType} FALSE, ${notSelectedLikes[0]} FALSE, ${notSelectedLikes[1]} TRUE`
                    );
                } else {
                    //false false false
                    newPostSelection = this.changeLikeFilters();
                }
            }
        } else {
            if (this.state[notSelectedLikes[0]]) {
                if (this.state[notSelectedLikes[1]]) {
                    //true true true
                    newPostSelection = this.changeLikeFilters();
                } else {
                    //true true false
                    newValue = [1, 1, 0];
                    newPostSelection = this.changeLikeFilters().filter(
                        (item) =>
                            item[likeType] >= likesLimit ||
                            item[notSelectedLikes[0]] >= likesLimit
                    );
                    console.log(
                        `${likeType} TRUE, ${notSelectedLikes[0]} TRUE, ${notSelectedLikes[1]} FALSE`
                    );
                }
            } else {
                if (this.state[notSelectedLikes[1]]) {
                    //true false true
                    newValue = [1, 0, 1];
                    newPostSelection = this.changeLikeFilters().filter(
                        (item) =>
                            item[likeType] >= likesLimit ||
                            item[notSelectedLikes[1]] >= likesLimit
                    );
                    console.log(
                        `${likeType} TRUE, ${notSelectedLikes[0]} FALSE, ${notSelectedLikes[1]} TRUE`
                    );
                } else {
                    //true false false
                    newValue = [1, 0, 0];
                    newPostSelection = this.changeLikeFilters().filter(
                        (item) => item[likeType] >= likesLimit
                    );
                    console.log(
                        `${likeType} TRUE, ${notSelectedLikes[0]} FALSE, ${notSelectedLikes[1]} FALSE`
                    );
                }
            }
        }

        this.props.filterPosts(newPostSelection, newValue);
    };

    //COMPONENT DID UPDATE MANAGES THE AUTOMATIC FILTERING OF THE NEW FETCHED POSTS
    automaticFiltering = () => {
        let filteredLikes_Selection = [];
        let newPostSelection = [];
        let newValue = [];

        //postSelection_likes needs to update HERE
        filteredLikes_Selection = this.handleLikesFilter();
        console.log("filteredLikes_Selection");
        console.log(filteredLikes_Selection);

        switch (this.state.storiesFilter) {
            case 1:
                console.log("filter stories");
                this.setState({storiesFilter: 1});
                newPostSelection = filteredLikes_Selection.filter(
                    (post) => post.isQuestion === "0"
                );
                newValue = [1, 0];
                break;
            case 2:
                console.log("filter questions");
                this.setState({storiesFilter: 2});
                newPostSelection = filteredLikes_Selection.filter(
                    (post) => post.isQuestion === "1"
                );
                newValue = [0, 1];
                break;
            default:
                console.log("remove filters");
                this.setState({storiesFilter: 0});
                newPostSelection = filteredLikes_Selection;
                newValue = [0, 0];
                break;
        }

        this.props.filterPosts(newPostSelection, newValue);
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.posts !== prevProps.posts) {
            if (this.props.posts !== undefined) {
                console.log("FILTERFEED DID UPDATE");
                this.automaticFiltering();
            }
        }
    };

    removeAllFilters = () => {
        this.setState({
            likeCount: false,
            iAgreeCount: false,
            interestingCount: false,
            storiesFilter: 0,
        });
        this.props.filterPosts(this.props.posts, [0, 0, 0]);
        this.handleOpen();
    };

    handleOpen = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    render() {
        const {classes, posts, usersCount} = this.props;

        const variants = {
            open: {x: "0%"},
            closed: {x: "85%"},
        };

        return (
            <motion.div
                animate={this.state.open ? "open" : "closed"}
                variants={variants}
                transition={{ease: "easeOut", duration: 0.3}}
                className={classes.bigWrapper}
            >
                <div
                    className={classes.background}
                    style={{
                        backgroundColor: this.state.open
                            ? theme.palette.primary.light
                            : theme.palette.secondary.main,
                    }}
                >
                    <div className={classes.arrowWrapper}>
                        <Button
                            onClick={this.handleOpen}
                            style={{
                                color: this.state.open ? theme.palette.secondary.main : "white",
                            }}
                            size="small"
                            color="secondary"
                            startIcon={
                                !this.state.open ? (
                                    <ArrowBackIosIcon/>
                                ) : (
                                    <ArrowForwardIosIcon/>
                                )
                            }
                        >
                            {!this.state.open && (
                                <Typography variant="body1" className={classes.filterText}>
                                    FILTERS
                                </Typography>
                            )}
                        </Button>
                    </div>
                    {(this.state.likeCount !== false ||
                        this.state.iAgreeCount !== false ||
                        this.state.interestingCount !== false ||
                        this.state.storiesFilter !== 0) && (
                        <Button
                            onClick={this.removeAllFilters}
                            color="secondary"
                            size="small"
                            className={classes.filterButtons}
                        >
                            Remove filters
                        </Button>
                    )}
                </div>
                <Container
                    className={classes.container}
                    maxWidth="lg"
                    style={{opacity: this.state.open ? 1 : 0}}
                >
                    <ButtonGroup
                        aria-label="outlined primary button group"
                        className={classes.secondTabs}
                    >
                        <FilterButton
                            iconActive={<SvgIcon component={Experience} className={classes.experienceOn}/>}
                            iconInActive={<SvgIcon component={Experience} className={classes.iconsOff}/>}
                            condition={this.state.storiesFilter === 1}
                            action={() => this.handleChange_StoryFilter("story")}
                            caption="Shares"
                        />
                        <FilterButton
                            iconActive={<QuestionOn className={classes.firstIcon}/>}
                            iconInActive={<QuestionOff className={classes.firstIcon}/>}
                            condition={this.state.storiesFilter === 2}
                            action={() => this.handleChange_StoryFilter("question")}
                            caption="Questions"
                        />
                    </ButtonGroup>
                    <ButtonGroup
                        aria-label="outlined primary button group"
                        className={classes.secondTabs}
                    >
                        <FilterButton
                            iconActive={<CongratulationsOn className={classes.firstIcon}/>}
                            iconInActive={<CongratulationsOff className={classes.firstIcon}/>}
                            condition={this.state.likeCount}
                            action={() => this.handleChange_LikeFilter("likeCount")}
                            caption="Congratulations"
                        />
                        <FilterButton
                            iconActive={<SvgIcon component={Helpful} className={classes.helpfulOn}/>}
                            iconInActive={<SvgIcon component={Helpful} className={classes.iconsOff}/>}
                            condition={this.state.iAgreeCount}
                            action={() => this.handleChange_LikeFilter("iAgreeCount")}
                            caption="Helpful"
                        />
                        <FilterButton
                            iconActive={<SvgIcon component={inspiring} className={classes.inspiringOn}/>}
                            iconInActive={<SvgIcon component={inspiring} className={classes.iconsOff}/>}
                            condition={this.state.interestingCount}
                            action={() => this.handleChange_LikeFilter("interestingCount")}
                            caption="Inspiring"
                        />
                    </ButtonGroup>
                </Container>
            </motion.div>
        );
    }
}

FilterFeedMultiSelect.defaultProps = {
    url: "images/faq-banner-img.png",
    title: "Page title",
    subTitle: "Subtitle",
    backgroundPosition: "left center",
};

FilterFeedMultiSelect.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    backgroundPosition: PropTypes.string,
};

export default withStyles(styles)(FilterFeedMultiSelect);
