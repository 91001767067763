import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../App.css";

const styles = {
  container: {
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },

  imageContainer: {
    height: "50vh",
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr",
    gridTemplateRows: "3fr 2fr",
    columnGap: "10px",
    rowGap: "10px",
    gridTemplateAreas: "'image1  image2 image3''image1 image2 image4'",
  },

  image: {
    backgroundPosition: "center" /* Center the image */,
    borderRadius: "20px",
    overflow: "hidden",
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  textInsideImage: {
    color: "white",
    textShadow: "1px 2px 3px rgba(0, 0, 0, 0.5)",
  },
};

const image1 = "images/meditation.jpg";
const image2 = "images/self-help.jpg";
const image3 = "images/reading.jpg";
const image4 = "images/newspaper.jpg";

function WhatIsMasksOff(props) {
  return (
    <div className={props.classes.container}>
      <p style={{ marginBlockStart: "0em", marginBlockEnd: "2em", lineHeight: "1.6"}}>
        Behind the Mask is een plek voor verpleegkundigen om verhalen en ervaringen te
        delen. Wij vinden het belangrijk dat je de mogelijkheid hebt om te
        praten over wat je hebt meegemaakt met mensen die aan een half woord
        genoeg hebben omdat ze dit zelf ook meemaken. Wie je bent, is daarvoor
        niet van belang. Daarnaast is biedt dit platformer ruimte voor
        zelf-hulp-tips en andere belangrijke informatie. Zo komen hier
        verschillende facetten samen om de zorg in Nederland weerbaar te maken.
      </p>
      <div className={props.classes.imageContainer}>
        <div
          className={props.classes.image}
          style={{ backgroundImage: `url(${image1})`, gridArea: "image1" }}
        >
          <h6 className={props.classes.textInsideImage}>Zelf hulp</h6>
        </div>
        <div
          className={props.classes.image}
          style={{ backgroundImage: `url(${image2})`, gridArea: "image2" }}
        >
          <h6 className={props.classes.textInsideImage}>Peer ondersteuning</h6>
        </div>
        <div
          className={props.classes.image}
          style={{ backgroundImage: `url(${image3})`, gridArea: "image3" }}
        >
          <h6 className={props.classes.textInsideImage}>Anonimiteit</h6>
        </div>
        <div
          className={props.classes.image}
          style={{ backgroundImage: `url(${image4})`, gridArea: "image4" }}
        >
          <h6 className={props.classes.textInsideImage}>Niews</h6>
        </div>
      </div>
    </div>
  );
}

/*withStyles(styles, [options]) => higher-order component
-----------------------------------------------------------------------------
Link a style sheet with a component using the higher-order component pattern.
It does not modify the component passed to it; instead, it returns a new
component with a classes property. This classes object contains the name of the
class names injected in the DOM.*/
export default withStyles(styles)(WhatIsMasksOff);
