import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import {handleDate} from "./functions/Date";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";

import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as DeleteIcon} from "../assets/icons/delete-24px.svg";
import {ReactComponent as ReportIcon} from "../assets/icons/report_black_24dp.svg";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {deletePost} from "../redux/actions/dataActions";

import Comments from "./Comments";
import UserDataTip from "./UserDataTip";
import InputComment from "./InputComment";
import MooiButton from "./MooiButton";
import HerkenbaarButton from "./HerkenbaarButton";
import DitHelpButton from "./DitHelpButton";
import SavePostButton from "./SavePostButton";
import ReportPopup from "./ReportPopup";
import {NavLink} from "react-router-dom";

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

const redTheme = createMuiTheme({palette: {primary: red}});

const styles = {
    card: {
        margin: "1vw",
        position: "relative",
        maxHeight: "100%",
        width: "100%",
    },
    postHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2vh",
    },

    cardContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "2vh",
        paddingTop: 0,
    },

    cardP1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        margin: "1vh",
        //marginTop: 0,
        marginBottom: "0",
    },
    cardP2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "2vh",
    },
    cardP3: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: 0,
        paddingTop: 0,
    },
    likesStack: {
        display: "flex",
        justifyContent: "flex-start",
    },
    handle: {
        padding: "1vh",
        float: "right",
    },
    navButton: {
        backgroundColor: "#fff",
        margin: "1vh",
    },
    media: {
        minHeight: 250,
        transition: "transform 0.2s ease-out",
    },
    mediaContainer: {
        overflow: "hidden",
    },
    //backgroundColor: "#999999",
    seeComments: {
        width: "40%",
    },
    avatar: {
        cursor: "pointer",
    },
};

class Post extends Component {
    state = {
        anchorEl: null,
        //expandComments: false,
        openOptions: false,
        deleteOpen: false,
        reportOpen: false,
        hovered: false,
        avatarHovered: null,
        anchorElAvatar: null,
    };

    handleMouseEnter = () => {
        this.setState({hovered: true});
    };

    handleMouseLeave = () => {
        this.setState({hovered: false});
    };

    handleClick = (event) => {
        this.setState({openOptions: true, anchorEl: event.currentTarget});
    };

    handleRequestClose = () => {
        this.setState({openOptions: false, reportOpen: false});
    };

    handleOpenReporting = () => {
        this.setState({reportOpen: true});
    };

    handleOpenOptions = () => {
        this.setState({deleteOpen: true});
    };
    handleCloseOptions = () => {
        this.setState({reportOpen: false});
        this.setState({deleteOpen: false});
        this.setState({openOptions: false});
    };

    handleExpandComments = () => {
        this.props.openModal(this.props.post);
        //this.setState({ expandComments: true });
    };

    handleAvatarEnter = (event) => {
        this.setState({avatarHovered: event.currentTarget});
        //console.log("avatar enter");
    };
    handleAvatarLeave = () => {
        this.setState({avatarHovered: null});
        //console.log("avatar out");
    };

    handleOpenPopper = (event) => {
        this.setState({
            anchorElAvatar: this.state.anchorElAvatar ? null : event.currentTarget,
        });
    };
    handleClosePopper = () => {
        this.setState({
            anchorElAvatar: null,
        });
    };

    render() {

        // console.log("Entering post.js with this post:");
        // console.log(this.props.post);
        //dayjs.extend(relativeTime);
        const {
            classes,
            post: {
                body,
                title,
                createdAt,
                userAvatar,
                userHandle,
                userSpecialisation,
                userExperience,
                likeCount,
                interestingCount,
                iAgreeCount,
                bookmarkCount,
                id,
                imageURL,
                comments, //each comment from post.comments should have the property commentId
                isQuestion,
            },
            user: {
                authenticated,
                credentials: {handle, avatar, role},
            },
            open,
            activeFilter,
        } = this.props;

        const {anchorEl, openOptions /*expandComments */} = this.state;

        const deletePost = () => {
            this.props.deletePost(id);
            this.setState({deleteOpen: false});
            this.setState({openOptions: false});
        };

        // console.log("userRole: " + role)
        // console.log("admin? " + (role === 'admin'))

        let avatarOpen = Boolean(this.state.avatarHovered);

        const popperOpen = Boolean(this.state.anchorElAvatar);
        const popperId = popperOpen ? "simple-popper" : undefined;

        return (
            <Card
                className={classes.card}
                style={{
                    backgroundColor: `${isQuestion ? "white" : "white"}`,
                }}
            >
                <CardHeader
                    avatar={
                        <div>
                            {userHandle === handle ? (
                                <Avatar
                                    className={classes.avatar}
                                    alt="Remy Sharp"
                                    src={
                                        userAvatar
                                            ? require("../assets/svg/" + userAvatar + ".svg")
                                            : null
                                    }
                                    aria-describedby={popperId}
                                    onMouseEnter={this.handleOpenPopper}
                                    onMouseLeave={this.handleClosePopper}
                                    component={NavLink}
                                    to={`/myprofile`}
                                />
                            ) : (<Avatar
                                    className={classes.avatar}
                                    alt="Remy Sharp"
                                    src={
                                        userAvatar
                                            ? require("../assets/svg/" + userAvatar + ".svg")
                                            : null
                                    }
                                    aria-describedby={popperId}
                                    onMouseEnter={this.handleOpenPopper}
                                    onMouseLeave={this.handleClosePopper}
                                    component={NavLink}
                                    to={`/profile/${userHandle}`}
                                />
                            )}
                            <Popover
                                style={{pointerEvents: "none"}}
                                id={popperId}
                                open={popperOpen}
                                anchorEl={this.state.anchorElAvatar}
                                onClose={this.handleClosePopper}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                            >
                                <UserDataTip
                                    avatar={userAvatar}
                                    handle={userHandle}
                                    specialisation={userSpecialisation}
                                    experience={userExperience}
                                />
                            </Popover>
                        </div>
                    }
                    action={
                        <IconButton
                            aria-label="More"
                            aria-owns={this.state.openOptions ? "more-options" : null}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    }
                    title={
                        <div>
                            {userHandle === handle ? (
                                <NavLink
                                    to={`/myprofile`}
                                    style={{color: "inherit", textDecoration: "none"}}
                                    onMouseEnter={this.handleOpenPopper}
                                    onMouseLeave={this.handleClosePopper}
                                >
                                    {userHandle}
                                </NavLink>
                            ) : (
                                <NavLink
                                    to={`/profile/${userHandle}`}
                                    style={{color: "inherit", textDecoration: "none"}}
                                    onMouseEnter={this.handleOpenPopper}
                                    onMouseLeave={this.handleClosePopper}
                                >
                                    {userHandle}
                                </NavLink>
                            )}
                            <Popover
                                style={{pointerEvents: "none"}}
                                id={popperId}
                                open={popperOpen}
                                anchorEl={this.state.anchorElAvatar}
                                onClose={this.handleClosePopper}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                            >
                                <UserDataTip
                                    avatar={userAvatar}
                                    handle={userHandle}
                                    specialisation={userSpecialisation}
                                    experience={userExperience}
                                />
                            </Popover>
                        </div>
                    }
                    subheader={handleDate(this.props.post)}
                />
                <Menu
                    id="more-options"
                    anchorEl={this.state.anchorEl}
                    open={this.state.openOptions}
                    onClose={this.handleRequestClose}
                >
                    {authenticated && userHandle === handle || role === 'admin' ? (
                        <MenuItem>
                            <Button
                                onClick={this.handleOpenOptions}
                                className={classes.deleteButton}
                            >
                                <SvgIcon component={DeleteIcon} color="secondary"/>
                            </Button>
                            <Button
                                onClick={this.handleRequestClose}
                                className={classes.deleteButton}
                            >
                                <ArrowBackIcon color="secondary"/>
                            </Button>
                            <Dialog
                                open={this.state.deleteOpen}
                                onClose={this.handleCloseOptions}
                                fullWidth
                                maxWidth="sm"
                            >
                                <DialogTitle>
                                    Are you sure you want to delete this post?
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={this.handleCloseOptions} color="secondary">
                                        Cancel
                                    </Button>
                                    <Button onClick={deletePost} color="secondary">
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </MenuItem>
                    ) : (
                        <MenuItem>
                            {this.state.reportOpen ? null : <MuiThemeProvider theme={redTheme}><Button
                                onClick={this.handleOpenReporting}
                                className={classes.deleteButton}
                            >
                                <SvgIcon component={ReportIcon} color="primary"/>
                            </Button></MuiThemeProvider>}
                            <Button
                                onClick={this.handleRequestClose}
                                className={classes.deleteButton}
                            >
                                <ArrowBackIcon color="secondary"/>
                            </Button>
                            {this.state.reportOpen ?
                                <ReportPopup postId={id} handleCloseOptions={this.handleCloseOptions}/> : null}
                        </MenuItem>
                    )}
                </Menu>
                <CardActionArea
                    onClick={this.handleExpandComments}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <div className={classes.mediaContainer}>
                        <CardMedia
                            className={classes.media}
                            style={{
                                transform: this.state.hovered ? "scale(1.01)" : "scale(1)",
                            }}
                            image={
                                imageURL
                                    ? imageURL
                                    : "https://images.unsplash.com/photo-1519810755548-39cd217da494?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
                            }
                        />
                    </div>
                    <CardContent className={classes.cardP1}>
                        <Typography variant="h5" color="textSecondary">
                            {title}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {body !== undefined
                                ? body.length < 250
                                    ? body
                                    : body.substr(0, 250)
                                : ""}
                            <Button
                                onClick={this.handleExpandComments}
                                className={classes.seeComments}
                                size="small"
                                color="secondary"
                            >
                                {" "}
                                {body !== undefined
                                    ? body.length < 250
                                        ? ""
                                        : "Show more"
                                    : ""}
                            </Button>
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardP3}>
                    <div className={classes.likesStack}>
                        <MooiButton
                            postId={id}
                            mooiCount={likeCount}
                            activeFilter={activeFilter}
                        />
                        <DitHelpButton
                            postId={id}
                            dithelpCount={iAgreeCount}
                            activeFilter={activeFilter}
                        />
                        <HerkenbaarButton
                            postId={id}
                            herkenbaarCount={interestingCount}
                            activeFilter={activeFilter}
                        />
                    </div>
                    <SavePostButton postId={id} activeFilter={activeFilter}/>
                </CardActions>
                <CardContent className={classes.cardContent}>
                    <Button
                        onClick={this.handleExpandComments}
                        className={classes.seeComments}
                        size="small"
                        color="secondary"
                    >
                        {" "}
                        {comments !== undefined
                            ? comments.length > 2
                                ? `See the ${comments.length} comments`
                                : "Show more"
                            : "Show more"}
                    </Button>
                    {comments ? (
                        <Comments
                            comments={comments} //commentId should be a property of each component of the array comments
                            open={open}
                            authenticated={authenticated}
                            handle={handle}
                            postId={id}
                            role={role}
                        />
                    ) : (
                        <div></div>
                    )}
                    <InputComment postId={id} className={classes.inputField}/>
                </CardContent>
            </Card>
        );
    }
}

/*
{open ? (
<CardContent>
<InputComment postId={postId} />
<Button
onClick={this.handleCompressComments}
className={classes.deleteButton}
>
<ExpandLessIcon color="secondary" />
</Button>
</CardContent>
) : (
<Button
onClick={this.handleExpandComments}
className={classes.deleteButton}
>
<ExpandMoreIcon color="secondary" />
</Button>
)}
*/

const mapStateToProps = (state) => ({
    user: state.user,
    //I need to put data here so that mapStateToProps rerenders when comments get into post.comments from redux state
    //data: state.data,
});

const mapActionsToProps = {
    deletePost,
};

//it will stop mapStateToProps from triggering if there are no changes in the condition (if it returns true)
//const areStatesEqual = (next, prev) => false; //next.user === prev.user;

Post.propTypes = {
    user: PropTypes.object.isRequired,
    deletePost: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(Post));