import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

const styles = {
  cardContainer: {
    margin: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  imageWrapper: {
    width: "150px",
    height: "150px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  textWrapper: {
    marginTop: "10px",
    textAlign: "center",
  },

  profileImage: {
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    borderRadius: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },

  arrow: {
    backgroundColor: "white",
    position: "absolute",
    right: "-20px",
    borderRadius: "100%",
    width: "40px",
    height: "40px",
    backgroundSize: "30% 30%",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    boxShadow: "1px 2px 6px rgba(0,0,0, 0.16)",
  },
};

const Arrow = styled.div`
  background-color: white;
  position: absolute;
  right: -20px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-image: url("images/VerticalArrow@2x.png");
  background-size: 30% 30%;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.16);
  transform: ${(props) => (props.hovered ? "rotate(-45deg)" : "rotate(0)")};
`;

function EmployeeProfile(props) {
  const {
    classes,
    image,
    name,
    proffesion,
    description,
    handleOpen,
    index,
    onClick,
  } = props;

  const [hover, setHover] = useState(false);

  return (
    <div
      className={classes.cardContainer}
      //onClick={handleOpen} //()=>handleOpen(index)
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div className={classes.imageWrapper}>
        <div
          className={classes.profileImage}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <Arrow hovered={hover} />
      </div>
      <div className={classes.textWrapper}>
        <Typography variant="h5">{name}</Typography>
        <Typography variant="body2">{proffesion}</Typography>
      </div>
    </div>
  );
}

EmployeeProfile.defaultProps = {
  image: "images/faq-banner-img.png",
  name: "Name",
  proffesion: "Proffesion",
  description: "Description",
  handleOpen: () => {},
  index: 0,
};

EmployeeProfile.propTypes = {
  image: PropTypes.string,
  backgroundPosition: PropTypes.string,
  name: PropTypes.string,
  proffesion: PropTypes.string,
  description: PropTypes.string,
  handleOpen: PropTypes.func,
  index: PropTypes.number,
};

export default withStyles(styles)(EmployeeProfile);
