import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  display: block;
  padding-left: 40px;
  padding-right: 20px;
  border-bottom: 1px solid #00A6D6;
  background: ${(props) =>
    props.hovered && !props.open ? "#FAFAFA" : "white"};
  padding-bottom: ${(props) => (props.open ? "25px" : "")};
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
`;

const Arrow = styled.div`
  width: 20px;
  height: 20px;
  background-image: url("images/TIP_ARROWOPEN.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
  margin: 30px;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "")};
`;

class FaqQuestion extends Component {
  //const [hovered, setHover] = useState(false);

  //const [open, setOpen] = useState(false);
  //const [selection, setSelection] = useState([]);
  /*
  const toggle = () => {
    setOpen(!open);
    props.openPage(props.page);
  }
  */
  //FaqQuestion.handleClickOutside = () => setOpen(false);

  render() {
    const { onClick, openPage, open, answer, question} = this.props;
    return (
      <Wrapper
        //onMouseEnter={() => setHover(true)}
        //onMouseOut={() => setHover(false)}
        //hovered={hovered}
        open={open}
        onClick={onClick}
      >
        <QuestionContainer
          className="(QuestionContainer)"
          //onKeyPress={() => toggle(!open)}
          //onClick={openPage(page)}
        >
          <h5_Lato>{question}</h5_Lato>
          <Arrow open={open}></Arrow>
        </QuestionContainer>
        {open && answer}
      </Wrapper>
    );
  }
}

FaqQuestion.defaultProps = {
  question: "Question",
  answer: <div />,
};

FaqQuestion.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.element,
};

export default FaqQuestion;
