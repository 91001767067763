import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "../../App.css";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const styles = (theme) => ({
    avatar: {
        width: 170,
        height: 170,
        alignSelf: "center",
    },
    dialog: {
        //top: "10vh",
        //bottom: "10vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    dialogPaper: {
        //minHeight: "85vh",
        maxHeight: "85vh",
        padding: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    title: {
        padding: 20,
    },
    actions: {
        padding: 20,
        justifyContent: "center",
    },
    form: {
        width: "40vw",
    },
    fieldContainer: {
        display: "flex",
        justifyContent: "flex-start",
    },
    textForm: {
        margin: 5,
        minWidth: "65%",
    },
    remove: {
        marginLeft: theme.spacing(3),
    },
});

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        border: "none",
    },
}))(ToggleButtonGroup);

class EditUserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            ...this.props.profileState,
        };
    }

    handleChange = (event) => {
        console.log(
            "handeling change of  " + event.target.id + " to " + event.target.value
        );
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleDelete = (e) => {
        document.getElementById(e.currentTarget.id).value = "";
    };

    /*
    componentWillReceiveProps(nextProps) {
      if (nextProps.UI.errors) {
        this.setState({ errors: nextProps.UI.errors });
      }
    }
  */
    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.props.open && this.setState({...this.props.profileState});
        }
        if (this.props.UI.errors !== prevProps.UI.errors) {
            this.setState({errors: this.props.UI.errors});
        }
    }

    render() {
        const {
            classes,
            open,
            handleClose,
            handleSubmit,
            profileState,
            UI: {loading},
        } = this.props;

        const {errors} = this.state;
        /*
        const removableArray = dataArray.filter(
          (item) => item !== "handle" || item !== "email"
        );
        */
        const dataArray = [
            // "email",
            // "name",
            // "surname",
            "specialisation",
            "experience",
            "institution",
            "hyperlink",
            "biography"
        ];
        const removableArray = [
            "hyperlink",
            "biography"
        ];

        const textFields = dataArray.map((item, index) => (
            <div className={classes.fieldContainer}>
                <TextField
                    key={index}
                    id={item}
                    label={item}
                    value={this.state[item]}
                    error={errors.item ? true : false}
                    onChange={this.handleChange}
                    variant="outlined"
                    color="secondary"
                    className={classes.textForm}
                />
                {removableArray.includes(item) && (
                    <Button
                        className={classes.remove}
                        id={item}
                        size="small"
                        color="secondary"
                        onClick={this.handleDelete}
                    >
                        Delete
                    </Button>
                )}
            </div>
        ));

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                className={classes.dialog}
                classes={{paper: classes.dialogPaper}}
            >
                <DialogTitle className={classes.title}>
                    <Typography variant="h5">Edit profile</Typography>
                </DialogTitle>
                <DialogContent /*className={classes.content}*/>
                    <form noValidate autoComplete="off" className={classes.form}>
                        {textFields}
                        {errors.general && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.general}
                            </Typography>
                        )}
                    </form>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleSubmit(this.state);
                        }}
                        variant="contained"
                        color="secondary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditUserDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
});

export default connect(mapStateToProps)(withStyles(styles)(EditUserDetails));
