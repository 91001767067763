import {
    COUNT_USERS,
    SET_USER,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    LIKE_POST,
    UNLIKE_POST,
    SAVE_POST,
    UNSAVE_POST,
    SET_EMAIL,
} from '../types';

const initialState = {
    numberOfUsers: 0,
    authenticated: false,
    credentials: {},
    likes: [],
    notifications: [],
    favorites: [],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COUNT_USERS:
            return {
                ...state,
                numberOfUsers: action.payload
            };
        case SET_EMAIL:
            return {
                ...state,
                credentials: {
                    email: action.payload
                },
                loading: false
            }
        case LOADING_USER:
            return {
                ...state,
                loading: true
            };
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            };
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_USER:
            console.log(action.payload);
            return {
                ...state,
                authenticated: true,
                loading: false,
                ...action.payload
            };

        case SAVE_POST:
            /*
            state.savedPosts.push({
                userHandle: action.payload.userHandle,
                postId: action.payload.postId,
            });
      */
            console.log('Save posts array: ');
            // console.log(state.likes);
            // console.log(state);
            console.log(state.favorites);

            return {
                ...state,
                favorites: [
                    ...state.favorites,
                    {
                        userHandle: action.payload.userHandle,
                        postId: action.payload.postId
                    }
                ]
            };
        case UNSAVE_POST:
            console.log('Unsaving post: ');
            console.log(action.payload);
            return {
                ...state,
                favorites: state.favorites.filter(
                    (save) => save.postId != action.payload.id
                )
            };
        case LIKE_POST:
            console.log('creden: ' + state.credentials)
            console.log('payload: ' + action.payload)
            return {
                ...state,
                likes: [
                    ...state.likes,
                    {
                        userHandle: action.payload.handle,
                        postId: action.payload.postId,
                        type: action.payload.type
                    }
                ]
            };
        case UNLIKE_POST:
            return {
                ...state,
                likes: state.likes.filter(
                    (like) =>
                        like.postId !== action.payload.postId ||
                        like.type !== action.payload.type
                )
            };
        default:
            return state;
    }
}
