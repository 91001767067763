import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import {Modal, Backdrop} from "@material-ui/core";
import OpenPostModal from "../components/OpenPostModal";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ProfileContentWrapper from "../components/ProfileContentWrapper.js";
import PostPreview from "../components/PostPreview";
import axios from "axios";
import {getPosts, getHandleInfo} from "../redux/actions/dataActions";

import StaticProfile from "../components/StaticProfile";

const styles = (theme) => ({
    root: {
        marginTop: "2vh",
        height: "88vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    grid: {
        height: "100%",
    },
    postsColumns: {
        height: "75vh",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    },
    profileWrapper: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
    },
    infoWrapper: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    postPreviewContainer: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(1),
    },
    modalFather: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        top: "10vh",
        bottom: "10vh",
        position: "absolute",
        outline: "none",
        overflow: "hidden",
    },
});

class otherprofile extends React.Component {
    state = {
        profile: null,
        profileStyled: null,
        myposts: [],
        activeModal: null,
    };

    handleOpenModal = (post) => {
        this.setState({
            activeModal: post,
        });
    };

    handleCloseModal = () => {
        this.setState({
            activeModal: null,
        });
    };

    componentDidMount() {
        const {
            classes,
            user: {authenticated, loadingUser},
            data: {posts, loadingPosts},
        } = this.props;

        const handleUser = this.props.match.params.handle;
        //changes the store state by sending to the store the following action
        //{ type: SET_USER, payload: res.data }
        this.props.getHandleInfo(handleUser);
        this.props.getPosts();

        axios.get(`/users/${handleUser}`)
            .then(res => {
                console.log("res: " + JSON.stringify(res.data));
                this.setState(
                    {
                        profile: res.data.credentials
                        //     {
                        //     // name: res.data.credentials.name,
                        //     // avatar: res.data.credentials.avatar,
                        //     // createdAt: res.data.credentials.createdAt,
                        //     // experience: res.data.credentials.experience,
                        //     // email: res.data.credentials.email,
                        //     // handle: res.data.credentials.handle,
                        //     // role: res.data.credentials.role,
                        //     // surname: res.data.credentials.surname
                        // }
                    },
                    function () {
                        let currentProfile = authenticated ? (
                            !loadingUser && this.state.profile != null ? (
                                <StaticProfile credentials={this.state.profile}/>
                            ) : (
                                <p>loading</p>
                            )
                        ) : (
                            []
                        );

                        let myposts = authenticated ? (
                            !loadingPosts && this.state.profile != null ? (
                                this.props.data.posts.map(
                                    (post) =>
                                        post.userHandle === this.state.profile.handle && (
                                            <Grid
                                                key={post.postId}
                                                item
                                                sm={12}
                                                className={this.props.classes.postPreviewContainer}
                                            >
                                                <PostPreview post={post} openModal={this.handleOpenModal}/>
                                            </Grid>
                                        )
                                )
                            ) : (
                                <p>loading</p>
                            )
                        ) : (
                            []
                        );

                        this.setState({
                            profileStyled: currentProfile,
                            myposts: myposts,
                        });
                    }
                )
            })
            .catch(err => console.log(err))
        //this.props.getPosts();
    }

    render() {
        const {
            classes,
            user: {authenticated},
        } = this.props;

        const {profileStyled, myposts} = this.state;

        const modalContent = (
            <Container maxWidth="lg" className={classes.paper}>
                {this.state.activeModal !== null && (
                    <OpenPostModal post={this.state.activeModal} open={true}/>
                )}
            </Container>
        );

        return profileStyled != null ? (
            authenticated ? (
                <Container className={classes.root} maxWidth="xl">
                    <Grid
                        container
                        justify="flex-start"
                        spacing={3}
                        className={classes.grid}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={4}
                            lg={3}
                            className={classes.profileWrapper}
                        >
                            {profileStyled}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={7}
                            md={8}
                            lg={9}
                            className={classes.infoWrapper}
                        >
                            <div className={classes.postsColumns}>
                                <ProfileContentWrapper
                                    title={this.state.profile.handle + "'s posts"}
                                    content={myposts}
                                    height={"100%"}
                                    background={"white"}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Modal
                        className={classes.modalFather}
                        open={this.state.activeModal != null ? true : false} //if acctive modal != null it will be open
                        onClose={this.handleCloseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            style: {backgroundColor: "black", opacity: "50%"},
                            timeout: 500,
                        }}
                    >
                        {this.state.activeModal != null ? modalContent : <div/>}
                    </Modal>
                </Container>
            ) : (
                <div className={classes.root}>You have to log in</div>
            )
        ) : (
            <div className={classes.root}>Loading...</div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    data: state.data
});

otherprofile.propTypes = {
    getHandleInfo: PropTypes.func.isRequired,
    getPosts: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

const mapActionsToProps = {
    getHandleInfo,
    getPosts,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(otherprofile));