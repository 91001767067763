import $ from 'jquery';
import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import styled from "styled-components";

const styles = {
    gototopbutton: {
        position: "Absolute",
        flex:"horizontal",
        left: "55%",
        marginTop:"-150px",
        "&:hover": {
            cursor: "pointer",
        },
    },

}

const Arrow = styled.div`
  width: 20px;
  height: 17px;
  background-image: url("images/TIP_ARROWOPEN@2x.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
  float:right;
  margin-left:5px;
  // margin-top:1px;
  transform: ${(props) =>
    props.open ? "" : "rotate(180deg)"};
`;

class GoTopButton extends Component {
    constructor() {
        super();
        this.onclick = this.onclick.bind(this);
    }

    render() {
        const {classes} = this.props;

        return (
            <p onClick={this.onclick} className={classes.gototopbutton}>Go to top <Arrow></Arrow></p>
        );
    }

    onclick() {
        $("html, body").animate({scrollTop: 0}, 300);
    }
}

export default withStyles(styles)(GoTopButton);