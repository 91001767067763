import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {pushNewComment, reportPost} from "../redux/actions/dataActions";
import PropTypes from "prop-types";
import PersonIcon from "@material-ui/icons/Person";
import Link from "react-router-dom/Link";
import Button from "@material-ui/core/Button";

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

const redTheme = createMuiTheme({palette: {primary: red}});

const styles = (theme) => ({
    filledButton: {
        margin: theme.spacing(2),
        float: 'right',
    },
    selectReason: {
        fontSize: 10,
        color: 'red',
        marginLeft: "5vh",
    },
});

//export default
class ReportPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {value: '', selectedReason: '', emptyReport: false}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(smth) {
        this.setState({value: smth.target.value})
    }

    handleSubmit(smth) {
        smth.preventDefault();
        var reportReason = this.state.selectedReason;
        if(reportReason === -1) {
            reportReason = this.state.value;
        }
        if(reportReason === "") {
            this.setState({emptyReport: true})
            return
        }

        this.props.reportPost(this.props.postId, { body: reportReason });
        alert('You have reported this post because: ' + reportReason)
        this.props.handleCloseOptions();
    }

    setSelectedReason(selected) {
        this.state.selectedReason = selected;
        console.log(this.state.selectedReason);
    }


    render() {
        const {classes} = this.props;
        return(
        <div className="modal">
            <div className="modal_content">
                <form onSubmit={this.handleSubmit}>
                    <h3>Why would you like to report this post?</h3>
                    <label>
                        <input type="radio" name="reasona" onChange={this.setSelectedReason.bind(this, "The post contains bad words")}/>The post contains bad words
                    </label> <br />

                    <label>
                        <input type="radio" name="reasona" onChange={this.setSelectedReason.bind(this, "The post contains hateful content")}/>The post contains hateful content
                    </label> <br />

                    <label>
                        <input type="radio" name="reasona" onChange={this.setSelectedReason.bind(this, "The post contains spam")}/>The post contains spam
                    </label> <br />

                    <label>
                        <input type="radio" name="reasona" onChange={this.setSelectedReason.bind(this, "The comments contain bad content")}/>The comments contain bad content
                    </label> <br />

                    <label><input type="radio" name="reasona" onChange={this.setSelectedReason.bind(this, -1)}/>
                        Other:⠀</label>
                    <input type="text" value={this.state.value} onChange={this.handleChange} /> <br />
                    {this.state.emptyReport ? <MuiThemeProvider theme={redTheme}>
                        <span className={classes.selectReason}>Please select and/or enter a reason</span></MuiThemeProvider>: null}
                    <div>
                            <Button
                                variant="contained"
                                className={classes.filledButton}
                                onClick={this.handleSubmit}
                            >
                                Send Report
                            </Button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapActionsToProps = {
    reportPost,
};

ReportPopup.propTypes = {
    user: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(ReportPopup));