import React from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import BannerTitle from "../components/BannerTitle.js";
import EmployeeProfile from "../components/EmployeeProfile.js";

const styles = {
  //Styles from Modal Component
  paper: {
    top: "10vh",
    left: "10vw",
    bottom: "10vh",
    right: "10vw",
    position: "absolute",
    backgroundColor: "white",
    borderRadius: "20px",
    outline: "none",
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridTemplateRows: "1fr",
  },
  cardImage: {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  cardTextContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "50px",
  },
  closingCross: {
    top: "30px",
    right: "30px",
    position: "absolute",
    width: "20px",
    height: "20px",
    backgroundPosition: "left center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  backdrop: {
    backgroundColor: "white",
  },

  //Styles from About page
  page: {
    display: "block",
    width: "100%",
  },
  container: {
    display: "block",
    margin: " auto",
    maxWidth: " 1000px",
  },
  contentWrapper: {
    marginBottom: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  titleContainer: {
    paddingLeft: "50px",
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  separator: {
    width: "100%",
    height: "1px",
    backgroundColor: "#00A6D6",
    marginTop: "1em",
    marginBottom: "1em",
  },
  peopleWrapper: {
    width: "100%",
    /*
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    columnGap: "10px",
    rowGap: "10px",
    gridTemplateAreas:
      "'popUp popUp popUp popUp popUp''popUp popUp popUp popUp popUp'",
      */
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  logosWrapper: {
    marginTop: "1em",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "80%",
  },
  partnerLogo: {
    width: "200px",
    height: "200px",
    margin: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
  },
};

function AboutPage(props) {
  const [activeModal, setActiveModal] = React.useState(null);

  const handleOpen = (index) => {
    setActiveModal(index);
  };

  const handleClose = () => {
    setActiveModal(null);
  };

  //selObject = pablo || victor || salvo ...
  var selObject =
    activeModal != null
      ? Object.keys(employees)[activeModal]
      : Object.keys(employees)[0];

  const employeeList = Object.keys(employees).map((item, index) => (
    <EmployeeProfile
      image={employees[item].image}
      name={employees[item].name}
      proffesion={employees[item].proffesion}
      onClick={() => handleOpen(index)}
      index={index}
    />
  ));

  const partnersList = Object.keys(partners).map((item, index) => (
    <div
      className={props.classes.partnerLogo}
      style={{ backgroundImage: `url(${partners[item]})` }}
    />
  ));

  const modalContent = (
    <Fade in={activeModal != null ? true : false} timeout={500}>
      <div className={props.classes.paper}>
        <div
          className={props.classes.closingCross}
          onClick={handleClose}
          style={{
            backgroundImage: `url(images/ICON_CROSS@2x.png)`,
          }}
        />
        <div
          className={props.classes.cardImage}
          style={{
            backgroundImage: `url(${employees[selObject].image})`,
          }}
        />
        <div className={props.classes.cardTextContainer}>
          <Typography variant="h2" id="simple-modal-title">
            {employees[selObject].name}
          </Typography>
          <Typography variant="subtitle2">
            {employees[selObject].proffesion}
          </Typography>
          <Typography
            variant="body1"
            id="simple-modal-description"
            style={{ marginTop: "3em" }}
          >
            {employees[selObject].description}
          </Typography>
        </div>
      </div>
    </Fade>
  );

  return (
    <div className={props.classes.page}>
      <div className={props.classes.container}>
        <BannerTitle
          url="images/aboutHero.jpg"
          title="Over ons"
          subTitle="Hier vind je meer informatie over wie er achter dit platform zit."
          backgroundPosition="0% 65%"
        />
        <div className={props.classes.contentWrapper}>
          <div className={props.classes.titleContainer}>
            <Typography variant="h3">Het MasksOff team</Typography>
          </div>
          <div className={props.classes.separator} />
          <div className={props.classes.peopleWrapper}>
            {employeeList}
            <Modal
              open={activeModal != null ? true : false} //if acctive modal != null it will be open
              onClose={handleClose}
              closeAfterTransition
              aria-labelledby="simple-modal-title" //employees[activeModal].name
              aria-describedby="simple-modal-description" //employees[activeModal].description
              BackdropComponent={Backdrop}
              BackdropProps={{
                style: { backgroundColor: "black" },
                timeout: 500,
              }}
            >
              {activeModal != null && modalContent}
            </Modal>
          </div>
          <div className={props.classes.separator} />
          <div className={props.classes.logosWrapper}>{partnersList}</div>
        </div>
      </div>
    </div>
  );
}

const partners = {
  TUDelft: "images/TU_Delft_logo_RGB.png",
  Hogeschool: "images/hogeschool-utrecht.svg",
  LUMC: "images/LUMCwithtext.png",
  ErasmusMC: "images/ErasmusMCwithtext.jpg",
  UMCUtretch: "images/umc-utrecht-1.svg",
  ThinkInnovation: "images/thinc2.png",
};

const lorenIpsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit donec consectetur semper nunc in molestie. Sed velit lectus, porttitor eu convallis sit amet, semper eget mauris. Integer in pulvinar mauris. Donec facilisis placerat magna sed cursus. Mauris vel tristique arcu. Duis congue orci id libero dictum sollicitudin. Curabitur dapibus arcu leo, condimentum tempus augue condimentum sed. Aliquam sed auctor ex. Nunc quis neque non eros dictum scelerisque ut ac urna. Etiam vel felis molestie, malesuada neque tempus, bibendum mauris. Nullam sit amet rhoncus nisl. Quisque non vehicula dui. Vestibulum neque tortor, placerat sit amet condimentum euismod, suscipit lobortis diam. Sed varius elit non erat dignissim vulputate.";

const employees = {
  pablo: {
    image: "images/avatar1.jpg",
    name: "Pablo",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  victor: {
    image: "images/avatar2.jpg",
    name: "Victor",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  merijn: {
    image: "images/avatar3.jpg",
    name: "Merijn",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
    backgroundPosition: "center top",
  },
  salvo: {
    image: "images/avatar4.jpg",
    name: "Salvo",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  maira: {
    image: "images/avatar5.jpg",
    name: "Maira",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  jennifer: {
    image: "images/avatar6.jpg",
    name: "Jennifer",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  samira: {
    image: "images/avatar7.jpg",
    name: "Samira",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  antonio: {
    image: "images/avatar8.jpg",
    name: "Antonio",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  maria: {
    image: "images/avatar9.jpg",
    name: "Maria",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
  juan: {
    image: "images/avatar10.jpg",
    name: "Juan",
    proffesion: "Designer",
    description: `${lorenIpsum}`,
  },
};

export default withStyles(styles)(AboutPage);
