import axios from "axios";
import {
  LOADING_UI,
  STOP_LOADING_UI,
  NEW_POST,
  CLEAR_ERRORS,
  SET_ERRORS,
  DELETE_POST,
  DELETE_COMMENT,
  SET_POSTS,
  SET_COMMENTS,
  SUBMIT_COMMENT,
  LOADING_DATA,
  LIKE_POST,
  UNLIKE_POST,
  SAVE_POST,
  UNSAVE_POST,
  NEW_FEEDBACK,
  NEW_IMAGE,
  ADD_POSTS,
  SET_HANDLE,
    SET_LIKES,
} from "../types";

//Post a Scream

export const pushNewPost = (image, newPost, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => pushNewPost()");
  axios.post("/user/image/New", image).then((res) => {
      // console.log(res);
    newPost.imageURL = res.data.imageURL;
    axios
      .post("/posts", newPost)
      .then((resp) => {
        dispatch({ type: NEW_POST, payload: resp.data });
        dispatch({ type: CLEAR_ERRORS });
        history.push("/feed");
      })
      .catch((err) => {
        dispatch({ type: SET_ERRORS, payload: err.response.data });
      });
  });
};

/**
 * Add the post to the database with the default image
 * @param imageURL The url of the default image
 * @param newPost The new post
 * @param history The window
 * @returns {(function(*): void)|*} Return nothing but dispatch action based post reponse
 */
export const pushNewPostDefaultImage = (imageURL, newPost, history) => (
  dispatch
) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => pushNewPost with def image()");
  newPost.imageURL= imageURL;
  axios
    .post("/posts", newPost)
    .then((resp) => {
      dispatch({ type: NEW_POST, payload: resp.data });
      dispatch({ type: CLEAR_ERRORS });
      history.push("/feed");
    })
    .catch((err) => {
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

/**
 * Function for adding a reaction to the post.
 * @param postId The id of the post
 * @param type This is the type of reaction(look at DATABASE)
 * @returns {(function(*): void)|*} Return nothing but dispatch actions based on post response
 */
export const likePost = (postId, type) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => likePost()");
  axios
    .post(`/posts/${postId}/` + type + `/like`)
    .then((res) => {
      res.data.type = type;
      dispatch({ type: LIKE_POST, payload: res.data });
    })
    .catch((err) => console.log(err, type));
};

/**
 * Function for removing a reaction from the post
 * @param postId The id of the post
 * @param type This is the type of reaction(look at DATABASE)
 * @returns {(function(*): void)|*} Return nothing but dispatch actions based on post response
 */
export const unlikePost = (postId, type) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => unlikePost()");
  axios
    .post(`/posts/${postId}/` + type + `/unlike`)
    .then((res) => {
      res.data.type = type;
      dispatch({ type: UNLIKE_POST, payload: res.data });
    })
    .catch((err) => console.log(err));
};

/**
 * Report a specific post by using the id
 * @param postId The id of the post to be reported
 * @param reason The reason for reporting the post
 * @returns {(function(*): void)|*} This functions returns nothing but dispatch action based the post response
 */
export const reportPost = (postId, reason) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    console.log("HTTP request => reportPost()");
    axios
        .post(`/posts/${postId}/report`, reason)
        .catch((err) => {
            dispatch({ type: SET_ERRORS, payload: {...err.response.data, postIdError: postId}});
        });
};

/**
 * Function for remembering  the save post
 * @param postId The id of the post
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on post response
 */
export const savePost = (postId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => savePost()");
  axios
    .post(`/posts/${postId}/save`)
    .then((res) => {
      dispatch({ type: SAVE_POST, payload: res.data });
    })
    .catch((err) => console.log(err));
};

/**
 * Function for unsaving the post
 * @param postId The id of the post
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on post response
 */
export const unSavePost = (postId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => unsavePost()");
  axios
    .post(`/posts/${postId}/unsave`)
    .then((res) => {
      dispatch({ type: UNSAVE_POST, payload: res.data });
    })
    .catch((err) => console.log(err));
};

//TOO MANY REQUESTS HAS SOMETHING TO dO WITH DISPATCH
/**
 * Function for uploading image to server
 * @param formData The formdata contains the image blob
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on post response
 */
export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => uploadImage()");
  axios
    .post("/user/image/New", formData)
    .then((res) => {
      console.log("THIS IS THE RESPONSE");
      console.log(res.data);
      dispatch({ type: NEW_IMAGE, payload: res.data });
    })
    .catch((err) => console.log(err));
};

/**
 * Get the comment of a post
 * @param postId The id of the post
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on get response
 */
export const getComments = (postId) => (dispatch) => {
  console.log(`HTTP request => getSinglePost(${postId})`);
  axios
    .get(`/posts/singlepost/${postId}`)
    .then((res) => {
      // console.log("getting comments of post in data actions" + postId);
      //console.log(res.data);//res.data is the posts array with no comments attached
      dispatch({ type: LOADING_DATA });
      dispatch({
        type: SET_COMMENTS,
        payload: res.data, //singlePost
      });
    })
    .catch((err) => {
      // console.log("It wasn't able to get post/:postId because: " + err);
      dispatch({
        type: SET_COMMENTS,
        payload: [],
      });
    });
};

export const getPosts = () => (dispatch, last = new Date().toISOString()) => {
  dispatch({ type: LOADING_DATA });
  console.log("HTTP request => fetchPosts()");

  last = 0;
  console.log("HTTP request => fetchPosts() last" + last);

  axios
    .get(`/posts/${last}`)   // Retrieve data
    .then((res) => {
      res.data.posts.forEach((post) => {
        // console.log("Trying dataActions -> getPosts reading postId:" + post.id);
        axios
          .get(`/posts/singlepost/${post.id}`)
          .then((res) => {

            dispatch({ type: LOADING_DATA });
            dispatch({
              type: SET_COMMENTS,
              payload: res.data, //singlePost
            });
          })
          .catch((err) => {
            // console.log("It wasn't able to get post/:postId because: " + err);
            dispatch({
              type: SET_COMMENTS,
              payload: [],
            });
          });
      });
      // console.log("Ending dataActions SET_POSTS-> getPosts --> payload"); //console.log(res.data.posts);
      dispatch({
        type: SET_POSTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_POSTS,
        payload: [],
      });
    });
};

export const getLikes = () => (dispatch) => {
    console.log(`HTTP request => get likes`);
    axios
        .get(`/posts/userlikes`)
        .then((res) => {
            dispatch({ type: LOADING_DATA });
            dispatch({
                type: SET_LIKES,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("It wasn't able to get /posts/userlikes because: " + err);
            dispatch({
                type: SET_LIKES,
                payload: [],
            });
        });
};

/**
 * Get new post based on last post displayed on user side
 * @param last The current last post the user sees
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on get response
 */
export const addPosts = (last) => (dispatch) => {
  //console.log("LAST FOR ADD");
  //console.log(last);
  console.log("HTTP request => addPost()");
  axios
    .get(`/posts/${last}`)
    .then((res) => {
      //console.log(res.data);
      if (!(res.data.posts === undefined || res.data.posts.length == 0)) {
        dispatch({ type: ADD_POSTS, payload: res.data });
      }
    })
    .catch((err) => {
      const dummy = {};
      dummy.posts = [];
      dummy.lastData = last;
      console.log("NO MORE POSTS");
      // dispatch({
      //   type: ADD_POSTS,
      //   payload: dummy,
      // });
    });
};

/**
 * Add new comment to the specific post using the id
 * @param postId The id of the post to be commented
 * @param newComment The comment for the post
 * @returns {(function(*): void)|*} This functions returns nothing but dispatch action based the post response
 */
export const pushNewComment = (postId, newComment) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  console.log("HTTP request => pushNewComment()");
  axios
    .post(`/posts/${postId}/comment`, newComment)
    .then((res) => {
      res.data !== undefined && console.log(res.data); //res.data includes commentId prop
      dispatch({ type: SUBMIT_COMMENT, payload: res.data });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({ type: SET_ERRORS, payload: {...err.response.data, postIdError: postId}});
    });
};

/**
 * Function for deleting the post based on post id
 * @param postId The id of the post
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on delete response
 */
export const deletePost = (postId) => (dispatch) => {
  console.log("HTTP request => deletePost()");
  console.log("post id ==== ", postId)
  axios
    .delete(`posts/${postId}`)
    .then(() => {
      dispatch({ type: DELETE_POST, payload: postId });
    })
    .catch((err) => console.log(err));
};

/**
 * Function for deleting the comment of a post
 * @param comment The comment to be deleted
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on delete response
 */
export const deleteComment = (comment) => (dispatch) => {
  console.log("HTTP request => deleteComment()");
  axios
    .delete(`/comments/${comment.commentId}`)
    .then(() => {
      dispatch({ type: DELETE_COMMENT, payload: comment });
      console.log("Comment deleted");
    })
    .catch((err) => console.log(err));
};

/**
 * The functions for sending a feedback. This functions is not doing anything
 * @param feedback The feedback to be sent
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on post response
 */
export const sendFeedback = (feedback) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  console.log("HTTP request => sendFeedback()");
  axios
    .post("/feedback", feedback)
    .then((res) => {
      dispatch({ type: NEW_FEEDBACK, payload: res.data });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
        console.log(err);
        console.log(err.response)
        console.log(err.response.data)
      dispatch({ type: SET_ERRORS, payload: err.response.data });
        console.log("dispatching")
    });
};

/**
 * Function for getting the handle
 * @param handle The handle that needs to be fetch from remote
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on get response
 */
export const getHandleInfo = (handle) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/users/${handle}`)
    .then((res) => {
      //console.log("getting comments of post in data actions" + postId);
      //console.log(res.data);//res.data is the posts array with no comments attached
      dispatch({
        type: SET_HANDLE,
        payload: res.data, //singlePost
      });
    })
    .catch((err) => {
      console.log("It wasn't able to get handle info: " + err);
      dispatch({
        type: SET_HANDLE,
        payload: [],
      });
    });
};
