// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
export const COUNT_USERS = "COUNT_USERS";

// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

// Data reducer types
export const SET_POSTS = "SET_POSTS";
export const SET_POST = "SET_POST";
export const ADD_POSTS = "ADD_POSTS";
export const SAVE_POST = "SAVE_POST";
export const UNSAVE_POST = "UNSAVE_POST";
export const LIKE_POST = "LIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";
export const DELETE_POST = "DELETE_POST";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const SET_COMMENTS = "SET_COMMENTS";
export const NEW_POST = "NEW_POST";
export const NEW_FEEDBACK = "NEW_FEEDBACK";
export const NEW_IMAGE = "NEW_IMAGE";
export const SET_HANDLE = "SET_HANDLE";
export const SET_LIKES = "SET_LIKES";
export const SET_EMAIL = "SET_EMAIL";