import React, { Component } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  button: {
    width: "100%",
    minWidth: 125,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    minWidth: 120,
    padding: 2,
  },
};

export class FilterButton extends Component {
  state = {
    hovered: false,
  };

  handleOnMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ hovered: false });
  };

  render() {
    const {
      classes,
      iconActive,
      iconInActive,
      condition,
      action,
      caption,
    } = this.props;

    return (
      <div
        className={classes.wrapper}
        style={{
          color: `${condition ? "#9be0d3" : "#bcbcbc"}`,
        }}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <Button
          className={classes.button}
          style={{
            backgroundColor: `${
              condition ? "white" : this.state.hovered ? "#EFEFEF" : "#f7f7f7"
            }`,
          }}
          color="inherit"
          size="small"
          endIcon={condition ? iconActive : iconInActive}
          onClick={action}
          variant={condition ? "contained" : "outlained"}
        >
          {caption}
        </Button>
      </div>
    );
  }
}

FilterButton.propTypes = {
  action: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterButton);
