import {
  NEW_POST,
  DELETE_POST,
  DELETE_COMMENT,
  SET_POSTS,
  LOADING_DATA,
  SET_COMMENTS,
  SUBMIT_COMMENT,
  LIKE_POST,
  UNLIKE_POST,
  NEW_FEEDBACK,
  ADD_POSTS,
  SET_HANDLE
} from '../types';

const initialState = {
  posts: [],
  filteredPosts: [],
  post: {},
  last: new Date().toISOString(),
  loading: false,
  handleUser: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
    case DELETE_POST:
      // TODO: Line 34 till 37 seems to be useless
      var index = state.posts.findIndex((post) => [
        post.postId === action.payload,
      ]);
      console.log("Index ===== " + index);
      state.posts.splice(index, 1);
      return {
        ...state,
        posts: state.posts.filter((post) => post.id != action.payload),
      };
    // TODO: Read how set post work
    case SET_POSTS:
      // console.log("Set posts is called and this is the payload");
      // console.log(action.payload);
      return {
        ...state,
        posts: action.payload.posts,
        last: action.payload.lastDate,
        loading: false,
      };
    // TODO: Read how add post work
    case ADD_POSTS:
      //console.log("Add posts is called and this is the payload");
      //console.log(action.payload);
      return {
        ...state,
        posts: state.posts.concat(action.payload.posts),
        last: action.payload.lastDate,
        loading: false,
      };
    case NEW_POST:
      // console.log("create new posts is called and this is the payload");
      // console.log(action.payload);
      return {
        ...state,
        posts: [action.payload, ...state.posts],
      };
    // TODO: Don't test it
    case SET_COMMENTS:
      state.posts != undefined &&
        state.posts.forEach((post) => {
          if (post.id == action.payload.postId) {
            post.comments = action.payload.comments;
          }
        });
      //console.log(state.posts);
      //console.log(state.loading);
      return {
        ...state,
        loading: false,
      };
    //TODO: Don't test it
    case SUBMIT_COMMENT:
      state.posts.forEach(post => {
        // HERE WE NEED TO KEEP THE == NO ===
        if (post.id == action.payload.postId) {

          post.comments = post.comments.concat(action.payload);
          post.comments.sort((a,b) => (a.commentId > b.commentId) ? -1 : ((b.commentId > a.commentId) ? 1 : 0));
          // post.comments = action.payload.concat(post.comments);
        }
      });
      return {
        ...state,
      };
    // TODO: Don't test it
    case DELETE_COMMENT:
      console.log("playload:");
      console.log(action.payload); //when clicking comment 3 it deletes comments[1] which equals comment 2
      console.log(state.posts); //when clicking comment 3 it deletes comments[1] which equals comment 2

      var postIndex = state.posts.findIndex(
        (post) => post.id == action.payload.postId);

      // console.log(state.posts);
      console.log("Post from where it came from " + postIndex);


      var commentIndex = state.posts[postIndex].comments.findIndex(
        (comment) => comment.commentId == action.payload.commentId
      );

      console.log("Comment to delete ====" + commentIndex);

      console.log(
        "this are the new comments after " +
          state.posts[postIndex].comments[commentIndex].body +
          " is deleted"
      );

      state.posts[postIndex].comments.splice(commentIndex, 1);

      var newCommentsArray = state.posts[postIndex].comments.filter(
        (element) => element != action.payload
      );

      console.log(state.posts);

      return {
        ...state,
        posts: [...state.posts],
      };
    // TODO: NEW_FEEDBACK doesn't do anything
    case NEW_FEEDBACK:
      return {
        ...state,
        loading: false,
      };
    case LIKE_POST:

      console.log("State posts =====");
      console.log(state.posts);
      console.log(action.payload);

      index = state.posts.findIndex(
        (post) => post.id === action.payload.id
      );
      state.posts[index] = {
        ...state.posts[index],
        ...action.payload,
      };
      if (state.post.id === action.payload.id) {
        state.post = action.payload;
      }
      return {
        ...state,
        loading: false,
      };
    case UNLIKE_POST:
      index = state.posts.findIndex(
        (post) => post.id === action.payload.id
      );
      state.posts[index] = {
        ...state.posts[index],
        ...action.payload,
      };
      if (state.post.id === action.payload.id) {
        state.post = action.payload;
      }
      return {
        ...state,
        loading: false,
      };
    // Don't test it
    case SET_HANDLE:
      return {
        ...state,
        loading: false,
        handleUser: action.payload,
      };
    default:
      return state;
  }
}
