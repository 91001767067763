import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { resendEmail } from '../redux/actions/userActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

const styles = {
    frame: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5vh',
        marginLeft: '10vh',
        alignItems: 'center'
    },
    formGrid: {
        boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3vh',
        marginBottom: '3vh',
        backgroundColor: '#EFEFEF'
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%'
    },
    resendButton: {
        position: 'relative',
        alignItems: 'center',
        margin: '3vh'
    }
};

export class verify extends Component {
    constructor() {
        super();
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.frame}>
                <Typography variant='h4'> Thank you for signing in </Typography>
                <Grid container spacing={4} className={classes.formGrid}>
                    <Grid item sm={10}>
                        <div className={classes.textContainer}>
                            <Typography variant='body3'> A confirmation email has been sent your email. Click on the confirmation link in your email to activate your account. If you haven't received an email, please also check your spam/junk/other folder.</Typography>
                        </div>
                    </Grid>
                    <Button
                        variant='contained'
                        onClick={
                            () => {
                                // const userEmail = 'yewijaf904@slowimo.com';
                                this.props.resendEmail(this.props.cred.email);
                            }
                        }
                        color='secondary'
                        size='medium'
                        className={classes.resendButton}
                    >
                        Resend the confirmation link
                </Button>
                </Grid>
            </Grid>
        );
    };
};

verify.propTypes = {
    classes: PropTypes.object.isRequired,
    cred: PropTypes.object.isRequired,
    resendEmail: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    cred: state.user.credentials
})

const mapActionsToProps = {
    resendEmail
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(verify));
