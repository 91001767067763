import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../App.css";

const styles = {
  pageContainer: {
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },

  container2: {
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "1fr",
    columnGap: "10px",
  },

  containerExplanation: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  iconEcplanationWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "25px",
  },

  postImg: {
    backgroundImage: "url('/images/POST_EXPANDED.png')",
    minHeight: "110vh" /* You must set a specified height */,
    width: "100%",
    backgroundPosition: "left" /* Center the image */,
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize: "contain",
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  iconWrapper: {
    minHeight: "10vh",
    minWidth: "10vw",
    backgroundPosition: "left" /* Center the image */,
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize: "contain",
  },
};

function PostExplanation(img, title, text, props) {
  return (
    <div className={props.classes.iconEcplanationWrapper}>
      <div
        className={props.classes.iconWrapper}
        style={{ backgroundImage: `url(${img})` }}
      ></div>
      <div className={props.classes.textWrapper}>
        <h5 style={{ marginBlockStart: "0", marginBlockEnd: "0" }}>{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
}

function HowWorksPost(props) {
  return (
    <div className={props.classes.pageContainer}>
      <p style={{ marginBlockStart: "0em", marginBlockEnd: "2em", lineHeight: "1.6"}}>
        Hoewel er veel overeenkomsten zijn met andere social media platforms,
        heeft Behind the Mask, net als veel andere platforms, ook zijn eigen specifieke
        functies. Hieronder zie je aan de linker kant een voorbeeld van hoe je
        je ervaring kunt delen of op ervaringen van anderen kunt reageren. Aan
        de rechter kant zijn de belangrijkste functies op een rijtje gezet.
      </p>
      <div className={props.classes.container2}>
        <div className={props.classes.postImg} />
        <div className={props.classes.containerExplanation}>
          {PostExplanation(
            "images/EMOJI_RECOG.png",
            "Herkenbaar",
            "Gebruik deze knop om aan te geven dat de ervaring herkenbaar is.",
            props
          )}
          {PostExplanation(
            "images/EMOJI_HELPS.png",
            "Dit helpt mij",
            "Gebruik deze knop om aan te geven dat de ervaring jou geholpen heeft.",
            props
          )}
          {PostExplanation(
            "images/EMOJI_BEAU.png",
            "Mooi",
            "Gebruik deze knop om aan te geven dat je een ervaring mooi vind. ",
            props
          )}
          {PostExplanation(
            "images/man-1.png",
            "Avatar",
            "Iedereen heeft een eigen avatar. Door hier op te klikken kan je het profiel bekijken van deze gebruiker. ",
            props
          )}
          {PostExplanation(
            "images/BUTTON_FAV.png",
            "Favorieten",
            "Voeg een post toe aan je favorieten om hem later terug te vinden in je profiel. ",
            props
          )}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(HowWorksPost);
