import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "react-router-dom/Link";

const styles = {
    imageWrapper: {
        height: "100px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        textAlign:"center"
    },

    headerTitle: {
        margin:"50px",
        textAlign: "center"
    },
    title: {
        marginBottom:"10px",
        textAlign: "center"
    },

    summary: {
        marginBottom:"20px",
        textAlign: "center"
    },

    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },

    column: {
        paddingLeft: "100px",
        paddingRight: "100px"
    }


};

class HomeSubTitle extends Component {
    render() {
        const { classes, title, text, imageUrl, title2, text2, imageUrl2, title3, text3, imageUrl3 } = this.props;
        return (
            <div>
                <div className={classes.headerTitle}><Typography variant="h2">Hoe werkt het?</Typography></div>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <div className={classes.imageWrapper} >
                            <div className={classes.imageWrapper}>
                                <img  src={imageUrl} className={classes.imageWrapper} alt="Logo" />
                            </div>
                        </div>
                        <Typography variant="h5" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography className={classes.summary}>
                            <i>{text}</i>
                        </Typography>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.imageWrapper} >
                            <div className={classes.imageWrapper}>
                                <img  src={imageUrl2} className={classes.imageWrapper} alt="Logo" />
                            </div>
                        </div>
                        <Typography variant="h5" className={classes.title}>
                            {title2}
                        </Typography>
                        <Typography className={classes.summary}>
                            <i>{text2}</i>
                        </Typography>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.imageWrapper} >
                            <div className={classes.imageWrapper}>
                                <img  src={imageUrl3} className={classes.imageWrapper} alt="Logo" />
                            </div>
                        </div>
                        <Typography variant="h5" className={classes.title}>
                            {title3}
                        </Typography>
                        <Typography className={classes.summary}>
                            <i>{text3}</i>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

HomeSubTitle.defaultProps = {
    title:"This is title",
    text:"description of the section",
    imageUrl:"/images/aboutHero.jpg",
    title2:"This is title",
    text2:"description of the section",
    imageUrl2:"/images/aboutHero.jpg",
    title3:"This is title",
    text3:"description of the section",
    imageUrl3:"/images/aboutHero.jpg"
};


/*withStyles(styles, [options]) => higher-order component
-----------------------------------------------------------------------------
Link a style sheet with a component using the higher-order component pattern.
It does not modify the component passed to it; instead, it returns a new
component with a classes property. This classes object contains the name of the
class names injected in the DOM.*/
export default withStyles(styles)(HomeSubTitle);
