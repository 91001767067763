import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";


const styles = {
    bannerSection: {
        display: "flex",
        width: "100%",
    },

    bannerContainer: {
        backgroundColor: "#cccccc" /* Used if the image is unavailable */,
        minHeight: "15vh",
        minWidth: "50vw",
        /* maxHeight: "25vh" /* You must set a specified height ,*/
        width: "100%",
        backgroundPosition: "left center" /* Center the image */,
        backgroundRepeat: "no-repeat" /* Do not repeat the image */,
        backgroundSize:
            "cover" /* Resize the background image to cover the entire container */,
        borderRadius: "20px",
        paddingTop: "20px",
        marginBottom: "1em",
        margintop: "-2em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },

    title: {
        textShadow: "1px 2px 3px rgba(0,0,0, 0.5)",
        color: "white",
    },
    subTitle: {
        fontSize: "20px",
        color: "white",
        marginRight: "20px"
    },

    phoneNumber: {
        height: "30px",
        marginTop:"3px",
        marginBottom:"3px",
        borderBottomLeftRadius: "10px",
        borderTopLeftRadius: "10px",
        backgroundColor: "white",
        color: "black",
        textAlign: "center",
        paddingTop: "10px",
        width: "200px",
        marginLeft: "auto"
    },

    infoBox: {
        height: "100px",
        flexDirection: "column",
        textAlign: "right"
    },

    helpLink:{
        textDecoration: "none",
        marginRight: "20px",
    },

    textLink:{
        textDecoration: "none",
        color:"white",
    },

    smallButton: {
        backgroundColor:"white",
        padding:"2px 6px 2px 6px ",
        fontSize: "9px",
        textTransform:"capitalize"
    },

};

const BannerLink = styled.a`
    text-decoration:none;
`;

const Logo = styled.div`
    height: 100px;
    width: 200px;
    border-radius:0 20px 20px 0;
    margin-top:5px;
    box-sizing:border-box;
    box-shadow:3px 3px 3px rgba(1,1,1,0.1);
    
    ${BannerLink}:hover & {
        background-position: 2px 0px;
        transition: 300ms;
        box-shadow: 3px 3px 3px rgba(1,1,1,0.2);
    }
`;

class BannerTitle extends Component {
    render() {
        const {classes, color, logoUrl, number, websiteUrl, topTitle, bottomTitle} = this.props;
        return (
            <BannerLink href={websiteUrl}
               target="_blank" >
            <div className={classes.bannerSection}>
                <div
                    className={classes.bannerContainer}
                    style={{
                        backgroundColor: `${color}`,
                    }}
                >
                    <a href={websiteUrl}
                       target="_blank"
                       className={classes.helpLink} >
                    <Logo
                         style={{
                             backgroundImage: `url(${logoUrl})`,
                             backgroundSize: "cover"
                         }}
                    >
                    </Logo>
                    </a>

                    <div className={classes.infoBox}>

                        <Typography className={classes.subTitle}>
                            {topTitle}
                        </Typography>

                        <div className={classes.phoneNumber}>
                            {number}
                        </div>

                        <Typography className={classes.subTitle}>
                            {bottomTitle}
                        </Typography>
                    </div>

                </div>
            </div></BannerLink>
        );
    }
}

BannerTitle.defaultProps = {
    websiteUrl: "images/faq-banner-img.png",
    topTitle: "Page title",
    bottomTitle: "Subtitle",
    backgroundPosition: "left center",
};


/*withStyles(styles, [options]) => higher-order component
-----------------------------------------------------------------------------
Link a style sheet with a component using the higher-order component pattern.
It does not modify the component passed to it; instead, it returns a new
component with a classes property. This classes object contains the name of the
class names injected in the DOM.*/
export default withStyles(styles)(BannerTitle);
