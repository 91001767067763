import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import BottomScrollListener from "react-bottom-scroll-listener";
import { Modal, Backdrop, Fade, Typography, Card } from "@material-ui/core";
import OpenPostModal from "../components/OpenPostModal";
import FilterFeed from "../components/feed/FilterFeed.js";
import FilterFeedMultiSelect from "../components/feed/FilterFeedMultiSelect.js";

import Post from "../components/Post";
import { Container } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as AddPost } from "../assets/icons/add_white_24dp.svg";

import theme from "../util/theme";

import { getPosts, getComments, getLikes, addPosts } from "../redux/actions/dataActions";
import { getUserData, countUsers } from "../redux/actions/userActions";

const styles = (theme) => ({
  page: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  frame: {
    position: "relative",
    paddingTop: "12vh",
    zIndex: 0,
  },
  post: {
    display: "flex",
    justifyContent: "center",
    height: "fit-content",
  },
  newPostButton: {
    position: "fixed",
    bottom: "3rem",
    right: "6rem",
    backgroundColor: theme.palette.secondary.main,
  },
  modalFather: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    top: "5vh",
    bottom: "5vh",
    position: "absolute",
    outline: "none",
    overflow: "hidden",
  },

  "@keyframes myEffect": {
    "0%": { backgroundPosition: "71% 0%" },
    "50%": { backgroundPosition: "30% 100%" },
    "100%": { backgroundPosition: "71% 0%" },
  },

  fakePostCard: {
    background: " linear-gradient(213deg, #c9c9c9, #fafafa)",
    backgroundSize: "600% 600%",
    margin: "1vw",
    position: "relative",
    height: "70vh",
    width: "100%",
    animation: "$myEffect 5s ease infinite",
  },
  icon: {
    //width: 20,
    //height: 20,
  },
});

export class feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentPostsMarkup: [1, 1, 1, 1, 1, 1],
      activeModal: null,
      filteredPostArray: [],
      activeFilter: [0, 0],
    };
  }

  componentDidMount() {
    console.log("FEED MOUNTS");
    this.props.getPosts();
    // console.log("These are the posts in feed.js")
    // console.log(this.props.getPosts());
    this.props.getUserData();
    // console.log(this.props.getUserData());
    this.props.countUsers();
    // console.log(this.props.countUsers());
  }

  //this.props.data.loading !== prevProps.data.loading
  //this.props.data.posts !== prevProps.data.posts
  componentDidUpdate(prevProps) {
    if (this.props.data.posts !== prevProps.data.posts) {
      if (this.props.data.posts !== undefined) {
        console.log("COMPONENT DID UPDATE");
        this.render();
      }
    }
    //THIS IS PROBABLY NOT NECESSARY
    if (this.props.user.favorites !== prevProps.user.favorites) {
      if (this.props.user.favorites !== undefined) {
        console.log("UPDATED FAVORITES");
        console.log(this.props.user.favorites);
        this.render();
      }
    }
  }

  filterPosts = (filteredPostSelection, filter) => {
    this.setState({
      recentPostsMarkup: filteredPostSelection,
      activeFilter: filter,
    });
  };

  handleBottom = () => {
    console.log("BOTTOM REACHED:");
    //console.log(this.props.data.last);
    //console.log("HTTP request => addPosts()");
    console.log("After reaching bottom this is our last: " + this.props.data.last)
    this.props.addPosts(this.props.data.last);
  };

  handleOpenModal = (post) => {
    this.setState({
      activeModal: post,
    });
  };

  handleCloseModal = () => {
    this.setState({
      activeModal: null,
    });
  };

  render() {
    const {
      classes,
      user: { authenticated },
    } = this.props;

    const modalContent = (
      <Container maxWidth="xl" className={classes.paper}>
        {this.state.activeModal !== null && (
          <OpenPostModal post={this.state.activeModal} open={true} />
        )}
      </Container>
    );

    let postsList = this.state.recentPostsMarkup.map((post, index) => (
      <Grid
        key={index} //post.postId
        item
        xs={12}
        sm={6}
        className={this.props.classes.post}
      >
        {authenticated ? (
          <Post
            post={post}
            openModal={this.handleOpenModal}
            activeFilter={[0, 0]}
          />
        ) : (
          <Card className={this.props.classes.fakePostCard} />
        )}
      </Grid>
    ));

    return (
      <div className={classes.page}>
        <div>
          <FilterFeedMultiSelect
            posts={this.props.data.posts}
            filterPosts={this.filterPosts}
            usersCount={this.props.user.numberOfUsers}
          />
          <Container className={classes.frame} maxWidth="lg">
            <Grid container>
              {postsList}
              <Modal
                className={classes.modalFather}
                open={this.state.activeModal != null ? true : false} //if acctive modal != null it will be open
                onClose={this.handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  style: { backgroundColor: "black", opacity: "50%" },
                  timeout: 500,
                }}
              >
                {this.state.activeModal != null ? (
                  modalContent
                ) : (
                  <div>hello</div>
                )}
              </Modal>
            </Grid>
            <BottomScrollListener onBottom={this.handleBottom} />
            <Tooltip title="Create a post">
              <Fab
                color="primary"
                aria-label="add"
                className={classes.newPostButton}
                component={Link}
                to="/addNewPost"
              >
                <SvgIcon component={AddPost} className={classes.icon} />
              </Fab>
            </Tooltip>
          </Container>
        </div>
      </div>
    );
  }
}

feed.propTypes = {
  getUserData: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  // orig  authenticated: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  //authenticated: state.user.authenticated,
  data: state.data,
  user: state.user,
});

const mapActionsToProps = {
  getPosts,
  getComments,
  getLikes,
  addPosts,
  getUserData,
  countUsers,
};

//prevents mapStateToProps rerender if data.posts remains unchanged
//const areStatesEqual = (next, prev) => false;
//const areStatesEqual = (next, prev) => next.data.posts === prev.data.posts;

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(feed));
