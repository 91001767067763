import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";

export const styles = {
  container: {
    //position: "absolute",
    //marginTop: 30,
    //marginLeft: 25,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    padding: 20,
  },
  avatar: {
    height: 70,
    width: 70,
  },
  description: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginLeft: 20,
  },
};

class UserDataTip extends Component {
  render() {
    const { classes, handle, specialisation, experience, avatar, hyperlink } = this.props;

    return (
      <Card className={classes.container}>
        <Avatar
          className={classes.avatar}
          alt="Remy Sharp"
          src={avatar ? require("../assets/svg/" + avatar + ".svg") : null}
        />
        <div className={classes.description}>
          <Typography variant="h6" color="textSecondary">
            {handle}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {specialisation}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {experience ? `${experience} years of experience` : ""}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {hyperlink}
          </Typography>
        </div>
      </Card>
    );
  }
}

UserDataTip.defaultProps = {
  url: "images/faq-banner-img.png",
  title: "Page title",
  subTitle: "Subtitle",
  backgroundPosition: "left center",
};

UserDataTip.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backgroundPosition: PropTypes.string,
};

export default withStyles(styles)(UserDataTip);
