import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Avatar from "@material-ui/core/Avatar";

import { connect } from "react-redux";
import Comment from "./Comment.js";
import ProfileContentWrapper from "./ProfileContentWrapper.js";

// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import theme from "../util/theme";

const styles = (theme) => ({
  container: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      //boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      //webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      backgroundColor: "#fafafa",
      //width: "0.1em",
      //backgroundClip: "content-box",
      borderRadius: "1em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "1em",
      //height: "1em",
    },
  },
});

export class Comments extends Component {
  state = {
    displayedComments: [],
  };

  mapComments = () => {
    //  console.log("hello map comments");
    let { comments, classes, open, authenticated, handle, postId, role } = this.props;

    let displayedComments;

    open
      ? (displayedComments = comments)
      : (displayedComments = comments.slice(0, 2));

    let commentsList = displayedComments.map((comment) => (
      <Comment
        key={comment.commentId}
        comment={comment}
        handle={handle}
        role={role}
        avatar={comment.userAvatar}
        authenticated={authenticated}
      />
    ));
    return commentsList;
  };

  componentDidMount = () => {
    //  console.log("component did mount");
    let commentsList = this.mapComments();
    //  console.log(commentsList);
    this.setState({ displayedComments: commentsList });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      //    console.log("updating component");
      let commentsList = this.mapComments();
      this.setState({ displayedComments: commentsList });
    }
  };

  render() {
    const { comments, classes, open } = this.props;

    let displayLenght = "10vh";

    comments.length > 3
      ? (displayLenght = "50vh")
      : (displayLenght = `${comments.length * 10}vh`);

    return (
      <div className={classes.container}>{this.state.displayedComments}</div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});
/*
const mapActionsToProps = {
  deleteComment,
};
*/
const areStatesEqual = (next, prev) => false;

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
};

export default withStyles(styles)(Comments);
//export default connect(null, mapActionsToProps)(withStyles(styles)(Comments));
