import React, { Component } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Container } from "@material-ui/core";

import FavoriteIcon from "@material-ui/icons/Favorite";
import GradeIcon from "@material-ui/icons/Grade";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";

export const styles = (theme) => ({
  bigWrapper: {
    position: "fixed",
    top: "8vh",
    height: "9vh",
    width: "100%",
    zIndex: 1,
  },
  container: {
    position: "absolute",
    top: "0",
    height: "100%",
    width: "80%",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      left: "45%",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      paddingLeft: 0,
      height: "15vh",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
      height: "15vh",
      left: "50%",
    },
  },
  background: {
    position: "absolute",
    top: "0vh",
    height: "100%",
    width: "90%",
    left: "55%",
    paddingLeft: 20,
    paddingRight: "10%",
    transform: "translateX(-50%)",
    zIndex: 1,
    backgroundColor: theme.palette.primary.light,
    boxShadow: "3px 3px 5px 0px #0000001c",
    borderBottomLeftRadius: 100,
    borderTopLeftRadius: 100,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("xl")]: {
      paddingRight: "25%",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: "5%",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "top",
      justifyContent: "flex-start",
      borderRadius: 0,
      width: "100vw",
      left: "50%",
      transform: "translateX(-50%)",
      height: "15vh",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      height: "20vh",
    },

    //marginRight: 50,
  },
  filterButtons: {
    [theme.breakpoints.down("xs")]: {
      height: "8vh",
    },
  },
  secondTabs: {
    //margin: theme.spacing(3),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  firstIcon: {
    margin: 6,
  },

  arrowWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

const StyledTab = withStyles({
  root: { minWidth: 130 },
  wrapper: {
    flexDirection: "row",
    marginBottom: 2,
  },
})(Tab);

/*
export const MyComponent = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <motion.nav
      animate={isOpen ? "open" : "closed"}
      variants={variants}
    >
      <Toggle onClick={() => setIsOpen(!isOpen)} />
      <Items />
    </motion.nav>
  )
}
*/
class FilterFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likeFilter: false,
      storyFilter: false,
      open: false,
    };
  }

  handleChangeLikes = (event, newValue) => {
    this.setState({ likeFilter: newValue });
    //console.log("All posts =>");
    //console.log(this.props.posts);
    //console.log("Moi filter =>");
    //console.log(this.props.posts.filter((item) => item.mooiCount > 0));
    let newPostSelection = [];
    switch (newValue) {
      case 0:
        newPostSelection = this.props.posts.filter(
          (item) => item.mooiCount > 0
        );
        break;
      case 1:
        newPostSelection = this.props.posts.filter(
          (item) => item.ditHelpCount > 0
        );
        break;
      case 2:
        newPostSelection = this.props.posts.filter(
          (item) => item.herkenbaarCount > 0
        );
        break;
      default:
        newPostSelection = this.props.posts;
        break;
    }
    //console.log("newPostSelection");
    //console.log(newPostSelection);
    this.props.filterPosts(newPostSelection, newValue);
  };

  handleChangeStories = (event, newValue) => {
    this.setState({ storyFilter: newValue });
  };

  removeFilters = () => {
    this.handleChangeLikes(null, false);
    this.handleChangeStories(null, false);
  };

  handleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, posts } = this.props;
    const { likeFilter, storyFilter, open } = this.state;

    const variants = {
      open: { x: "0%" },
      closed: { x: "80%" },
    };

    //console.log("likeFilter " + likeFilter);
    //console.log("storyFilter " + storyFilter);

    //<Paper square className={classes.container}>
    return (
      <motion.div
        animate={this.state.open ? "open" : "closed"}
        variants={variants}
        transition={{ ease: "easeOut", duration: 0.3 }}
        className={classes.bigWrapper}
      >
        <div className={classes.background}>
          <div className={classes.arrowWrapper}>
            <Button
              onClick={this.handleOpen}
              className={classes.filterButtons}
              size="small"
              color="secondary"
              startIcon={!open ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
            >
              {!open && "Filters"}
            </Button>
          </div>
          {(likeFilter !== false || storyFilter !== false) && (
            <Button
              onClick={this.removeFilters}
              color="secondary"
              size="small"
              className={classes.filterButtons}
            >
              Remove filters
            </Button>
          )}
        </div>
        <Container className={classes.container} maxWidth="lg">
          <Tabs
            //className={classes.tabs}
            //?? => if null, false or undefined goes to the second argument
            value={storyFilter ?? false}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={this.handleChangeStories}
            aria-label="disabled tabs example"
          >
            <StyledTab
              label="Experiences"
              icon={<GradeIcon className={classes.firstIcon} />}
              disableRipple={true}
            />
            <StyledTab
              label="Questions"
              icon={<GradeIcon className={classes.firstIcon} />}
              disableRipple={true}
            />
          </Tabs>
          <Tabs
            className={classes.secondTabs}
            value={likeFilter ?? false}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={this.handleChangeLikes}
            aria-label="disabled tabs example"
          >
            <StyledTab
              value={0}
              label="mooi"
              icon={<FavoriteIcon className={classes.firstIcon} />}
              disableRipple={true}
            />
            <StyledTab
              value={1}
              label="dit helpt"
              icon={<GradeIcon className={classes.firstIcon} />}
              disableRipple={true}
            />
            <StyledTab
              value={2}
              label="herkenbaar"
              icon={<InsertEmoticonIcon className={classes.firstIcon} />}
              disableRipple={true}
            />
          </Tabs>
        </Container>
      </motion.div>
    );
  }
}

FilterFeed.defaultProps = {
  url: "images/faq-banner-img.png",
  title: "Page title",
  subTitle: "Subtitle",
  backgroundPosition: "left center",
};

FilterFeed.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backgroundPosition: PropTypes.string,
};

export default withStyles(styles)(FilterFeed);
