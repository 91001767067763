import React, { Component } from "react";
import Link from "react-router-dom/Link";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../App.css";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { ReactComponent as Amb } from "../../assets/svg/amb.svg";
import { ReactComponent as Beard } from "../../assets/svg/beard.svg";
import { ReactComponent as Elderly } from "../../assets/svg/elderly.svg";
import { ReactComponent as Bun } from "../../assets/svg/bun.svg";
import { ReactComponent as Harry } from "../../assets/svg/harry.svg";
import { ReactComponent as Gaga } from "../../assets/svg/gaga.svg";
import { ReactComponent as Goatee } from "../../assets/svg/goatee.svg";
import { ReactComponent as Hippie } from "../../assets/svg/hippie.svg";
import { ReactComponent as Karen } from "../../assets/svg/karen.svg";
import { ReactComponent as Lady } from "../../assets/svg/lady.svg";
import { ReactComponent as Old } from "../../assets/svg/old.svg";
import { ReactComponent as Siri } from "../../assets/svg/siri.svg";
import { ReactComponent as Specs } from "../../assets/svg/specs.svg";
import { ReactComponent as Stache } from "../../assets/svg/stache.svg";
import { ReactComponent as Swoop } from "../../assets/svg/swoop.svg";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = {
  avatar: {
    width: 170,
    height: 170,
    alignSelf: "center",
  },
};

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    border: "none",
  },
}))(ToggleButtonGroup);

class ChangeAvatar extends Component {
  render() {
    const {
      classes,
      open,
      handleClose,
      profileState: { temporalAvatar },
      handleSubmit,
      handleAvatarChoice,
    } = this.props;

    const avatarsObject = {
      amb: <Amb />,
      beard: <Beard />,
      elderly: <Elderly />,
      bun: <Bun />,
      harry: <Harry />,
      gaga: <Gaga />,
      goatee: <Goatee />,
      hippie: <Hippie />,
      karen: <Karen />,
      lady: <Lady />,
      old: <Old />,
      siri: <Siri />,
      specs: <Specs />,
      stache: <Stache />,
      swoop: <Swoop />,
    };

    const avatarsList = Object.keys(avatarsObject).map((item, index) => (
      <ToggleButton
        key={index}
        value={item.toString()}
        aria-label={item.toString()}
        className={classes.avatarToggleButton}
      >
        <SvgIcon className={classes.avatar}>{avatarsObject[item]};</SvgIcon>
      </ToggleButton>
    ));

    return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Choose your new avatar!</DialogTitle>
        <DialogContent>
          <StyledToggleButtonGroup
            value={temporalAvatar}
            exclusive
            onChange={handleAvatarChoice}
            className={classes.avatarTypeToggleContainer}
            aria-label="avatar type selection"
          >
            {avatarsList}
          </StyledToggleButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(this.props.profileState);
            }}
            color="secondary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ChangeAvatar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  //avatar: PropTypes.string.isRequired,
  handleAvatarChoice: PropTypes.func.isRequired,
  //changeUserDetails: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChangeAvatar);
