const theme = {
  spacing: [0, 4, 8, 16, 32, 64],
  //*************************************** BREAKPOINTS ***************************************
  breakpoints: {
    values: {
      xs: 0,
      sm: 620,
      md: 880,
      lg: 1000,
      xl: 1250,
    },
  },

  //*************************************** COLORS ***************************************
  palette: {
    primary: {
      light: "#f7f7f7",
      dark: "#a7a7a7",
      main: "#EFEFEF",
      contrastText: "#000000",
    },
    secondary: {
      light: "#f1be3e",
      dark: "#61a4b4",
      main: "#00A6D6",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#F25F5C",
    },
    typography: {
      useNextVariants: true,
    },
  },

  //*************************************** TYPOGRAPHY ***************************************
  typography: {
    fontSize: 12,
    h1: {
      fontFamily: "Lora",
      fontSize: 60,
    },
    h2: {
      fontFamily: "Lora",
      fontSize: 50,
    },
    h3: {
      fontFamily: "Lora",
      fontSize: 45,
      paddingBottom: "0.8em",
    },
    h4: {
      fontFamily: "Lora",
      fontSize: 35,
    },
    h5: {
      fontFamily: "Lora",
      fontSize: 25,
      marginBlockEnd: "0.3em",
    },
    h6: {
      fontFamily: "Lora",
      fontWeight: "700",
      fontSize: 16,
    },
    subtitle1: {
      fontFamily: "Lato",
      fontSize: 20,
      //marginBlockStart: "0.5em",
      //marginBlockEnd: "0.5em",
    },
    subtitle2: {
      fontFamily: "Lato",
      fontSize: 18,
      //marginBlockStart: "0.5em",
      //marginBlockEnd: "0.5em",
    },
    body1: {
      fontFamily: "Lato",
      fontSize: 14,
    },
    body2: {
      fontFamily: "Lato",
      fontSize: 16,
      lineHeight: 1.8,
    },
    body3: {
      fontFamily: "Lato",
      fontSize: 20,
      lineHeight: 1.8,
    },
    button: {
      fontFamily: "Lato",
    },
    caption: {
      fontFamily: "Lora",
      fontSize: 10,
    },
    overline: {},
  },
};

//*************************************** COMPONENT OVVERRIDES ***************************************
theme.overrides = {
  ...theme.overrides,
  MuiCard: {
    root: {
      borderRadius: 15,
      boxShadow: "2px 0px 5px 0px rgba(0,0,0,0.15)",
    },
  },
  MuiCardHeader: {
    content: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      ...theme.typography.h6,
      marginRight: 10,
    },
    subheader: theme.typography.caption,
  },
  MuiButton: {
    // Name of the styleSheet
    text: {},
    contained: {},
    outlined: {},

    sizeSmall: {
      textTransform: "none",
      fontSize: 14,
      justifyContent: "flex-start",
    },
    containedSizeSmall: {
      padding: "6px 16px",
    },
    endIcon: {
      marginLeft: 5,
      marginRight: 0,
    },
  },
  MuiTooltip: {
    popper: {
      marginTop: -20,
    },
  },

  MuiToolbar: {
    regular: {
      paddingLeft: 11,
    },
  },

  MuiDrawer: {
    paper: {
      top: "8.5vh",
      height: "91.5vh",
    },
  },
  MuiTabs: {
    indicator: {
      bottom: "10%",
      height: "80%",
      borderRadius: "50px",
      backgroundColor: "white",
      zIndex: 0,
    },
  },
  MuiTab: {
    root: {
      position: "relative",
      zIndex: 1,
      fontSize: 14,
      textTransform: "capitalize",
      padding: 0,
    },
    labelIcon: {
      paddingTop: 0,
      minHeight: 50,
    },
  },

  MuiInputBase: {
    input: {
      padding: "15px 5px 15px 5px",
    },
    multiline: {
      padding: "15px 5px 15px 5px",
    },
  },

  MuiBackdrop: {
    root: {
      backgroundColor: "transparent",
      opacity: "80%",
    },
  },
};

export default theme;
