import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch, useLocation} from "react-router-dom";

import {Provider} from "react-redux";
import store from "./redux/store";
import {SET_AUTHENTICATED} from "./redux/types";
import {logoutUser, getUserData} from "./redux/actions/userActions";

import jwtDecode from "jwt-decode";

import "./App.css";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";
import theme from "./util/theme";

import home from "./pages/home";
import login from "./pages/login";
import signup from "./pages/signup";
import feed from "./pages/feed";
import faq from "./pages/faq";
import about from "./pages/about";
import contact from "./pages/contact";
import myprofile from "./pages/myprofile";
import addNewPost from "./pages/addNewPost";
import hulplijnen from "./pages/hulplijnen";

import Navbar from "./components/Navbar";
import AuthRoute from "./util/AuthRoute";
import axios from "axios";
import otherprofile from "./pages/otherprofile";
import verify from "./pages/verify";

const myTheme = responsiveFontSizes(createMuiTheme(theme));

axios.defaults.baseURL = "https://hallway-ide.tudelft.nl"
// "https://us-central1-masksoff-304c4.cloudfunctions.net/api";

const token = localStorage.HallwayToken;

if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
        window.location.href = "/login";
        store.dispatch(logoutUser());
    } else {
        store.dispatch({type: SET_AUTHENTICATED});
        axios.defaults.headers.common["x-access-token"] = token;
        store.dispatch(getUserData());
    }
}

const FourOhFour = () => {
    const {pathname} = useLocation()
    return <h3> No match for <code> {pathname}</code></h3>
}
class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={myTheme}>
                <Provider store={store}>
                    <div className="App">
                        <Router basename={'/'}>
                            <Navbar/>
                            <div className="container">
                                <Switch>
                                    <Route exact path="/" component={login}/>
                                    <AuthRoute exact path="/login" component={login}/>
                                    <AuthRoute exact path="/signup" component={signup}/>
                                    <Route exact path="/verify" component={verify}/>
                                    <Route exact path="/feed" component={feed}/>
                                    <Route exact path="/faq" component={faq}/>
                                    <Route exact path="/about" component={about}/>
                                    <Route exact path="/contact" component={contact}/>
                                    <Route exact path="/myprofile" component={myprofile}/>
                                    <Route exact path="/profile/:handle" component={otherprofile}/>
                                    <Route exact path="/addNewPost" component={addNewPost}/>
                                    <Route exact path="/hulplijnen" component={hulplijnen}/>
                                    <Route path="*" component={FourOhFour}/>
                                </Switch>
                            </div>
                        </Router>
                    </div>
                </Provider>
            </MuiThemeProvider>
        );
    }
}

export default App;
