import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SvgIcon from "@material-ui/core/SvgIcon";
import Link from '@material-ui/core/Link';
import Checkbox from "@material-ui/core/Checkbox";

import {connect} from "react-redux";
import {signUpUser} from "../redux/actions/userActions";
import {ReactComponent as Amb} from "../assets/svg/amb.svg";
import {ReactComponent as Beard} from "../assets/svg/beard.svg";
import {ReactComponent as Elderly} from "../assets/svg/elderly.svg";
import {ReactComponent as Bun} from "../assets/svg/bun.svg";
import {ReactComponent as Harry} from "../assets/svg/harry.svg";
import {ReactComponent as Gaga} from "../assets/svg/gaga.svg";
import {ReactComponent as Goatee} from "../assets/svg/goatee.svg";
import {ReactComponent as Hippie} from "../assets/svg/hippie.svg";
import {ReactComponent as Karen} from "../assets/svg/karen.svg";
import {ReactComponent as Lady} from "../assets/svg/lady.svg";
import {ReactComponent as Old} from "../assets/svg/old.svg";
import {ReactComponent as Siri} from "../assets/svg/siri.svg";
import {ReactComponent as Specs} from "../assets/svg/specs.svg";
import {ReactComponent as Stache} from "../assets/svg/stache.svg";
import {ReactComponent as Swoop} from "../assets/svg/swoop.svg";
import {Tooltip} from "@material-ui/core";

const styles = {
    frame: {
        display: "flex",
        flexDirection: "column",
        marginTop: "5vh",
        marginLeft: "10vh",
        alignItems: "center",
    },
    formGrid: {
        boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "3vh",
        marginBottom: "3vh",
        backgroundColor: "#EFEFEF",
    },
    divider: {
        color: "red",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        width: "59vh",
        alignItems: "center",
    },
    avatarSelection: {
        display: "flex",
        justifyContent: "space-between",
        margin: "2vh",
        flexWrap: "wrap",
    },
    avatarSelectionGrid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    avatarTypeToggleContainer: {
        margin: "1vh",
        alignSelf: "center",
        flexWrap: "wrap",
        border: "none",
    },
    avatar: {
        //margin: "0.5vh",
        marginRight: "1vh",
        width: 55,
        height: 55,
    },
    textForm: {
        marginTop: "2vh",
        backgroundColor: "white",
    },
    avatarToggleButton: {
        alignItems: "center",
        justifyContent: "center",
    },
    loginButton: {
        position: "relative",
        alignItems: "center",
        margin: "3vh",
    },
    chosenAvatar: {
        width: 200,
        height: 200,
    },
    textContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
    },
    codeOfConduct: {
        marginTop: "-1vh",
        fontSize: 10,
    },
    termsAndConditions: {
        fontSize: 10,
    },
    tooltip: {
        fontSize: 10,
    },
    errorMessageCheckbox: {
        fontSize: 10,
        color: 'red',
        marginLeft: "5vh",
    },
};

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        border: "none",
    },
}))(ToggleButtonGroup);

export class signup extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            surname: "",
            email: "",
            password: "",
            confirmPassword: "",
            department: "",
            specialisation: "",
            experience: "",
            handle: "",
            hyperlink: "",
            avatar: "amb",
            termsAndConditions: false,
            codeOfConductTUDelft: false,
            errors: {},
            avatarSource: (
                <Avatar
                    alt="Remy Sharp"
                    src={require("../assets/svg/amb.svg")}
                    style={{
                        width: 200,
                        height: 200,
                    }}
                />
            ),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({errors: nextProps.UI.errors});
        }
    }

    handleAvatar = (event, avatarChoice) => {
        this.setState({
            avatar: avatarChoice,
            avatarSource: (
                <Avatar
                    alt="Remy Sharp"
                    src={require("../assets/svg/" + avatarChoice + ".svg")}
                    style={{
                        width: 200,
                        height: 200,
                    }}
                />
            ),
        });
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleCheckboxTC = () => {
        if (this.state.termsAndConditions) {
            this.setState({
                termsAndConditions: false,
            });
            console.log(this.state.termsAndConditions);
        } else {
            this.setState({
                termsAndConditions: true,
            });
            console.log(this.state.termsAndConditions);
        }
    };

    handleCheckboxCC = () => {
        if (this.state.codeOfConductTUDelft) {
            this.setState({
                codeOfConductTUDelft: false,
            });
            console.log(this.state.codeOfConductTUDelft);
        } else {
            this.setState({
                codeOfConductTUDelft: true,
            });
            console.log(this.state.codeOfConductTUDelft);
        }
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        // console.log("password");
        // console.log(this.state.password);
        console.log(this.state.errors);

        const newHandle = this.state.name + " " + this.state.surname;

        this.state.handle = newHandle;

        const userData = {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            handle: this.state.handle,
            avatar: this.state.avatar,
            phone: this.state.phone,
            name: this.state.name,
            surname: this.state.surname,
            dob: this.state.dob,
            department: this.state.department,
            specialisation: this.state.specialisation,
            experience: this.state.experience,
            termsAndConditions: this.state.termsAndConditions,
            codeOfConductTUDelft: this.state.codeOfConductTUDelft,
            hyperlink: this.state.hyperlink
        };

        this.props.signUpUser(userData, this.props.history);
    };

    handleClickTooltip = () => {
        window.open("https://www.tudelft.nl/en/ide/about-ide/people/");
    };

    render() {
        const {
            classes,
            UI: {loading},
        } = this.props;
        const {errors} = this.state;
        return (
            <Grid container className={classes.frame}>
                <Typography variant="h4"> Register an account </Typography>
                <Divider classes={{root: classes.divider}}/>
                <Grid container spacing={4} className={classes.formGrid}>
                    <Grid item sm={6}>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            className={classes.form}
                        >
                            <div className={classes.textContainer}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="name"
                                    type="name"
                                    value={this.state.name}
                                    helperText={errors.name}
                                    error={errors.name ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div className={classes.textContainer}>
                                <TextField
                                    id="surname"
                                    name="surname"
                                    label="surname"
                                    type="surname"
                                    value={this.state.surname}
                                    helperText={errors.surname}
                                    error={errors.surname ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div className={classes.textContainer}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="TU Delft email"
                                    type="email"
                                    value={this.state.email}
                                    helperText={errors.emailRegister}
                                    error={errors.emailRegister ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div className={classes.textContainer}>
                                <Tooltip title={
                                    <div>
                                        Please put the link to your personal TU Delft profile. You can find your profile
                                        <a target="_blank" href="https://www.tudelft.nl/en/ide/about-ide/people/" color='secondary'> here</a>
                                    </div>}
                                         arrow interactive className={classes.tooltip}>
                                    <TextField
                                        id="hyperlink"
                                        label="TU Delft profile hyperlink"
                                        type="hyperlink"
                                        name="hyperlink"
                                        color="secondary"
                                        value={this.state.hyperlink}
                                        helperText={errors.hyperlink}
                                        error={errors.hyperlink ? true : false}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                        className={classes.textForm}
                                    />
                                </Tooltip>
                            </div>
                            <div className={classes.textContainer}>
                                <TextField
                                    id="password"
                                    label="password"
                                    type="password"
                                    name="password"
                                    color="secondary"
                                    value={this.state.password}
                                    helperText={errors.password}
                                    error={errors.password ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div className={classes.textContainer}>
                                <TextField
                                    id="confirmPassword"
                                    label="confirm password"
                                    type="password"
                                    name="confirmPassword"
                                    color="secondary"
                                    value={this.state.confirmPassword}
                                    helperText={errors.confirmPassword}
                                    error={errors.confirmPassword ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div className={classes.textContainer}>
                                <TextField
                                    id="department"
                                    name="department"
                                    label="department"
                                    type="department"
                                    value={this.state.department}
                                    helperText={errors.department}
                                    error={errors.department ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div className={classes.textContainer}>
                                <TextField
                                    id="specialisation"
                                    name="specialisation"
                                    label="specialisation"
                                    type="specialisation"
                                    value={this.state.specialisation}
                                    helperText={errors.specialisation}
                                    error={errors.specialisation ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div className={classes.textContainer}>
                                <TextField
                                    id="experience"
                                    name="experience"
                                    label="years of experience"
                                    type="experience"
                                    value={this.state.experience}
                                    helperText={errors.experience}
                                    error={errors.experience ? true : false}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    className={classes.textForm}
                                />
                            </div>
                            <div
                                className={classes.termsAndConditions}
                            >
                                <Checkbox
                                    value="checkedA"
                                    inputProps={{'aria-label': 'Checkbox A'}}
                                    helperText={errors.termsAndConditions}
                                    error={errors.termsAndConditions ? true : false}
                                    required={true}
                                    onChange={this.handleCheckboxTC}
                                />
                                I agree to the <Link target="_blank"
                                                     href="..\..\assets\documents\Hallway_Community_Guidelines.pdf"
                                                     color='secondary'>Hallway Community Guidelines </Link> and I
                                will
                                report any violation of it.
                                <div
                                    className={classes.codeOfConduct}
                                >
                                    <Checkbox
                                        value="checkedA"
                                        inputProps={{'aria-label': 'Checkbox A'}}
                                        // helperText={errors.codeOfConductTUDelft}
                                        error={errors.codeOfConductTUDelft ? true : false}
                                        required={true}
                                        onChange={this.handleCheckboxCC}
                                    />
                                    I agree to the TU Delft <Link target="_blank"
                                                                  href="https://www.tudelft.nl/en/about-tu-delft/strategy/integrity-policy/tu-delft-code-of-conduct"
                                                                  color='secondary'> code of conduct </Link>
                                    <div className={classes.errorMessageCheckbox}>{errors.termsAndConditions}</div>
                                    <div
                                        className={classes.errorMessageCheckbox}>{errors.codeOfConductTUDelft}</div>
                                </div>
                            </div>

                            {errors.general && (
                                <Typography variant="body2" className={classes.customError}>
                                    {errors.general}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.loginButton}
                            >
                                Register an account
                                {loading && <CircularProgress className={classes.spinner}/>}
                            </Button>
                        </form>
                    </Grid>
                    <Grid item sm={6} className={classes.avatarSelectionGrid}>
                        {this.state.avatarSource ? this.state.avatarSource : <div/>}

                        <StyledToggleButtonGroup
                            value={this.state.avatar}
                            exclusive
                            onChange={this.handleAvatar}
                            className={classes.avatarTypeToggleContainer}
                            aria-label="avatar type selection"
                        >
                            <ToggleButton
                                value={"swoop"}
                                aria-label="swoop"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Swoop/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"amb"}
                                aria-label="amb"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Amb/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"beard"}
                                aria-label="beard"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Beard/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"bun"}
                                aria-label="bun"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Bun/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"elderly"}
                                aria-label="elderly"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Elderly/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"gaga"}
                                aria-label="gaga"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Gaga/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"goatee"}
                                aria-label="goatee"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Goatee/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"harry"}
                                aria-label="harry"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Harry/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"hippie"}
                                aria-label="hippie"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Hippie/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"karen"}
                                aria-label="karen"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Karen/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"lady"}
                                aria-label="lady"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Lady/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"old"}
                                aria-label="old"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Old/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"siri"}
                                aria-label="siri"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Siri/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"specs"}
                                aria-label="specs"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Specs/>
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton
                                value={"stache"}
                                aria-label="stache"
                                className={classes.avatarToggleButton}
                            >
                                <SvgIcon className={classes.avatar}>
                                    <Stache/>
                                </SvgIcon>
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        )
            ;
    }
}

signup.propTypes =
    {
        classes: PropTypes.object.isRequired,
        signUpUser: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        UI: PropTypes.object.isRequired,
    };

const mapStateToProps = (state) => (
    {
        user: state.user,
        UI: state.UI,
    }
);

const mapActionsToProps =
    {
        signUpUser,
    };

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(signup));

/*

    <TextField
        id="dob"
        name="dob"
        label="geboortedatum"
        type="dob"
        value={this.state.dob}
        helperText={errors.dob}
        error={errors.dob ? true : false}
        onChange={this.handleChange}
        variant="outlined"
        color="secondary"
        fullWidth
        className={classes.textForm}
    />

    <TextField
        id="phone"
        name="phone"
        label="telefoonnummer"
        type="phone"
        value={this.state.phone}
        helperText={errors.phone}
        error={errors.phone ? true : false}
        onChange={this.handleChange}
        variant="outlined"
        color="secondary"
        fullWidth
        className={classes.textForm}
    />

*/