import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as FavoriteOn } from "../assets/icons/favorite_active-24px.svg";
import { ReactComponent as FavoriteOff } from "../assets/icons/favorite_inactive-24px.svg";

// REdux
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { savePost, unSavePost } from "../redux/actions/dataActions";

const styles = {
  navButton: {
    backgroundColor: "#fff",
    margin: "1vh",
  },
  icon: {
    width: 23.5,
    height: 23.5,
  },
};

export class SavePostButton extends Component {
  state = {
    favourite: false,
  };

  savedPost = () => {
    // console.log("Saved posts ====");
    // console.log(this.props.postId);
    //console.log("CHECK SAVED STATUS");
    if (
        this.props.user.favorites &&
        this.props.user.favorites.find(
            (post) => post.postId == this.props.postId
        )
    ) {
      return true;
    } else return false;
  };

  savePost = () => {
    this.state.favourite
        ? this.props.unSavePost(this.props.postId)
        : this.props.savePost(this.props.postId);
    // console.log(this.state.favourite);
    this.setState({ favourite: !this.state.favourite });
  };

  componentDidMount() {
    this.setState({ favourite: this.savedPost() });
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeFilter !== prevProps.activeFilter) {
      this.setState({ favourite: this.savedPost() });
    }
  }

  render() {
    const {
      classes,
      user: { authenticated },
      mooiCount,
    } = this.props;
    // console.log("Favorites now =====");
    // console.log(this.state.favourite);
    const favouriteButton = this.state.favourite ? (
        <SvgIcon component={FavoriteOn} className={classes.icon} />
    ) : (
        <SvgIcon component={FavoriteOff} className={classes.icon} />
    );
    return (
        <Tooltip title="save" className={classes.tooltip}>
          <IconButton onClick={this.savePost}>{favouriteButton}</IconButton>
        </Tooltip>
    );
  }
}

SavePostButton.propTypes = {
  user: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
  savePost: PropTypes.func.isRequired,
  unSavePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  savePost,
  unSavePost,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(SavePostButton));