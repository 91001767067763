import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as DitHelptOn } from "../assets/icons/DitHelptMij.svg";
import { ReactComponent as DitHelptOff } from "../assets/icons/DitHelptMij_inactive.svg";
import { ReactComponent as Helpful } from "../assets/icons/trending_up_black.svg";

// REdux
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { likePost, unlikePost } from "../redux/actions/dataActions";

const styles = {
  navButton: {
    backgroundColor: "#fff",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 30,
  },
  icon: {
    width: 20,
    height: 20,
    color: "grey",
  },
  iconOn: {
    width: 20,
    height: 20,
    color: "#16DFC3",
  },
};

export class DitHelpCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      count: 0,
    };
  }

  likedPost = () => {
    if (
      this.props.user.likes.find(
        (like) => like.postId === this.props.postId && like.type === "dithelp"
      )
    )
      return true;
    else return false;
  };

  componentDidMount() {
    this.setState({
      selected: this.likedPost(),
      count: this.props.dithelpCount,
    });
  }

  componentDidUpdate(prevProps) {
    //I chose to update over user favorites to avoid un update related to likes
    if (this.props.activeFilter !== prevProps.activeFilter) {
      this.setState({
        selected: this.likedPost(),
        count: this.props.dithelpCount,
      });
    }
  }

  likePost = () => {
    if (!this.state.selected) {
      let newlike = {
        postId: this.props.postId,
        type: "dithelp"
      }
      this.props.user.likes.push(newlike)
      this.setState({ selected: true, count: parseInt(this.state.count) + 1 });
      this.props.likePost(this.props.postId, "dithelp");
    } else {
      const idx = this.props.user.likes.findIndex(
          (like) => like.postId === this.props.postId && like.type === "dithelp"
      )
      this.props.user.likes.splice(idx, 1)
      this.setState({ selected: false, count: parseInt(this.state.count) - 1 });
      this.props.unlikePost(this.props.postId, "dithelp");
    }
  };

  render() {
    const {
      user: { authenticated },
      dithelpCount,
    } = this.props;
    const { selected, count } = this.state;
    const { classes } = this.props;
    const dithelpButton = (
      <Tooltip title="helpful" className={classes.tooltip}>
        {selected ? (
          <IconButton onClick={this.likePost}>
            <div className={classes.iconWrapper}>
              <SvgIcon component={Helpful} className={classes.iconOn} />
              <Typography>{count}</Typography>
            </div>
          </IconButton>
        ) : (
          <IconButton onClick={this.likePost}>
            <div className={classes.iconWrapper}>
              <SvgIcon component={Helpful} className={classes.icon} />
              <Typography>{count}</Typography>
            </div>
          </IconButton>
        )}
      </Tooltip>
    );
    return dithelpButton;
  }
}

DitHelpCount.propTypes = {
  user: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  likePost,
  unlikePost,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(DitHelpCount));
