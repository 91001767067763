import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "react-router-dom/Link";

import theme from "../util/theme";

import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";

const styles = (theme) => ({
  container:{
    height:"91vh",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  frame: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    display: "flex",
    justifyContent: "center",
    //marginTop: "10vh",
    borderRadius: "15px",
    overflow: "hidden",
  },
  loginBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "8vh",
    padding: "5vh",
    alignItems: "center",
    height: "62vh",
    width: "100%",
  },
  registrationBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "8vh",
    padding: "5vh",
    alignItems: "center",
    height: "62vh",
    backgroundColor: "#00A6D6",
  },
  visitorBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "31vh",
    backgroundColor: "#EFEFEF",
    padding: "5vh",
  },
  textForm: {
    margin: "2vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: "2vh",
  },
  loginButton: {
    position: "relative",
    alignItems: "center",
    marginTop: "3vh",
  },
  loginHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "10vh",
  },
  registrationHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "10vh",
    marginBottom: "3vh",
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
  },
  spinner: { position: "absolute" },
});

class login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData, this.props.history);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container className={classes.frame}>
          <Grid item sm={12} md={6} className={classes.loginBox}>
            <div className={classes.loginHeader}>
              <Typography variant="h4">Login</Typography>
              <Typography variant="subtitle2">
                Please log in to continue
              </Typography>
            </div>
            <form
              noValidate
              autoComplete="off"
              onSubmit={this.handleSubmit}
              className={classes.form}
            >
              <TextField
                id="email"
                name="email"
                label="email"
                type="email"
                value={this.state.email}
                helperText={errors.emailLogin}
                error={errors.emailLogin ? true : false}
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
                className={classes.textForm}
              />
              <TextField
                id="password"
                label="password"
                type="password"
                name="password"
                value={this.state.password}
                helperText={errors.password}
                error={errors.password ? true : false}
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
                className={classes.textForm}
              />
              {errors.general && (
                <Typography variant="body2" className={classes.customError}>
                  {errors.general}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                className={classes.loginButton}
              >
                LOG IN
                {loading && <CircularProgress className={classes.spinner} />}
              </Button>
            </form>
          </Grid>
          <Grid item sm className={classes.registrationBox}>
            <div className={classes.registrationHeader}>
              <Typography variant="h4" color="primary">
                Register
              </Typography>
              <Typography variant="subtitle2" color="primary">
                Don't have an account?
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to="/signup"
              className={classes.loginButton}
            >
              REGISTER
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(login));
/*
<Grid item sm className={classes.visitorBox}>
              <div className={classes.loginHeader}>
                <Typography variant="h4">Bezoeker</Typography>
                <Typography variant="subtitle2">
                  Alleen even rondkijken?
                </Typography>
              </div>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                component={Link}
                to="/"
                className={classes.loginButton}
              >
                overslaan
              </Button>
            </Grid>
*/
