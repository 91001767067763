import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {NavLink} from "react-router-dom";
import {handleDate} from "./functions/Date";
import Avatar from "@material-ui/core/Avatar";

import {deleteComment} from "../redux/actions/dataActions";
import {connect} from "react-redux";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as DeleteIcon} from "../assets/icons/delete-24px.svg";

const styles = {
    container: {
        display: "flex",
        flexDirection: "flex-start",
        marginRight: 5,
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
        marginRight: 15,
    },
    commentData: {
        width: "100%",
    },
    titleAndDate: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "baseline",
    },
    date: {
        marginLeft: 10,
    },

    deleteButton: {
        width: 24,
        height: 24,
    },
};

export class Comment extends Component {
    state = {
        deleteOpen: false,
        hovered: false,
    };

    //the problem is that deleteComment executes directly after creating a comment
    deleteComment = (comment) => {
        console.log("click deleting of comment " + comment.body);
        this.props.deleteComment(comment);
        this.setState({deleteOpen: false});
    };

    handleOpen = () => {
        this.setState({deleteOpen: true});
    };
    handleClose = () => {
        this.setState({deleteOpen: false});
    };

    handleMouseEnter = () => {
        this.setState({hovered: true});
    };

    handleMouseLeave = () => {
        this.setState({hovered: false});
    };

    render() {
        const {comment, classes, handle, authenticated, userAvatar, role} = this.props;

        // console.log("comment role " + role)
        // console.log("comment admin? " + (role === 'admin'))

        const date = handleDate(comment);

        return (
            <div
                key={comment.commentId}
                className={classes.container}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div>
                    {comment.userHandle === handle ? (
                        <Avatar
                            className={classes.avatar}
                            alt="Remy Sharp"
                            src={
                              comment.userAvatar
                                    ? require("../assets/svg/" + comment.userAvatar + ".svg")
                                    : null
                            }
                            component={NavLink}
                            to={`/myprofile`}
                        />
                    ) : (<Avatar
                            className={classes.avatar}
                            alt="Remy Sharp"
                            src={
                              comment.userAvatar
                                    ? require("../assets/svg/" + comment.userAvatar + ".svg")
                                    : null
                            }
                            component={NavLink}
                            to={`/profile/${comment.userHandle}`}
                        />
                    )}
                </div>
                <div className={classes.commentData}>
                    <div className={classes.titleAndDate}>
                        <Typography variant="h6">{<div>
                            {comment.userHandle === handle ? (
                                <NavLink
                                    to={`/myprofile`}
                                    style={{color: "inherit", textDecoration: "none"}}
                                >
                                    {comment.userHandle}
                                </NavLink>
                            ) : (
                                <NavLink
                                    to={`/profile/${comment.userHandle}`}
                                    style={{color: "inherit", textDecoration: "none"}}
                                >
                                    {comment.userHandle}
                                </NavLink>
                            )}
                        </div>}</Typography>
                        <Typography variant="caption" className={classes.date}>
                            {date}
                        </Typography>
                    </div>
                    <Typography variant="body1">{comment.body}</Typography>
                </div>
                <div>
                    {authenticated && (comment.userHandle === handle || role === 'admin') ? (
                        <div>
                            {this.state.hovered ? (
                                <Tooltip title="delete" className={classes.tooltip}>
                                    <IconButton onClick={this.handleOpen}>
                                        <SvgIcon component={DeleteIcon} color="secondary"/>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <div className={classes.deleteButton}/>
                            )}
                            <Dialog
                                open={this.state.deleteOpen}
                                onClose={this.handleClose}
                                fullWidth
                                maxWidth="sm"
                            >
                                <DialogTitle>
                                    Are you sure you want to delete this comment?
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.deleteComment(comment);
                                        }}
                                        color="secondary"
                                    >
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

/*
Comments.propTypes = {
  comment: PropTypes.obj.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});
*/

const mapActionsToProps = {
    deleteComment,
};

//const areStatesEqual = (next, prev) => false;

export default connect(null, mapActionsToProps)(withStyles(styles)(Comment));
