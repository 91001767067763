import React, {Component} from "react";
import Link from "react-router-dom/Link";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "../App.css";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import PersonIcon from "@material-ui/icons/Person";

import {logoutUser, editUserDetails, deleteUser} from "../redux/actions/userActions";
import ChangeAvatar from "./profile/ChangeAvatar.js";
import EditUserDetails from "./profile/EditUserDetails.js";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
//working here

const redTheme = createMuiTheme({palette: {primary: red}});

const styles = (theme) => ({
    avatarContainer: {
        position: "relative",
    },
    avatar: {
        width: 180,
        height: 180,
        margin: "auto",
    },
    buttonContainer: {
        backgroundColor: "#fff",
        borderRadius: "100%",
        position: "absolute",
        bottom: 0,
        right: 20,
        display: "flex",
        alignItem: "center",
        justifyContent: "flex-start",
        width: 45,
        height: 45,
    },
    changeAvatarButton: {
        //padding: 0,
    },
    card: {
        position: "relative",
        padding: theme.spacing(3),
        paddingLeft: "24px",
        paddingRight: "24px",
        backgroundColor: "#fafafa",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxHeight: "100%",
        width: "100%",
    },
    personalize: {
        display: "flex",
        flexDirection: "column",
        //justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0),
    },
    userDetail: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        marginBottom: "1vh",
    },
    settingsButton: {padding: 0},
    media: {
        height: 240,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    profileButtons: {
        //position: "absolute",
        bottom: theme.spacing(2),
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    filledButton: {
        margin: theme.spacing(2),
    },
    tooltip: {
        fontSize: 12,
    },
    grayBox: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        marginTop: 5,
        height: 30,
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        cursor: "pointer",
    },
    dialogText: {
        alignItems: "center",
        fontFamily: 'Roboto',
        marginBottom: "1vh",
        fontSize: 10,
    }
});

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        border: "none",
    },
}))(ToggleButtonGroup);

const dataArray = [
    "handle",
    "email",
    "name",
    "surname",
    //"dob",
    "specialisation",
    "institution",
    "experience",
    "hyperlink",
    "biography"
    //"phone",
];

class Profile extends Component {
    state = {
        changeAvatarOpen: false,
        editUserDetailsOpen: false,
        temporalAvatar: "amb",
        deleteAccount: false,
        ...this.props.credentials,
    };

    handleLogout = () => {
        this.props.logoutUser();
    };

    handleDeleteAccount = () => {
        this.props.deleteUser();
    };


    handleOpenChangeAvatar = () => {
        this.setState({changeAvatarOpen: true});
    };
    handleCloseChangeAvatar = () => {
        this.setState({changeAvatarOpen: false});
        this.setState({temporalAvatar: this.state.avatar});
    };

    handleOpenEditDetails = () => {
        this.setState({editUserDetailsOpen: true});
    };
    handleCloseEditDetails = () => {
        this.setState({editUserDetailsOpen: false});
    };

    handleAvatarChoice = (event, avatarChoice) => {
        avatarChoice !== null
            ? this.setState({temporalAvatar: avatarChoice})
            : this.setState({temporalAvatar: this.state.avatar});
    };

    handleSubmitChanges = (userDetails) => {
        let newUserData = {};

        this.setState({
            ...userDetails,
            loading: true,
            avatar: userDetails.temporalAvatar,
        });

        dataArray.forEach((item) =>
            userDetails[item] !== null
                ? (newUserData[item] = userDetails[item])
                : (newUserData[item] = "")
        );

        newUserData = {
            avatar: this.state.temporalAvatar,
            ...newUserData,
        };

        console.log("change user details to =");
        console.log(newUserData);

        this.props.editUserDetails(newUserData);
        this.setState({changeAvatarOpen: false});
        this.setState({editUserDetailsOpen: false});
    };

    handleOpen = () => {
        this.setState({deleteAccount: true});
    };

    handleClose = () => {
        this.setState({deleteAccount: false});
    };

    componentDidMount() {
        const {credentials} = this.props;
        this.setState({
            ...credentials,
            temporalAvatar: credentials.avatar,
        });
        console.log(this.state);
    }

    render() {
        dayjs.extend(relativeTime);

        const {classes, credentials} = this.props;

        const avatarRoute =
            credentials.avatar !== null
                ? "../assets/svg/" + credentials.avatar + ".svg"
                : "../assets/svg/amb.svg";

        let formatedAvatarArray = credentials.avatar.split(" ");
        let formatedAvatar = "";
        formatedAvatarArray.forEach((item) => {
            formatedAvatar = formatedAvatar + item;
        });

        let avatarString = "url(images/avatars/" + formatedAvatar + ".png)";

        /*
        const codedPassword =
          credentials.password != null
            ? credentials.password.split("").map((item) => <span>*</span>)
            : "123";

        let displayedData = dataArray.filter(
          (item) => item !== "surname" && item !== "handle"
        );

        let userDetails = Object.keys(this.state).map(
          (item) =>
            displayedData.includes(item) && (
              <div className={classes.userDetail} key={item}>
                <Typography variant="caption">{item}</Typography>
                <Typography variant="body2">
                  {item === "password"
                    ? codedPassword
                    : item === "name"
                    ? this.state[item] + " " + this.state.surname
                    : this.state[item]}
                </Typography>
              </div>
            )
        );
    */

        //icon button for editing details
        /*
    <Tooltip title="Persoonlijke gegevens" className={classes.tooltip}>
      <IconButton
        className={classes.settingsButton}
        variant="outlined"
        color="secondary"
        fontSize="large"
        onClick={this.handleOpenEditDetails}
      >
          <SettingsIcon />
      </IconButton>
    </Tooltip>
    */
        /*
    <div className={classes.userDetail}>
      <Typography variant="caption">Geboortedatum</Typography>
      <Typography variant="body1">{this.state.dob}</Typography>
    </div>
    */
        /*
        let emptyInfo = (
          <div className={classes.grayBox}>
            <IconButton
              className={classes.smallButton}
              variant="contained"
              color="secondary"
              fontSize="small"
              onClick={this.handleOpenEditDetails}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
    */
        let emptyInfo = (
            <div
                className={classes.grayBox}
                onClick={this.handleOpenEditDetails}
            ></div>
        );

        let userDetails = (
            <div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Name</Typography>
                    {this.state.name || this.state.surname !== "" ? (
                        <Typography variant="body1">
                            {this.state.name + " " + this.state.surname}
                        </Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Specialisation</Typography>
                    {this.state.specialisation !== "" ? (
                        <Typography variant="body1">{this.state.specialisation}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Years of experience</Typography>
                    {this.state.experience !== "" ? (
                        <Typography variant="body1">{this.state.experience}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Department</Typography>
                    {this.state.institution !== "" ? (
                        <Typography variant="body1">{this.state.institution}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Email</Typography>
                    {this.state.email !== "" ? (
                        <Typography variant="body1">{this.state.email}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Hyperlink</Typography>
                    {this.state.hyperlink !== "" ?
                        this.state.hyperlink !== null ? (
                            <Typography variant="body1">{this.state.hyperlink}</Typography>
                        ) : (
                            emptyInfo
                        ) : (
                            emptyInfo
                        )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Biography</Typography>
                    {this.state.biography !== "" ?
                        this.state.biography !== null ? (
                            <Typography variant="body1">{this.state.biography}</Typography>
                        ) : (
                            emptyInfo
                        ) : (
                            emptyInfo
                        )}
                </div>
            </div>
        );

        return (
            <div className={classes.card}>
                <div className={classes.avatarContainer}>
                    <Avatar
                        alt="Remy Sharp"
                        src={require("../assets/avatars/" +
                            this.state.temporalAvatar +
                            ".png")}
                        className={classes.avatar}
                    />
                    <Tooltip title="Change avatar" className={classes.tooltip}>
                        <div className={classes.buttonContainer}>
                            <IconButton
                                className={classes.changeAvatarButton}
                                variant="contained"
                                color="secondary"
                                fontSize="large"
                                onClick={this.handleOpenChangeAvatar}
                            >
                                <EditIcon/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
                <div className={classes.personalize}>
                    <Typography variant="h4">{credentials.handle}</Typography>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={this.handleOpenEditDetails}
                    >
                        My information
                    </Button>
                </div>

                <ChangeAvatar
                    open={this.state.changeAvatarOpen}
                    handleClose={this.handleCloseChangeAvatar}
                    profileState={this.state}
                    handleAvatarChoice={this.handleAvatarChoice}
                    handleSubmit={this.handleSubmitChanges}
                />
                {userDetails}
                <EditUserDetails
                    open={this.state.editUserDetailsOpen}
                    handleClose={this.handleCloseEditDetails}
                    profileState={this.state}
                    dataArray={dataArray}
                    handleSubmit={this.handleSubmitChanges}
                />
                <div className={classes.profileButtons}>
                    <Button
                        variant="contained"
                        startIcon={<PersonIcon color="secondary"/>}
                        className={classes.filledButton}
                        onClick={this.handleLogout}
                        component={Link}
                        to="/login"
                    >
                        Log out
                    </Button>
                    <MuiThemeProvider theme={redTheme}>
                        <Button
                            color="primary"
                            onClick={this.handleOpen}
                            // this.handleDeleteAccount();
                            // this.handleLogout();
                        >
                            Delete account
                        </Button>
                        <Dialog
                            open={this.state.deleteAccount}
                            onClose={this.handleClose}
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle
                                disableTypography="true"
                                className={{
                                    paper: classes.dialogText
                                }}
                            > This will delete all of your data from our servers,
                                including your profile, posts, comments, favourites and responses.
                                Delete your Hallway account? </DialogTitle>
                            <DialogActions>
                                <Button onClick={this.handleClose}>
                                    No
                                </Button>
                                <Button
                                    color="primary"
                                    //component={Link} to="/addNewPost"
                                    onClick={() => {
                                        this.handleDeleteAccount();
                                        this.handleLogout();
                                    }}
                                    component={Link}
                                    to="/login"
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </MuiThemeProvider>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    user: state.user,
});

const mapActionsToProps = {logoutUser, editUserDetails, deleteUser};

Profile.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    credentials: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired,
    editUserDetails: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(Profile));
