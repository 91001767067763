import React, { useState, Component } from "react";
import BannerTitle from "../components/BannerTitle.js";
import FaqQuestion from "../components/FaqQuestion.js";
import WhatIsMasksOff from "./faq-what-is-masksoff.js";
import HowWorksPost from "./faq-how-works-post.js";
import withStyles from "@material-ui/core/styles/withStyles";

import { Container } from "@material-ui/core";

const styles = {
  container: {
    display: "block",
    margin: " auto",
    maxWidth: " 1000px",
  },

  list: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "20px",
    overflow: "hidden",
    boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.5)",
  },
};

class FaqPage extends Component {
  state = {
    page: 0,
  };

  openPage = (page) => {
    console.log("open page = " + page);
    const openedPage = this.state.page !== page ? page : 0;
    this.setState({ page: openedPage });
  };

  render() {
    return (
      <Container maxWidth="lg">
        <div className={this.props.classes.container}>
          <BannerTitle
            url="images/faq-banner-img.png"
            title="FAQ"
            subTitle="Hier vind je een overzicht van veel gestelde vragen."
          />
          <div className={this.props.classes.list}>
            <FaqQuestion
              question="Wat is Behind the Mask?"
              answer={<WhatIsMasksOff />}
              onClick={() => this.openPage(2)}
              open={this.state.page === 2 ? true : false}
            />
            <FaqQuestion
              question="Hoe werken posts op Behind the Mask?"
              answer={<HowWorksPost />}
              onClick={() => this.openPage(3)}
              open={this.state.page === 3 ? true : false}
            />
            <FaqQuestion question="Wat vind je op Behind the Mask?" />
            <FaqQuestion question="Hoe zit het met privacy?" />
            <FaqQuestion question="Voor wie is Behind the Mask?" />
            <FaqQuestion question="Wie zit er achter Behind the Mask?" />
            <FaqQuestion question="Wat zijn buddy's?" />
            <FaqQuestion question="Waarvoor zijn emoji's?" />
            <FaqQuestion question="Wat is het verschil tussen een ervaring en een verhaal?" />
            <FaqQuestion question="Hoe stel ik een vraag aan andere verpleegkundigen?" />
            <FaqQuestion question="Hoe deel ik een ervaring?" />
            <FaqQuestion question="Hoe registreer ik een account?" />
          </div>
        </div>
      </Container>
    );
  }
}
/*
const element = <AboutDetail name="Sara" />;
const domContainer = document.getElementById('root');
ReactDOM.render(element, domContainer);
*/
export default withStyles(styles)(FaqPage);
