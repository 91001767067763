import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as HekenbaarOn } from "../assets/icons/Herkenbaar.svg";
import { ReactComponent as HekenbaarOff } from "../assets/icons/Herkenbaar_inactive.svg";
import { ReactComponent as inspiring } from "../assets/icons/lightbulb_black_24dp.svg";

// REdux
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { likePost, unlikePost } from "../redux/actions/dataActions";

const styles = {
  navButton: {
    backgroundColor: "#fff",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 30,
  },
  icon: {
    width: 20,
    height: 20,
  },
  iconOff: {
    width: 20,
    height: 20,
    color: "grey",
  },
  iconOn: {
    width: 20,
    height: 20,
    color: "orange",
  },
};

//this.props.user.likes &&
export class HerkenbaarButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      count: 0,
    };
  }

  likedPost = () => {
    if (
        this.props.user.likes.find(
            (like) =>
                like.postId === this.props.postId && like.type === "herkenbaar"
        )
    )
      return true;
    else return false;
  };

  componentDidMount() {
    this.setState({
      selected: this.likedPost(),
      count: this.props.herkenbaarCount,
    });
  }

  componentDidUpdate(prevProps) {
    //I chose to update over user favorites to avoid un update related to likes
    if (this.props.activeFilter !== prevProps.activeFilter) {
      this.setState({
        selected: this.likedPost(),
        count: this.props.herkenbaarCount,
      });
    }
  }

  likePost = () => {
    if (!this.state.selected) {
      let newlike = {
        postId: this.props.postId,
        type: "herkenbaar"
      }
      this.props.user.likes.push(newlike)
      this.setState({ selected: true, count: parseInt(this.state.count) + 1 });
      this.props.likePost(this.props.postId, "herkenbaar");
    } else {
      const idx = this.props.user.likes.findIndex(
          (like) => like.postId === this.props.postId && like.type === "herkenbaar"
      )
      this.props.user.likes.splice(idx, 1)
      this.setState({ selected: false, count: parseInt(this.state.count) - 1 });
      this.props.unlikePost(this.props.postId, "herkenbaar");
    }
  };

  render() {
    const {
      user: { authenticated },
      herkenbaarCount,
    } = this.props;
    const { selected, count } = this.state;
    const { classes } = this.props;
    const herkenbaarButton = (
        <Tooltip title="inspiring" className={classes.tooltip}>
          {selected ? (
              <IconButton onClick={this.likePost}>
                <div className={classes.iconWrapper}>
                  <SvgIcon component={inspiring} className={classes.iconOn} />
                  <Typography>{count}</Typography>
                </div>
              </IconButton>
          ) : (
              <IconButton onClick={this.likePost}>
                <div className={classes.iconWrapper}>
                  <SvgIcon component={inspiring} className={classes.iconOff} />
                  <Typography>{count}</Typography>
                </div>
              </IconButton>
          )}
        </Tooltip>
    );
    return herkenbaarButton;
  }
}

HerkenbaarButton.propTypes = {
  user: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  likePost,
  unlikePost,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(HerkenbaarButton));
