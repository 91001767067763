import {
    COUNT_USERS,
    SET_USER,
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    SET_EMAIL,
} from '../types';
import axios from 'axios';

/**
 * Count the amount users
 * @returns Return nothing but dispatch actions based on api call
 */
export const countUsers = () => (dispatch) => {
    dispatch({ type: LOADING_USER });
    axios
        .get('/new/Users')
        // .get("/users")
        .then((res) => {
            console.log('Amount of users ' + res.data.users.length);
            dispatch({ type: COUNT_USERS, payload: res.data.users.length });
            console.log(res.data);
        })
        .catch((err) => console.log(err));
};

/**
 * User logs in with userData. Dispatch based on the response.
 * @param {UserData consist of email and password} userData
 * @param {*} history
 * @returns Nothing
 */
export const loginUser = (userData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post('api/auth/signin', userData)
        .then((res) => {
            setAuthHeader(res.data.accessToken);
            dispatch(getUserData());
            dispatch({ type: CLEAR_ERRORS });
            history.push('/feed');
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: SET_ERRORS, payload: err.response.data });
        });
};

/**
 * Tell the server to send the confirmation email again.
 * @param {String} userEmail The user email for the confirmation link to be sent.
 * @returns Return nothing but dispatch action based on the post result.
 */
export const resendEmail = (userEmail) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post('resend', { userEmail })
        .then(res => {
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(error => {
            console.log(error);
        })
}

/**
 * Get the user data associated to the json token
 * @returns Return nothing but dispatch actions based on the get result
 */
export const getUserData = () => (dispatch) => {
    dispatch({ type: LOADING_USER });
    axios
        .get('/user')
        .then((res) => {
            console.log('getUserData');
            // console.log(res);
            dispatch({ type: SET_USER, payload: res.data });
            console.log(res.data);
        })
        .catch((err) => console.log(err));
};

export const signUpUser = (newUserData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post('api/auth/signup', newUserData)
        .then(() => {
            dispatch({ type: SET_EMAIL, payload: newUserData.email });
            dispatch({ type: CLEAR_ERRORS });
            history.push('/verify');
        })
        .catch((error) => {
            dispatch({ type: SET_ERRORS, payload: error.response.data });
        })
}

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('HallwayToken');
    delete axios.defaults.headers.common['x-access-token'];
    dispatch({ type: SET_UNAUTHENTICATED });
};

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });

    console.log('editUserDetails is triggered');
    console.log(userDetails);
    axios
        .post('/user', userDetails)
        .then(() => {
            dispatch(getUserData());
        })
        .catch((err) => console.log(err));
};

/**
 * Function for deleting the user based on post id
 * @param postId The id of the user
 * @returns {(function(*): void)|*} Return nothing but dispatch action based on delete response
 */
export const deleteUser = () => (dispatch) => {
    console.log('HTTP request => deleteUser()');
    axios
        .delete('/user')
        // .then(() => {
        //     dispatch(deleteAllPostOfUser());
        // })
        .catch((err) => console.log(err));
};

export const setAuthHeader = (token) => {

  //MySQL auth
  const HallwayToken = token;
  localStorage.setItem("HallwayToken", HallwayToken);
  axios.defaults.headers.common["x-access-token"] = HallwayToken;

};
