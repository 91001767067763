import React, { Component } from "react";
import Link from "react-router-dom/Link";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
//import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SvgIcon from "@material-ui/core/SvgIcon";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
//import logoSVG from "../assets/icons/logo.svg";
//import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

import { ReactComponent as Logo } from "../assets/icons/Hallway Logo.svg";

import { logoutUser } from "../redux/actions/userActions";

import withStyles from "@material-ui/core/styles/withStyles";
import { sizing } from "@material-ui/system/sizing";

const styles = {
  navButton: {
    backgroundColor: "#fff",
    margin: "1vh",
  },
  navButton_withText: {
    backgroundColor: "#fff",
    margin: "0.5vh",
  },
  navButton_disabled: {
    backgroundColor: "#fff",
    margin: "-0.5vh",
  },
  drawer: {
    width: "50vh",
    marginTop: 64,
  },
  drawerContent: {
    backgroundColor: "#EFEFEF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    width: 80,
  },
  logoButton: {
    textAlign: "center",
    width: 60,
    height: 40,
    marginRight: "auto",
    marginLeft: 6,
    marginTop: "0",
  },
  logo: {
    width: 75,
    height: 75,
    // marginLeft: "2.5vh",
  },
  leftNav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  burgerIcons: {
    //width: 30,
    //height: 30,
    display: "flex",
    flexDirection: "column",
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10,
  },
  drawerModal: {
    zIndex: 0,
  },
};

class Navbar extends Component {
  state = {
    sideBar: false,
  };

  toggleSideBar = () => {
    this.setState({ sideBar: !this.state.sideBar });
    //console.log(this.state.sideBar);
  };

  handleLogout = () => {
    this.toggleSideBar();
    this.props.logoutUser();
  };

  render() {
    const { authenticated } = this.props;
    const { classes } = this.props;
    return (
      <AppBar>
        <Toolbar className="nav-container">
          <div className={classes.leftNav}>
            <IconButton
              variant="outlined"
              color="secondary"
              onClick={this.toggleSideBar}
              className={classes.navButton}
            >
              <MenuIcon />
            </IconButton>

            <IconButton
              component={Link}
              to={authenticated ? "/feed" : "/login"}
              className={classes.logoButton}
            >
              <SvgIcon className={classes.logo}>
                <Logo />
              </SvgIcon>
            </IconButton>
          </div>
          {authenticated ? (
            <div>
              <IconButton
                variant="outlined"
                color="secondary"
                fontSize="large"
                className={classes.navButton}
                disabled={true}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                variant="outlined"
                color="secondary"
                fontSize="large"
                className={classes.navButton}
                disabled={true}
              >
                <NotificationsNoneIcon />
              </IconButton>
              <IconButton
                variant="outlined"
                color="secondary"
                fontSize="large"
                className={classes.navButton}
                component={Link}
                to="/myprofile"
              >
                <AccountCircleIcon />
              </IconButton>
            </div>
          ) : (
            <Button
              variant="contained"
              endIcon={<PersonIcon color="secondary" />}
              className={classes.navButton}
              component={Link}
              to="/login"
            >
              Login
            </Button>
          )}
          <Drawer
            className={classes.drawer}
            anchor="left"
            open={this.state.sideBar}
            onClose={this.toggleSideBar.bind(this)}
            elevation={4}
            classes={{
              modal: classes.drawerModal,
            }}
            /*
            ModalProps={{
              hideBackdrop: { BackdropProps: { invisible: true } },
            }}
            */
            //variant="persistent"
          >
            <div className={classes.drawerContent}>
              {authenticated ? (
                <div className={classes.iconButtonLabel}>
                  <IconButton
                    variant="outlined"
                    color="secondary"
                    fontSize="large"
                    className={classes.navButton_withText}
                    onClick={this.toggleSideBar.bind(this)}
                    component={Link}
                    to="/feed"
                  >
                    <DynamicFeedIcon className={classes.burgerIcons} />
                  </IconButton>
                  <Typography variant="caption" color="black">
                    feed
                  </Typography>
                </div>
              ) : (
                <div />
              )}
              <div className={classes.iconButtonLabel}>
                <IconButton
                  onClick={this.toggleSideBar.bind(this)}
                  variant="outlined"
                  color="secondary"
                  fontSize="large"
                  className={classes.navButton_disabled}
                  component={Link}
                  to="/about"
                  label="More"
                  disabled={true}
                >
                  <GroupIcon className={classes.burgerIcons} />
                </IconButton>
                <Typography variant="caption" color="black">
                  about us
                </Typography>
              </div>
              <div className={classes.iconButtonLabel}>
                <IconButton
                  onClick={this.toggleSideBar.bind(this)}
                  variant="outlined"
                  color="secondary"
                  fontSize="large"
                  className={classes.navButton_disabled}
                  component={Link}
                  to="/hulplijnen"
                  disabled={true}
                >
                  <ContactSupportIcon className={classes.burgerIcons} />
                </IconButton>
                <Typography variant="caption" color="black">
                  links
                </Typography>
              </div>
              <div className={classes.iconButtonLabel}>
                <IconButton
                  onClick={this.toggleSideBar.bind(this)}
                  variant="outlined"
                  color="secondary"
                  fontSize="large"
                  className={classes.navButton_disabled}
                  component={Link}
                  to="/faq"
                  disabled={true}
                >
                  <HelpOutlineIcon className={classes.burgerIcons} />
                </IconButton>
                <Typography variant="caption" color="black">
                  FAQ
                </Typography>
              </div>

              <div className={classes.iconButtonLabel}>
                <IconButton
                  onClick={this.toggleSideBar.bind(this)}
                  variant="outlined"
                  color="secondary"
                  fontSize="large"
                  className={classes.navButton_disabled}
                  component={Link}
                  to="/contact"
                  disabled={true}
                >
                  <MailOutlineIcon className={classes.burgerIcons} />
                </IconButton>
                <Typography variant="caption" color="black">
                  contact
                </Typography>
              </div>
              {authenticated ? (
                <div className={classes.iconButtonLabel}>
                  <IconButton
                    variant="outlined"
                    color="secondary"
                    fontSize="large"
                    className={classes.navButton_withText}
                    onClick={this.handleLogout}
                    component={Link}
                    to="/login"
                  >
                    <ExitToAppIcon className={classes.burgerIcons} />
                  </IconButton>
                  <Typography variant="caption" color="black">
                    log out
                  </Typography>
                </div>
              ) : (
                <div />
              )}
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
});

Navbar.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapActionsToProps = { logoutUser };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(Navbar));
