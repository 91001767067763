import React, {Component, Fragment} from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardMedia from "@material-ui/core/CardMedia";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Link from "react-router-dom/Link";
import Fab from "@material-ui/core/Fab";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as GoBack } from "../assets/icons/arrow_back-24px.svg";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

import theme from "../util/theme";

import BannerTitle from "../components/BannerTitle.js";

import {
  uploadImage,
  pushNewPost,
  pushNewPostDefaultImage,
} from "../redux/actions/dataActions";

import Image from "../assets/misc/query.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  root: {
    display: "block",
    height: "90.2vh",
    //overflow: "hidden",
  },
  goBackButton: {
    // position: "fixed",
    // top: "13vh",
    // right: "3vw",
    // boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.3)",
    marginTop: "2.5vh",
    marginBottom: "1.5vh",
    marginRight: "1vh",
    alignSelf: "flex-end",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: " auto",
    maxWidth: " 1000px",
    height: "100%",
  },
  paperContainer: {
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "25vh",
    width: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "2vh",
  },
  paperText: {
    margin: "1.5vh",
    color: "white",
  },
  postTypeToggleContainer: {
    margin: "0vh",
    alignSelf: "center",
  },
  contentTypeToggleContainer: {
    margin: "1vh",
    alignSelf: "center",
    flexWrap: "wrap",
  },
  postTypeToggleButton: {
    backgoundColor: "red",
  },
  postFrame: {
    width: "100%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2vh",
    marginBottom: "0vh",
    backgroundColor: "#EFEFEF",
    borderRadius: "15px",
  },
  textForm: {
    marginTop: "2vh",
    backgroundColor: "white",
  },
  formText: {
    marginTop: "2vh",
  },
  uploadButton: {
    position: "relative",
    alignItems: "center",
  },
  uploadIcon: {
    alignSelf: "center",
    width: 40,
    height: 40,
    margin: "auto",
  },
  spinner: { position: "absolute" },
  imageSelected: {
    marginTop: "2vh",
    minHeight: "34.2vh",
    minWidth: "50vh",
    backgroundColor: "#999999",
  },
  leftColumn: {
    marginTop: "2vh",
    marginRight: "2vh",
    marginLeft: "2vh",
    marginBottom: "2vh",
    height: "auto",
    minWidth: "45%",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      minWidth: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "90%",
    },
  },
  rightColumn: {
    //display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  postButton: {
    marginTop: "2.5vh",
    marginBottom: "1.5vh",
    alignSelf: "flex-end",
  },
  form: {
    marginBottom: "2vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  avatarContainer: {
    position: "relative",
    cursor: "pointer",
  },
  buttonContainer: {
    backgroundColor: "#fff",
    borderRadius: "100%",
    position: "absolute",
    bottom: -15,
    right: -10,

    display: "flex",
    alignItem: "center",
    justifyContent: "flex-start",
    width: 45,
    height: 45,
  },
});

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    border: "none",
  },
}))(ToggleButtonGroup);

export class addNewPost extends Component {
  constructor() {
    super();
    this.state = {
      vraag: false,
      speelt: "",
      hoofdpersoon: "",
      hoofdactie: "",
      gevoel: "",
      title: "",
      body: "",
      imageURL: "",
      file: "",
      image: "",
      discardPost: false,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ body: "", title: "" });
    }
  }

  handlePostType = (event) => {
    this.setState({ vraag: this.state.vraag ? false : true });
  };

  handleSpeeltType = (event, speeltType) => {
    this.setState({ speelt: speeltType });
  };
  handleHoofdpersoonType = (event, hoofdpersoonType) => {
    this.setState({ hoofdpersoon: hoofdpersoonType });
  };

  handleHoofdactieType = (event, hoofdactieType) => {
    this.setState({ hoofdactie: hoofdactieType });
  };
  handleGevoelType = (event, gevoelType) => {
    this.setState({ gevoel: gevoelType });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleImageUpload = (event) => {
    const formData = new FormData();
    const image = this.state.image;
    formData.append("image", image, image.name);
    this.props.uploadImage(formData);
    console.log(this.props.UI);
    this.setState({ imageURL: this.props.UI.imageURL });
  };
  handleImageSelect = (event) => {
    const image = URL.createObjectURL(event.target.files[0]);
    const formData = event.target.files[0];
    this.setState({ file: image, image: formData });
    console.log(this.state.file);
  };
  handleEditPicture = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const image = this.state.image;
    if (this.state.image === "") {
      if (this.state.vraag) {
        this.state.imageURL =
          "https://firebasestorage.googleapis.com/v0/b/masksoff-304c4.appspot.com/o/question.png?alt=media&token=ea577cfa-ffc3-4b5e-a58a-d3045f316193";
      } else {
        this.state.imageURL =
          "https://firebasestorage.googleapis.com/v0/b/masksoff-304c4.appspot.com/o/story.png?alt=media&token=af442a12-bbd0-4233-8473-6b09fb9bd76d";
      }
      //formData.append("image", image, "default choice");

      this.props.pushNewPostDefaultImage(
        this.state.imageURL,
        {
          body: this.state.body,
          title: this.state.title,
          isQuestion: this.state.vraag,
        },
        this.props.history
      );
    } else {
      console.log("IMAGE DATA: ", image.name, image);
      formData.append("image", image, image.name);
      this.props.pushNewPost(
        formData,
        {
          body: this.state.body,
          title: this.state.title,
          isQuestion: this.state.vraag,
        },
        this.props.history
      );
    }
    console.log("NEW IMAGE");
    console.log(this.state.imageURL);
    console.log(this.props.UI.imageURL);
    console.log(this.props.UI);
  };

  handleOpen = () => {
    console.log(this.setState);
    this.setState({ discardPost: true });
  };
  handleClose = () => {
    this.setState({ discardPost: false });
    console.log(this.setState);
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;

    const { errors, file } = this.state;
    return (
      <Container maxWidth="lg" className={classes.root}>
        {/*<Tooltip title="Go back">*/}
        {/*  <Fab*/}
        {/*    color="primary"*/}
        {/*    aria-label="add"*/}
        {/*    className={classes.goBackButton}*/}
        {/*    component={Link}*/}
        {/*    to="/feed"*/}
        {/*  >*/}
        {/*    <SvgIcon component={GoBack} className={classes.icon} />*/}
        {/*  </Fab>*/}
        {/*</Tooltip>*/}
        <div className={classes.container}>
          {/*<BannerTitle*/}
          {/*  url={Image}*/}
          {/*  title="Een bericht insturen"*/}
          {/*  subTitle="Hier kan je een ervaring delen of een vraag stellen aan anderen"*/}
          {/*/>*/}
          <ToggleButtonGroup
            value={this.state.vraag}
            exclusive
            onChange={this.handlePostType}
            className={classes.postTypeToggleContainer}
            aria-label="post type selection"
          >
            <ToggleButton value={false} aria-label="ervaring">
              Share
            </ToggleButton>
            <ToggleButton value={true} aria-label="vraag">
              Ask
            </ToggleButton>
          </ToggleButtonGroup>

            <Grid container spacing={4} className={classes.postFrame}>
              <Grid className={classes.leftColumn}>
                <Typography variant="subtitle2" className={classes.formText}>
                  Upload an Image (optional)
                </Typography>
                <div className={classes.avatarContainer}>
                  <CardMedia
                      onClick={this.handleEditPicture}
                      className={classes.imageSelected}
                      image={
                        file === ""
                            ? this.state.vraag
                            ? require("../assets/misc/question.png")
                            : require("../assets/misc/story.png")
                            : file
                      }
                  />
                  <Tooltip title="upload an image">
                    <div className={classes.buttonContainer}>
                      <IconButton
                          variant="contained"
                          color="secondary"
                          fontSize="large"
                          onClick={this.handleEditPicture}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              </Grid>
              <Grid className={classes.rightColumn}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    className={classes.form}
                >
                  {this.state.vraag ? (
                      <Typography variant="subtitle2" className={classes.formText}>
                        Title
                      </Typography>
                  ) : (
                      <Typography variant="subtitle2" className={classes.formText}>
                        Title
                      </Typography>
                  )}
                  <TextField
                      id="title"
                      name="title"
                      label="title"
                      type="title"
                      color="secondary"
                      placeholder="Add a title"
                      value={this.state.title}
                      helperText={errors.title}
                      error={errors.title ? true : false}
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth
                      className={classes.textForm}
                  />
                  {this.state.vraag ? (
                      <Typography variant="subtitle2" className={classes.formText}>
                        Your question
                      </Typography>
                  ) : (
                      <Typography variant="subtitle2" className={classes.formText}>
                        Your post
                      </Typography>
                  )}
                  <TextField
                      id="body"
                      label={this.state.vraag ? "write your post" : "write your post"}
                      type="body"
                      name="body"
                      color="secondary"
                      placeholder={
                        this.state.vraag
                            ? "Type your question"
                            : "Type your experience"
                      }
                      value={this.state.body}
                      multiline
                      rows={7}
                      helperText={errors.body}
                      error={errors.body ? true : false}
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth
                      className={classes.textForm}
                  />
                  <input
                      type="file"
                      id="imageInput"
                      hidden="hidden"
                      onChange={this.handleImageSelect}
                  />
                  <Grid
                      container
                      justify="flex-end"
                      alignItems="flex-end"
                  >
                    <fragment>
                      <Button
                          type="button"
                          variant="contained"
                          color="warning"
                          size="large"
                          className={classes.goBackButton}
                          onClick={this.handleOpen}
                          // component={Link}
                          // to="/feed"
                      >
                        Discard post
                        {loading && <CircularProgress className={classes.spinner} />}
                      </Button>
                      <Dialog
                          open={this.state.discardPost}
                          onClose={this.handleClose}
                          fullWidth
                          maxWidth="sm"
                      >
                        <DialogTitle> Are you sure you want to discard this post? </DialogTitle>
                        <DialogActions>
                          <Button onClick={this.handleClose} color="primary">
                            Cancel
                          </Button>
                          <Button
                              component={Link} to="/feed"
                              color="secondary">
                            Discard
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </fragment>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        className={classes.postButton}
                    >
                      Create post
                      {loading && <CircularProgress className={classes.spinner} />}
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </Container>
    );
  }
}

const mapActionsToProps = {
  uploadImage,
  pushNewPost,
  pushNewPostDefaultImage,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

addNewPost.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  pushNewPost: PropTypes.func.isRequired,
  pushNewPostDefaultImage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(addNewPost));

/*{this.state.vraag ? (
              <Typography />
            ) : (
              <Grid item sm={6} className={classes.form}>
                <Typography variant="subtitle2" className={classes.formText}>
                  Waar speelt jouw ervaring zich af?
                </Typography>
                <StyledToggleButtonGroup
                  value={this.state.speelt}
                  exclusive
                  size="small"
                  onChange={this.handleSpeeltType}
                  className={classes.contentTypeToggleContainer}
                  aria-label="speelt type selection"
                >
                  <ToggleButton
                    value={"intensive care afdeling"}
                    aria-label="intensive care afdeling"
                  >
                    intensive care afdeling
                  </ToggleButton>
                  <ToggleButton
                    value={"longafdeling"}
                    aria-label="longafdeling"
                  >
                    longafdeling
                  </ToggleButton>
                  <ToggleButton
                    value={"verzorgingstehuis"}
                    aria-label="verzorgingstehuis"
                  >
                    verzorgingstehuis
                  </ToggleButton>
                  <ToggleButton value={"in de auto"} aria-label="in de auto">
                    in de auto
                  </ToggleButton>
                  <ToggleButton value={"thuis"} aria-label="thuis">
                    thuis
                  </ToggleButton>
                </StyledToggleButtonGroup>
                <Typography variant="subtitle2" className={classes.formText}>
                  Wie is de hoofdpersoon?
                </Typography>
                <StyledToggleButtonGroup
                  value={this.state.hoofdpersoon}
                  exclusive
                  size="small"
                  onChange={this.handleHoofdpersoonType}
                  className={classes.contentTypeToggleContainer}
                  aria-label="hoofdpersoon type selection"
                >
                  <ToggleButton
                    value={"verpleegkundige"}
                    aria-label="verpleegkundige"
                  >
                    verpleegkundige
                  </ToggleButton>
                  <ToggleButton value={"ouder"} aria-label="ouder">
                    ouder
                  </ToggleButton>
                  <ToggleButton value={"partner"} aria-label="partner">
                    partner
                  </ToggleButton>
                </StyledToggleButtonGroup>
                <Typography variant="subtitle2" className={classes.formText}>
                  Wat is de hoofdactie?
                </Typography>
                <StyledToggleButtonGroup
                  value={this.state.hoofdactie}
                  exclusive
                  size="small"
                  onChange={this.handleHoofdactieType}
                  className={classes.contentTypeToggleContainer}
                  aria-label="hoofdactie type selection"
                >
                  <ToggleButton value={"voelen"} aria-label="voelen">
                    voelen
                  </ToggleButton>
                  <ToggleButton value={"denken"} aria-label="denken">
                    denken
                  </ToggleButton>
                  <ToggleButton value={"zeggen"} aria-label="zeggen">
                    zeggen
                  </ToggleButton>
                  <ToggleButton value={"doen"} aria-label="doen">
                    doen
                  </ToggleButton>
                </StyledToggleButtonGroup>
                <Typography variant="subtitle2" className={classes.formText}>
                  Wat was het gevoel?
                </Typography>
                <StyledToggleButtonGroup
                  value={this.state.gevoel}
                  exclusive
                  size="small"
                  onChange={this.handleGevoelType}
                  className={classes.contentTypeToggleContainer}
                  aria-label="gevoel type selection"
                >
                  <ToggleButton value={"positief"} aria-label="positief">
                    positief
                  </ToggleButton>
                  <ToggleButton value={"negatief"} aria-label="negatief">
                    negatief
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Grid>

              <Container className={classes.buttonContainer}>
              <IconButton
                className={classes.uploadButton}
                color="secondary"
                onClick={this.handleEditPicture}
              >
                <CloudUploadIcon className={classes.uploadIcon} />
              </IconButton>
            </Container>
            )} */