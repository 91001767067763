import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

export const styles = {
  bannerSection: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  bannerContainer: {
    backgroundColor: "#cccccc" /* Used if the image is unavailable */,
    minHeight: "18vh",
    /* maxHeight: "25vh" /* You must set a specified height ,*/
    width: "100%",
    backgroundPosition: "left center" /* Center the image */,
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize:
      "cover" /* Resize the background image to cover the entire container */,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    padding: "40px",
    paddingTop: "10px",
    marginBottom: "2vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    textShadow: "1px 2px 3px rgba(0,0,0, 0.5)",
    color: "white",
  },
  subTitle: {
    textShadow: "1px 2px 3px rgba(0,0,0, 0.5)",
    color: "white",
  },
};

class BannerTitle extends Component {
  render() {
    const { classes, url, title, subTitle, backgroundPosition } = this.props;
    return (
      <div className={classes.bannerSection}>
        <div
          className={classes.bannerContainer}
          style={{
            backgroundImage: `url(${url})`,
            backgroundPosition: `${backgroundPosition}`,
          }}
        >
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="subtitle2" className={classes.subTitle}>
            {subTitle}
          </Typography>
        </div>
      </div>
    );
  }
}

BannerTitle.defaultProps = {
  url: "images/faq-banner-img.png",
  title: "Page title",
  subTitle: "Subtitle",
  backgroundPosition: "left center",
};

BannerTitle.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backgroundPosition: PropTypes.string,
};

/*withStyles(styles, [options]) => higher-order component
-----------------------------------------------------------------------------
Link a style sheet with a component using the higher-order component pattern.
It does not modify the component passed to it; instead, it returns a new
component with a classes property. This classes object contains the name of the
class names injected in the DOM.*/
export default withStyles(styles)(BannerTitle);
