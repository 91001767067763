import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "../App.css";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import {logoutUser, editUserDetails} from "../redux/actions/userActions";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";

const styles = (theme) => ({
    avatarContainer: {
        position: "relative",
    },
    avatar: {
        width: 180,
        height: 180,
        margin: "auto",
    },
    buttonContainer: {
        backgroundColor: "#fff",
        borderRadius: "100%",
        position: "absolute",
        bottom: 0,
        right: 20,
        display: "flex",
        alignItem: "center",
        justifyContent: "flex-start",
        width: 45,
        height: 45,
    },
    changeAvatarButton: {
        //padding: 0,
    },
    card: {
        position: "relative",
        padding: theme.spacing(3),
        paddingLeft: "24px",
        paddingRight: "24px",
        backgroundColor: "#fafafa",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxHeight: "100%",
        width: "100%",
    },
    personalize: {
        display: "flex",
        flexDirection: "column",
        //justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0),
    },
    userDetail: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        marginBottom: "1vh",
    },
    settingsButton: {padding: 0},
    media: {
        height: 240,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    exitButtons: {
        //position: "absolute",
        bottom: theme.spacing(2),
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    filledButton: {
        margin: theme.spacing(2),
    },
    tooltip: {
        fontSize: 12,
    },
    grayBox: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        marginTop: 5,
        height: 30,
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        cursor: "pointer",
    },
});

class StaticProfile extends Component {
    state = {
        changeAvatarOpen: false,
        editUserDetailsOpen: false,
        temporalAvatar: "amb",
        ...this.props.credentials,
    };

    handleGoToTUProfile = () => {
        window.open(this.state.hyperlink);
    };

    componentDidMount() {
        const {credentials} = this.props;
        this.setState({
            ...credentials,
            temporalAvatar: credentials.avatar,
        });
        console.log(this.state);
    }

    render() {
        dayjs.extend(relativeTime);

        const {classes, credentials} = this.props;

        const avatarRoute =
            credentials.avatar !== null
                ? "../assets/svg/" + credentials.avatar + ".svg"
                : "../assets/svg/amb.svg";

        let formatedAvatarArray = credentials.avatar.split(" ");
        let formatedAvatar = "";
        formatedAvatarArray.forEach((item) => {
            formatedAvatar = formatedAvatar + item;
        });

        let avatarString = "url(images/avatars/" + formatedAvatar + ".png)";

        let emptyInfo = (
            <div
                className={classes.grayBox}
            />
        );

        let userDetails = (
            <div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Name</Typography>
                    {this.state.name || this.state.surname !== "" ? (
                        <Typography variant="body1">
                            {this.state.name + " " + this.state.surname}
                        </Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Specialisation</Typography>
                    {this.state.specialisation !== "" ? (
                        <Typography variant="body1">{this.state.specialisation}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Years of experience</Typography>
                    {this.state.experience !== "" || this.state.experience !== null ? (
                        <Typography variant="body1">{this.state.experience}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Department</Typography>
                    {this.state.institution !== "" ? (
                        <Typography variant="body1">{this.state.institution}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Email</Typography>
                    {this.state.email !== "" ? (
                        <Typography variant="body1">{this.state.email}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
                <div className={classes.userDetail}>
                    <Typography variant="caption">Biography</Typography>
                    {this.state.biography !== "" || this.state.biography !== null ? (
                        <Typography variant="body1">{this.state.biography}</Typography>
                    ) : (
                        emptyInfo
                    )}
                </div>
            </div>
        );

        return (
            <div className={classes.card}>
                <div className={classes.avatarContainer}>
                    <Avatar
                        alt="Remy Sharp"
                        src={require("../assets/avatars/" +
                            this.state.temporalAvatar +
                            ".png")}
                        className={classes.avatar}
                    />
                </div>

                <div className={classes.personalize}>
                    <Typography variant="h4">{credentials.handle}</Typography>
                </div>

                {userDetails}

                <div className={classes.exitButtons}>
                    <Button
                        variant="contained"
                        startIcon={<PersonIcon color="secondary"/>}
                        className={classes.filledButton}
                        onClick={this.handleGoToTUProfile}
                    >
                        Go to {this.state.handle}'s profile
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    user: state.user,
});

const mapActionsToProps = {logoutUser, editUserDetails};

StaticProfile.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    credentials: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired,
    editUserDetails: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(StaticProfile));
