import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "react-router-dom/Link";

const styles = {
  homeSection: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor:"green"
  },

  leftBox: {
    // backgroundColor: "blue",
    justifyContent: "center",
    width: "500px",
    height: "450px",
    paddingTop: "100px",
    paddingleft: "10px",
    paddingRight: "10px",
    color: "black",
    margin: "70px 0 0 0",
    boxSizing: "border-box",
  },

  rightBox: {
    // backgroundColor: "green",
    width: "650px",
    height: "450px",
    fontSize: "30vw",
    color: "blue",
    boxSizing: "border-box",
  },

  imageWrapper: {
    // border:"2px solid gray",
    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //background: "green",
  },

  image: {
    width: "130%",
    objectFit: "cover",
  },

  title: {
    marginBottom: "10px",
  },

  summary: {
    fontSize: "18px",
    marginBottom: "20px",
  },
};

class HomeSection extends Component {
  render() {
    const {
      classes,
      title,
      text,
      buttonText,
      buttonLink,
      ButtonColor,
      direction,
      imageUrl,
      position,
    } = this.props;

    //const padding = position === "right" ? "100px" : "-100px";
    const padding = {
      paddingLeft: position === "right" ? "50px" : "0px",
      paddingTop: "50px",
      paddingRight: position === "right" ? "0px" : "100px",
    };
    //paddingRight: `${padding}`,
    return (
      <div dir={direction} className={classes.homeSection}>
        <div dir="ltr" className={classes.leftBox}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.summary}>
            <i>{text}</i>
          </Typography>
          <Button
            type="link"
            variant="contained"
            color={ButtonColor}
            size="medium"
            component={Link}
            to={buttonLink}
          >
            {buttonText}
          </Button>
        </div>
        <div className={classes.rightBox}>
          <div className={classes.imageWrapper} style={padding}>
            <img src={imageUrl} className={classes.image} alt="Logo" />
          </div>
        </div>
      </div>
    );
  }
}

HomeSection.defaultProps = {
  buttonLink: "/",
  buttonText: "Lees meer",
  ButtonColor: "secondary",
  direction: "ltr",
  title: "This is title",
  text: "description of the section",
  imageUrl: "/images/aboutHero.jpg",
};

/*withStyles(styles, [options]) => higher-order component
-----------------------------------------------------------------------------
Link a style sheet with a component using the higher-order component pattern.
It does not modify the component passed to it; instead, it returns a new
component with a classes property. This classes object contains the name of the
class names injected in the DOM.*/
export default withStyles(styles)(HomeSection);
