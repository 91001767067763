import React, { Component, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deletePost } from "../redux/actions/dataActions";

import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as ReportIcon} from "../assets/icons/report_black_24dp.svg";
import ReportPopup from "./ReportPopup";

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

const redTheme = createMuiTheme({palette: {primary: red}});


const styles = (theme) => ({
  card: {
    width: "100%",
  },
  cardP1: {
    //height: "2vh",
    backgroundColor: "green",
    display: "flex",
    alignItems: "center",
    padding: "2vh",
  },
  cardP2: {
    paddingBottom: "0vh",
  },
  cardP3: {
    paddingTop: "0vh",
  },
  handle: {
    padding: "1vh",
    float: "right",
  },
  navButton: {
    backgroundColor: "#fff",
    margin: "1vh",
  },
  media: {
    maxHeight: 240,
    minHeight: 100,
    backgroundColor: "#999999",
  },
});

class PostPreview extends Component {
  state = {
    anchorEl: null,
    openOptions: false,
    deleteOpen: false,
  };

  handleClick = (event) => {
    this.setState({ openOptions: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ openOptions: false });
    this.setState({ reportOpen: false });
  };

  handleOpen = () => {
    this.setState({ deleteOpen: true });
  };
  handleClose = () => {
    this.setState({ deleteOpen: false });
    this.setState({ openOptions: false });
  };

  handleExpandComments = () => {
    this.props.openModal(this.props.post);
    //this.setState({ expandComments: true });
  };

  handleOpenReporting = () => {
    this.setState({ reportOpen: true });
  };

  handleCloseOptions = () => {
    this.setState({reportOpen: false});
    this.setState({deleteOpen: false});
    this.setState({openOptions: false});
  };

  componentDidMount() {}

  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      post: {
        body,
        title,
        createdAt,
        userAvatar,
        userHandle,
        mooiCount,
        herkenbaarCount,
        ditHelpCount,
        id,
        imageURL,
      },
      user: {
        authenticated,
        credentials: { handle, role},
      },
    } = this.props;

    const { anchorEl, openOptions } = this.state;

    const deletePost = () => {
      this.props.deletePost(id);
      this.setState({ deleteOpen: false });
      this.setState({ openOptions: false });
    };

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar
              alt="Remy Sharp"
              src={
                userAvatar
                  ? require("../assets/svg/" + userAvatar + ".svg")
                  : null
              }
            />
          }
          action={
            <IconButton
              aria-label="More"
              aria-owns={this.state.openOptions ? "more-options" : null}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={userHandle}
          subheader={dayjs(createdAt).fromNow()}
        />

        <Menu
          id="more-options"
          anchorEl={this.state.anchorEl}
          open={this.state.openOptions}
          onClose={this.handleRequestClose}
        >
          {authenticated && (userHandle === handle || role === 'admin') ? (
            <MenuItem>
              <Button
                onClick={this.handleExpandComments}
                className={classes.deleteButton}
              >
                <ExpandMoreIcon color="secondary" />
              </Button>
              <Button
                onClick={this.handleOpen}
                className={classes.deleteButton}
              >
                <DeleteOutline color="secondary" />
              </Button>
              <Button
                onClick={this.handleRequestClose}
                className={classes.deleteButton}
              >
                <ArrowBackIcon color="secondary" />
              </Button>
              <Dialog
                open={this.state.deleteOpen}
                onClose={this.handleClose}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle>
                  Are you sure you want to delete this?
                </DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={deletePost} color="secondary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </MenuItem>
          ) : (
              <MenuItem>
                {this.state.reportOpen ? null : <div> <Button
                    onClick={this.handleExpandComments}
                    className={classes.deleteButton}
                >
                  <ExpandMoreIcon color="secondary" />
                </Button>
                  <MuiThemeProvider theme={redTheme}><Button
                    onClick={this.handleOpenReporting}
                    className={classes.deleteButton}
                >
                  <SvgIcon component={ReportIcon} color="primary" />
                </Button>
                </MuiThemeProvider> </div> }
                <Button
                    onClick={this.handleRequestClose}
                    className={classes.deleteButton}
                >
                  <ArrowBackIcon color="secondary" />
                </Button>
                {this.state.reportOpen ? <ReportPopup postId={id} handleCloseOptions={this.handleCloseOptions}/> : null}
              </MenuItem>
          )}
        </Menu>
        <CardActionArea
          className={classes.cardP2}
          onClick={this.handleExpandComments}
        >
          <CardMedia
            className={classes.media}
            image={
              imageURL
                ? imageURL
                : "https://images.unsplash.com/photo-1519810755548-39cd217da494?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
            }
          />
          <CardContent>
            <Typography variant="h5" color="textSecondary">
              {title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {body !== undefined
                  ? body.length < 250
                      ? body
                      : body.substr(0, 250)
                  : ""}
              <Button
                  onClick={this.handleExpandComments}
                  className={classes.seeComments}
                  size="small"
                  color="secondary"
              >
                {" "}
                {body !== undefined
                    ? body.length < 250
                        ? ""
                        : "Show more"
                    : ""}
              </Button>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}
//In case we want to add the like bottons to the preview
/*
<CardContent className={classes.cardP3}>
  <MooiButton postId={id} mooiCount={mooiCount} />
  <DitHelpButton postId={id} dithelpCount={ditHelpCount} />
  <HerkenbaarButton postId={id} herkenbaarCount={herkenbaarCount} />
</CardContent>
*/

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapActionsToProps = {
  deletePost,
};

PostPreview.propTypes = {
  user: PropTypes.object.isRequired,
  deletePost: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PostPreview));
