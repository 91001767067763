import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Modal, Backdrop, Fade} from "@material-ui/core";
import OpenPostModal from "../components/OpenPostModal";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ProfileContentWrapper from "../components/ProfileContentWrapper.js";
import PostPreview from "../components/PostPreview";
import Profile from "../components/Profile.js";
import {getUserData} from "../redux/actions/userActions";
import {getPosts} from "../redux/actions/dataActions";

const styles = (theme) => ({
  root: {
    marginTop: "2vh",
    height: "88vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  grid: {
    height: "100%",
  },
  postsColumns: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "50% 50%",
  },
  profileWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  infoWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  postPreviewContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(1),
  },
  modalFather: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    top: "10vh",
    bottom: "10vh",
    position: "absolute",
    outline: "none",
    overflow: "hidden",
  },
});

class myprofile extends React.Component {
  state = {
    profile: null,
    myposts: [],
    favposts: [],
    activeModal: null,
  };

  handleOpenModal = (post) => {
    this.setState({
      activeModal: post,
    });
  };

  handleCloseModal = () => {
    this.setState({
      activeModal: null,
    });
  };

    componentDidMount() {
        //fetch data here
        this.props.getUserData();
        this.props.getPosts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            classes,
            user: {authenticated, credentials, loadingUser, favorites},
            data: {posts, loadingPosts},
        } = this.props;

        if (this.props.user !== prevProps.user) {
            if (this.props.user !== undefined) {
                let currentProfile =
                    !loadingUser && credentials.handle != null ? (
                        <Profile credentials={credentials}/>
                    ) : (
                        <p>loading</p>
                    );
                this.setState({
                    profile: currentProfile,
                });

                this.render();
            }
        }

        if (this.props.data.posts !== prevProps.data.posts) {
            if (this.props.data.posts !== undefined) {
                let myposts = authenticated ? (
                    !loadingPosts ? (
                        posts.map(
                            (post) =>
                                post.userHandle === credentials.handle && (
                                    <Grid
                                        key={post.postId}
                                        item
                                        sm={12}
                                        className={this.props.classes.postPreviewContainer}
                                    >
                                        <PostPreview post={post} openModal={this.handleOpenModal}/>
                                    </Grid>
                                )
                        )
                    ) : (
                        <p>loading</p>
                    )
                ) : (
                    []
                );

                let arrayOfIDs = [];
                favorites.forEach((fav) => arrayOfIDs.push(fav.postId));

                console.log("arrayOfIDs = ");
                console.log(arrayOfIDs);

                let favpostsData = [];
                posts.forEach((post) => {
                    console.log("Post id ====");
                    console.log("" + post.id + "");
                    const stringPostId = "" + post.id + "";
                    //right now posts only gets the last posts while forgetting about the others
                    if (arrayOfIDs.includes(stringPostId)) {
                        favpostsData.push(post);
                    }
                });

                console.log("favPostData = ");
                console.log(favpostsData);

                let favposts = authenticated ? (
                    !loadingPosts ? (
                        favpostsData.map((fav) => (
                            <Grid
                                key={fav.postId}
                                item
                                sm={12}
                                className={this.props.classes.postPreviewContainer}
                            >
                                <PostPreview post={fav} openModal={this.handleOpenModal}/>
                            </Grid>
                        ))
                    ) : (
                        <p>loading</p>
                    )
                ) : (
                    []
                );

                this.setState({
                    myposts: myposts,
                    favposts: favposts,
                });

                this.render();
            }
        }
    }

    render() {
        const {
            classes,
            user: {credentials, authenticated},
        } = this.props;

        const {profile, myposts, favposts} = this.state;

        const modalContent = (
            <Container maxWidth="lg" className={classes.paper}>
                {this.state.activeModal !== null && (
                    <OpenPostModal post={this.state.activeModal} open={true}/>
                )}
            </Container>
        );

        return credentials != null ? (
            authenticated ? (
                <Container className={classes.root} maxWidth="xl">
                    <Grid
                        container
                        justify="flex-start"
                        spacing={3}
                        className={classes.grid}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={4}
                            lg={3}
                            className={classes.profileWrapper}
                        >
                            {profile}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={7}
                            md={8}
                            lg={9}
                            className={classes.infoWrapper}
                        >
                            <div className={classes.postsColumns}>
                                <ProfileContentWrapper
                                    title={"My posts"}
                                    content={myposts}
                                    height={"100%"}
                                    background={"white"}
                                />
                                <ProfileContentWrapper
                                    title={"My favourites"}
                                    content={favposts}
                                    height={"100%"}
                                    background={"white"}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Modal
                        className={classes.modalFather}
                        open={this.state.activeModal != null ? true : false} //if acctive modal != null it will be open
                        onClose={this.handleCloseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            style: {backgroundColor: "black", opacity: "50%"},
                            timeout: 500,
                        }}
                    >
                        {this.state.activeModal != null ? modalContent : <div/>}
                    </Modal>
                </Container>
            ) : (
                <div className={classes.root}>U moet inloggen</div>
            )
        ) : (
            <div className={classes.root}>Loading...</div>
        );
    }
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

myprofile.propTypes = {
  getUserData: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapActionsToProps = {
  getUserData,
  getPosts,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(myprofile));

/*
<ProfileContentWrapper
              title={"Mijn buddy's"}
              height={"3vh"}
              background={"#fafafa"}
            />
*/
