import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import Image from "../assets/misc/query.png";

import withStyles from "@material-ui/core/styles/withStyles";
const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "25vh",
    width: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "2vh",
  },
  paperText: {
    margin: "1.5vh",
    color: "white",
  },
  gridContainer: {
    display: "flex",
    marginTop: "3vh",
    flexDirection: "column",
    alignItems: "stretch",
    backgroundColor: "#EFEFEF",
    borderRadius: "2vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: "2vh",
    color: "white",
  },
  textForm: {
    margin: "2vh",
    backgroundColor: "white",
  },
  loginButton: {
    position: "relative",
    alignSelf: "flex-end",
    margin: "3vh",
    width: "15vh",
  },
};

class contact extends Component {
  constructor() {
    super();
    this.state = {
      topic: "",
      message: "",
      email: "",
      name: "",
    };
  }
  handleSubmit = (event) => {};

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { classes, authenticated } = this.props;

    return (
      <div>
        <Paper className={classes.paperContainer}>
          <Typography className={classes.paperText} variant="h3">
            Contact
          </Typography>
          <Typography className={classes.paperText} variant="body1">
            Hier kun je vragen stellen of feedback geven
          </Typography>
        </Paper>
        <Grid className={classes.gridContainer}>
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            {authenticated ? (
              <div />
            ) : (
              <div>
                <TextField
                  id="message"
                  label="Naam"
                  type="message"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  variant="outlined"
                  placeholder="Typ hier uw naam"
                  color="secondary"
                  className={classes.textForm}
                />
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  variant="outlined"
                  placeholder="Typ hier uw email"
                  color="secondary"
                  className={classes.textForm}
                />
              </div>
            )}
            <TextField
              id="topic"
              name="topic"
              label="Onderwerp"
              type="topic"
              value={this.state.topic}
              onChange={this.handleChange}
              variant="outlined"
              placeholder="Typ hier uw onderwerp"
              color="secondary"
              className={classes.textForm}
            />
            <TextField
              id="outlined-multiline-static"
              label="comment"
              multiline
              rows={4}
              placeholder="add a comment"
              variant="outlined"
              color="secondary"
              className={classes.textForm}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              className={classes.loginButton}
            >
              versturen
            </Button>
          </form>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
});

contact.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(contact));
