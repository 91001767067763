import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export const handleDate = (item) => {
  dayjs.extend(relativeTime);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  //const { comment } = this.props;
  if (item.createdAt !== undefined) {
    const date = {
      year: item.createdAt.split("-", 1)[0],
      month: item.createdAt.split("-", 2)[1],
      day: item.createdAt.split("-", 3)[2].split("T", 1)[0],
      hour: item.createdAt.split("-", 3)[2].split("T", 2)[1].split(":", 1)[0],
      min: item.createdAt.split("-", 3)[2].split("T", 2)[1].split(":", 2)[1],
    };

    today = yyyy + mm + dd;
    const creationDate = date.year + date.month + date.day;

    const result =
      today - creationDate < 5
        ? dayjs(item.createdAt).fromNow()
        : dayjs(item.createdAt).format("DD MMMM, YYYY"); //"h:mm a, MMMM DD YYYY"

    return result;
  } else return "loading...";
};
