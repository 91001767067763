import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import HomeTitle from "../components/HomeTitle";
import HomeSection from "../components/HomeSection";
import HomeSubTitle from "../components/HomeSubTitle";
import HomeBottomSection from "../components/HomeBottomSection";
import GoTopButton from "../components/goToTopButton";
import HomeComingSoon from "../components/HomeComingSoon"
import { Container } from "@material-ui/core";

const styles = {
  backgroundPattern: {
    justifyContent: "center",
    paddingTop: "50px",
    height: "250vh",
    width: "calc( 100vw - 0px )",
    backgroundSize: "cover",
    backgroundPosition: "0 0",
    // backgroundImage: `url('images/homeBackground.svg')`,
  },
};

export class home extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <HomeComingSoon/>
         {/* Temporarily comment this section out for the Coming Soom page.
        <HomeTitle
          url="images/splash/banner.png"
          title="Behind the Mask"
          subTitle="Zorgen voor anderen begint met zorgen voor jezelf."
          logo="/images/btm_logos/BtM_Logo.png"
        />
        <Container maxWidth="lg" className={classes.backgroundPattern}>
          <HomeSection
            url="images/aboutHero.jpg"
            title="Elkaar ondersteunen "
            text="Masks Off is een plek vóór verpleegkundigen dóór verpleegkundigen, ter ondersteuning van elkaar..."
            direction="ltr"
            buttonLink="/faq"
            imageUrl="/images/splash/ondersteunen.png"
            position="right"
          />
          <HomeSection
            url="images/aboutHero.jpg"
            title="Samen sterker "
            text="... het is een plek waar verpleegkundigen verhalen en tips delen om samen sterker te worden dan ooit..."
            direction="rtl"
            buttonLink="/faq"
            imageUrl="/images/splash/samen_sterker.png"
            position="left"
          />
          <HomeSection
            url="images/aboutHero.jpg"
            title="Zorgenloos delen"
            text="... het is een plek waar iedereen gelijk is, ipgericht door organisaties die geven om verpleegkundigen."
            direction="ltr"
            buttonLink="/faq"
            imageUrl="/images/splash/zorgenloosdelen.png"
            position="right"
          />
          <HomeSection
            url="images/aboutHero.jpg"
            title="Registreer en vertel vandaag jouw verhaal."
            text="Maak je profiel aan en kom in contact met verpleegkundigen in heel Nederland"
            direction="rtl"
            buttonText={"registreren"}
            buttonLink="/login"
            imageUrl="/images/splash/registreer.png"
            position="left"
          />
          <HomeBottomSection
            url="images/aboutHero.jpg"
            title="Waarom Masks Off?"
            text="Voor de momenten dat je er behoefte aan hebt, is er op Masks Off altijd wel iemand."
            direction="ltr"
            imageUrl="/images/btm_logos/BtM_Logo.png"
            position="right"
          /> 
          <GoTopButton />
        </Container>*/}
      </div>
    );
  }
}

export default withStyles(styles)(home);
