import React, { Component } from "react";
import BannerTitle from "../components/BannerTitle";
import BannerHulplijn from "../components/BannerHulplijn";
import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";

import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  root: {
    display: "block",
    height: "90vh",
  },
};

export class hulplijnen extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="lg" className={classes.root}>
        <BannerTitle
          url="images/exit.jpg"
          title="Hulplijnen"
          subTitle="Hier vind je een overzicht van telefoonnummers voor urgente vragen en hulp"
          backgroundPosition="0 95%"
        />

        <BannerHulplijn
          color="#ae252c"
          logoUrl="https://www.rodekruis.nl/wp-content/uploads/2018/08/Wij-steunen-rode-kruis-logo2.jpg"
          number="070-4455 888"
          websiteUrl="https://www.rodekruis.nl/"
          topTitle="Direct hulp nodig?"
          bottomTitle="Bel het Rodekruis"
        />
        <BannerHulplijn
          color="#216188"
          logoUrl="https://www.atlant.nl/wp-content/uploads/2020/03/RIVM-logo.jpg"
          number="0800 - 1351"
          websiteUrl="https://www.rivm.nl/"
          topTitle="Vragen over het virus?"
          bottomTitle="Bel het RIVM"
        />
        <BannerHulplijn
          color="#f1ba2e"
          logoUrl="https://bureaugroot.nl/wp-content/uploads/2019/03/bureagroot-nl-alkmaar-opdrachtgever-parnassia-groep.png"
          number="088 - 357 16 66"
          websiteUrl="https://www.parnassiagroep.nl/"
          topTitle="Psychologische ondersteuning?"
          bottomTitle="Bel het Parnassia instituut"
        />
      </Container>
    );
  }
}

export default withStyles(styles)(hulplijnen);
